/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable complexity */

import type { AuthState, CartActionTypes } from "../types/cartTypes";
import {
  CART_SUCCESS,
  CART_FAIL,
  CART_CONTENT_SAVE_REQUEST,
  CART_CONTENT_SAVE_SUCCESS,
  CART_CONTENT_SAVE_FAIL,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAIL,
  CART_CLEAR_REQUEST,
  CART_CLEAR_SUCCESS,
  CART_RECALCULATE_SUCCESS,
  CART_REMOVE_COUPON_CODE,
  CHANGE_AMOUNT,
  AUTH_LOGOUT,
  RESTORE_STATE,
  CART_LOADING,
  CART_LOADED,
  CART_FETCH_SUCCESS,
} from "./constants";

const initialState: AuthState = {
  amount: 0,
  products: [],
  ids: [],
  fetching: false,
  user_data: {},
  isSeparateCart: false,
  coupons: {},
  cartdata: {},
  carts: {}

};

let newProducts: any = [];
let newState: any = {};
let newCarts: any = {};
let newCoupons: any = {};

const Cartred = (state = initialState, action: CartActionTypes) => {
  switch (action.type) {
    case CART_LOADING:
    case ADD_TO_CART_REQUEST:
    case CART_CLEAR_REQUEST:
    case CART_CONTENT_SAVE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case CART_LOADED:
    case ADD_TO_CART_SUCCESS:
    case ADD_TO_CART_FAIL:
    case CART_CONTENT_SAVE_FAIL:
    case CART_FAIL:
      return {
        ...state,
        fetching: false,
      };

    case RESTORE_STATE:
      return {
        ...state,
        ...action.payload.cart,
      };

    case CART_FETCH_SUCCESS:
      return {
        ...state,
        cartdata: action.payload,
      };
    case CART_CLEAR_SUCCESS:
      return {
        ...state,
        carts: {},
        coupons: {},
        fetching: false,
      };
    case CART_CONTENT_SAVE_SUCCESS:
      return {
        ...state,
        user_data: {
          ...state.user_data,
          ...action.payload,
        },
      };

    case CART_RECALCULATE_SUCCESS:
      newCarts = JSON.parse(JSON.stringify(state.carts));
      newCoupons = state.coupons;
      if (state.carts.hasOwnProperty("general")) {
        newCarts.general = action.payload.cart;
        newCoupons.general = action.payload.cart.coupons;
      } else if (action.payload.cartId) {
        newCoupons[action.payload.cartId] = action.payload.cart.coupons;
        newCarts[action.payload.cartId] = action.payload.cart;
      }

      return {
        ...state,
        carts: { ...newCarts },
        coupons: newCoupons,
      };

    case AUTH_LOGOUT:
      return initialState;

    case CHANGE_AMOUNT:
      newState = JSON.parse(JSON.stringify(state.carts));

      if (state.carts.hasOwnProperty("general")) {
        newProducts = JSON.parse(JSON.stringify(state.carts.general.products));
        newProducts[action.payload.cid].amount = action.payload.amount;
        newState.general.products = newProducts;
      } else {
        newProducts = JSON.parse(
          JSON.stringify(state.carts[action.payload.id].products)
        );
        newProducts[action.payload.cid].amount = action.payload.amount;
        newState[action.payload.id].products = newProducts;
      }

      return {
        ...state,
        carts: newState,
        fetching: false,
      };

    case CART_REMOVE_COUPON_CODE:
      return {
        ...state,
        coupons: { ...action.payload.newCoupons },
      };

    default:
      return state;
  }
};

export default Cartred;
