import React from "react";
import { createMarkup } from "../../lib/Functions";
import { useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";

export type DividerProp = {
  href?: string;
  className?: string;
  width?: string | number;
};

const CustomerService: React.FC<DividerProp> = () => {
  const allState = useAppSelector((state: TypeOfStates) => state);
  const frontSettings = allState.mainMenus.front_settings;

  if (!frontSettings.CUSTOMER_SERVICE_PHONE_TEXT) {
    return <></>;
  }
  return (
    <div className="customer-service">
      <h2>{frontSettings.CUSTOMER_SERVICE_PHONE_TEXT.title}</h2>
      <div
        dangerouslySetInnerHTML={createMarkup(
          frontSettings.CUSTOMER_SERVICE_PHONE_TEXT.description
        )}
      />
    </div>
  );
};

export default CustomerService;
