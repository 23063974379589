import { useEffect } from "react";
import "./App.css";
import "./styles/style.scss";
import "./styles/theme.scss";
import "./styles/press.scss";
import "./styles/checkout.scss";
import "./styles/checkout-section.scss";
import "./styles/carousel-products.scss";
import "./styles/mobile-nav.scss";
import "./styles/auth-form-drawer.scss";
import "./styles/wishlist-list.scss";
import "./styles/profile.scss";
import "./styles/order-list-table.scss";
import "./styles/category-sort-section.scss";
import "./styles/filter-section.scss";
import "./styles/product-detail-section.scss";
import "./styles/register-form.scss";
import "./styles/checkout-confirmation.scss";

import { message } from "antd";
import * as getTranslationsActions from "./reducers/actions/getTranslationsActions";
import * as layoutActions from "./reducers/actions/layoutActions";
import { useAppDispatch, useAppSelector } from "./reducers/cart/hooks";
import RenderRoutes from "./screens/RenderRoutes";
import {
  NOTIFICATION_CLEAR,
  STORE_KEY_AUTH,
  STORE_LANGUAGE_CODE,
} from "./reducers/constants";
import * as authActions from "./reducers/actions/authActions";
import { BrowserRouter } from "react-router-dom";
import { TypeOfStates } from "./types/stateTypes";
import * as appActions from "./reducers/actions/appActions";
import * as settingsActions from "./reducers/actions/settingsActions";

function App() {
  const dispatch = useAppDispatch();
  const langCode = useAppSelector(
    (state: TypeOfStates) => state.settings.selectedLanguage.langCode
  );
  const notifications = useAppSelector(
    (state: TypeOfStates) => state.notifications
  );

  useEffect(() => {
    if (localStorage.getItem(STORE_LANGUAGE_CODE)) {
      dispatch(
        settingsActions.setLanguage({
          langCode: localStorage.getItem(STORE_LANGUAGE_CODE) || "mn",
          name:
            localStorage.getItem(STORE_LANGUAGE_CODE) === "mn"
              ? "Mongolia"
              : "English",
          selected: true,
        })
      )
        .then(() => appActions.initApp())
        .catch((e) => console.log("eee", e));
    } else {
      localStorage.setItem(STORE_LANGUAGE_CODE, "mn");
      dispatch(
        settingsActions.setLanguage({
          langCode: "mn",
          name: "Mongolia",
          selected: true,
        })
      )
        .then(() => appActions.initApp())
        .catch((e) => console.log("eee", e));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTranslationsActions.fetch());
  }, [dispatch, langCode]);

  useEffect(() => {
    if (notifications.items.length > 0) {
      setTimeout(() => {
        dispatch({
          type: NOTIFICATION_CLEAR,
          payload: {
            show: false,
          },
        });
      }, 2000);
    }
  }, [dispatch, notifications]);

  useEffect(() => {
    const localStorageData = localStorage.getItem(STORE_KEY_AUTH);
    if (localStorageData && JSON.parse(localStorageData)?.auth?.token) {
      void dispatch(
        authActions.getUserData({
          token: JSON.parse(localStorageData)?.auth?.token,
          ttl: JSON.parse(localStorageData)?.auth?.ttl,
        })
      );
      void dispatch(authActions.fetchProfile());
      void dispatch(authActions.authLoaded());
    } else {
      console.log("notihing ;");
    }
  }, [dispatch]);

  useEffect(() => {
    document.body.classList?.remove("loading");
    if (!navigator.onLine) message.error("Интернэт холболтоо шалгана уу.", 0);
    else message.destroy();

    void dispatch(layoutActions.fetchMainLayout());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <RenderRoutes />
    </BrowserRouter>
  );
}

export default App;
