/* eslint-disable no-warning-comments */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-case-declarations */
import {
  CH_CATEGORIES_REQUEST,
  CH_CATEGORIES_SUCCESS,
  CH_CATEGORIES_FAILED,
  CATEGORIES_WITH_SUB_REQUEST,
  CATEGORIES_WITH_SUB_SUCCESS,
  CATEGORIES_WITH_SUB_FAILED,
  CATEGORIES_TREE_REQUEST,
  CATEGORIES_TREE_SUCCESS,
  CATEGORIES_TREE_FAILED,
  CATEGORY_DETAIL_REQUEST,
  CATEGORY_DETAIL_SUCCESS,
  CATEGORY_DETAIL_FAILED,
} from "./constants";

const initialState = {
  catInfo: [],
  categoryProducts: [],
  fetching: false,
  categoryWithSub: [],
  categorytree: [],
  categorydetail: [],
};

function CategoryFetch(state: any = initialState, action: any) {
  switch (action.type) {
    case CH_CATEGORIES_REQUEST:
    case CATEGORIES_WITH_SUB_REQUEST:
    case CATEGORIES_TREE_REQUEST:
    case CATEGORY_DETAIL_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        categorydetail: action.payload,
        fetching: false,
      };

    case CATEGORIES_TREE_SUCCESS:
      return {
        ...state,
        categorytree: action.payload,
        fetching: false,
      };

    case CATEGORIES_WITH_SUB_SUCCESS:
      return {
        ...state,
        categoryWithSub: action.payload,
        fetching: false,
      };

    case CH_CATEGORIES_SUCCESS:
      return {
        ...state,
        catInfo: action.payload.catInfo,
        categoryProducts: action.payload.categoryProducts,
        fetching: false,
      };

    case CH_CATEGORIES_FAILED:
    case CATEGORIES_TREE_FAILED:
    case CATEGORIES_WITH_SUB_FAILED:
    case CATEGORY_DETAIL_FAILED:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}

export default CategoryFetch;
