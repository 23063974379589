import { FETCH_PROFILE_SUCCESS, RESTORE_STATE, AUTH_LOGOUT } from "./constants";

const initialState = {
  user_type: "C",
};
function Profile(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_LOGOUT:
      return initialState;

    case RESTORE_STATE:
      return {
        ...state,
        ...action.payload.profile,
      };

    default:
      return state;
  }
}

export default Profile;
