/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as authActions from "../../reducers/actions/authActions";
import * as ordersActions from "../../reducers/actions/ordersActions";
import { SectionTitle } from "../../components/ProfileFields";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Loader from "../Loader";
import { TypeOfStates } from "../../types/stateTypes";
import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";
import { Row, Col } from "antd";
import i18n from "../../utils/i18n";
import * as commonActions from "../../reducers/actions/commonActions";
import PointsRedBox from "../../components/ProfileForms/PointsRedBox";
import { createMarkup, numberFormat } from "../../lib/Functions";
import Moment from "react-moment";
import BackCircleBtn from "../../components/BackCircleBtn";
import { PURCHASE_AND_POINT_MESSAGE } from "../../reducers/constants/settings";
import { Layout } from "../../layouts";
import VerifyScreen from "./VerifyScreen";

function ProfileScreen() {
  Moment.globalFormat = "YYYY.MM.DD";

  const dispatch = useAppDispatch();
  const getStateSelect = useAppSelector((state: TypeOfStates) => state);

  const [orderPage, setOrderPage] = useState(1);

  const isMobile = getStateSelect.isMobile.status;
  const userProfile = getStateSelect.profile;
  const frontSettings = getStateSelect.mainMenus.front_settings;

  let navigate = useNavigate();
  let location = useLocation();

  const [getloginInfoForm, setLoginInfoForm] = useState(false);

  const allShowTrue = () => {
    dispatch(commonActions.mainNavShowHide(true));
    dispatch(commonActions.footerHideShow(true));
    dispatch(commonActions.topHeaderHide(true));
  };

  useEffect(() => {
    dispatch(commonActions.mainNavShowHide(false));
    dispatch(commonActions.footerHideShow(false));
    dispatch(commonActions.topHeaderHide(false));
  }, [dispatch]);

  useEffect(() => {
    if (!userProfile.email || getStateSelect.cart.fetching === true) {
      dispatch(authActions.fetchProfile());
    }
  }, [dispatch, getStateSelect.cart.fetching, userProfile]);

  useEffect(() => {
    if (getStateSelect.auth.logged !== true) {
      navigate("/");
    }
  }, [getStateSelect.auth.logged, location, navigate]);

  const setLoginInfoFormR = useCallback(() => {
    setLoginInfoForm(!getloginInfoForm);
  }, [getloginInfoForm]);

  const logout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ordersActions.fetch(orderPage));
  }, [dispatch, orderPage]);

  if (!userProfile.email && !frontSettings?.PURCHASE_AND_POINT_MESSAGE) {
    return <Loader />;
  }

  if (userProfile.verify_status === "N") {
    return <VerifyScreen />;
  }
  
  return (
  
      <div className={"w-full py-10 bg-white "}>
        <div className="max-width-layout profile-section">
          <div
            className="username-section"
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h5
              style={{
                paddingLeft: 20,
                fontSize: 13,
              }}
            >
              {i18n.t("hello_hi")}: {userProfile?.lastname}{" "}
              {userProfile?.firstname}
            </h5>
            <BackCircleBtn
              onClick={() => {
                navigate("/account/profile");
                allShowTrue();
              }}
            />
          </div>

          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{
                maxWidth: 520,
              }}
            >
              <PointsRedBox profile={userProfile} isMobile={isMobile} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={9}
              lg={9}
              xl={9}
              style={{
                paddingLeft: isMobile === true ? 20 : 90,
              }}
            >
              <SectionTitle
                title={frontSettings[PURCHASE_AND_POINT_MESSAGE].title}
                isUrlText={false}
                marginTop={0}
              />
              <div
                style={{ fontSize: 11 }}
                dangerouslySetInnerHTML={createMarkup(
                  frontSettings[PURCHASE_AND_POINT_MESSAGE].description
                )}
              />
            </Col>
          </Row>

          <div className="order-list-table-container">
            <table
              className="order-list-table"
              border={0}
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th
                    className="th-tbl"
                    style={{
                      width: 220,
                    }}
                  >
                    {i18n.t("Order")}
                  </th>
                  <th className="th-tbl">{i18n.t("Product")}</th>
                  <th className="th-tbl">{i18n.t("Price")}</th>
                  <th className="th-tbl">{i18n.t("Date")}</th>
                  <th className="th-tbl">{i18n.t("Points")}</th>
                </tr>
              </thead>
              <tbody>
                {getStateSelect?.orders?.items &&
                  getStateSelect?.orders?.items.length > 0 &&
                  Object.values(getStateSelect?.orders.items).map(
                    (order: any) => (
                      <tr key={order.order_id}>
                        <td>#{order.order_id}</td>
                        <td>
                          {order?.products &&
                            Object.values(order.products).map(
                              (order_product: any) => (
                                <div
                                  key={order_product.product_id}
                                  className="order-product"
                                >
                                  <img
                                    src={
                                      order_product?.main_pair?.detailed
                                        ?.image_path
                                    }
                                    style={{
                                      width: 80,
                                      objectFit: "cover",
                                    }}
                                    alt=""
                                  />
                                  <div className="desc">
                                    <p className="product">
                                      {order_product.product}
                                    </p>
                                    <p className="product_code">
                                      {order_product.product_code}
                                    </p>
                                    <p className="original_price">
                                      {numberFormat(
                                        order_product.original_price
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                        </td>
                        {/* {order.status_data.color}
                    {order.status_data.description}
                    {order.status_data.status} */}

                        <td> {numberFormat(order.total)}</td>
                        <td>
                          <Moment unix>{order?.updated_at}</Moment>
                        </td>
                        <td>
                          <span>
                            {order?.points_info?.is_gain === "Y"
                              ? " +" +
                                order?.points_info?.reward +
                                "" +
                                i18n.t("pts")
                              : ""}
                          </span>
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
 
  );
}

export default ProfileScreen;
