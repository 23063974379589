import {
  SHIPMENT_LOCATIONS_REQUEST,
  SHIPMENT_LOCATIONS_SUCCESS,
  SHIPMENT_LOCATIONS_FAILED,
} from "./constants";

const initialState: any = {
  cityProvince: [],
  fetching: false,
};

function shipmentLocation(state: any = initialState, action: any) {
  switch (action.type) {
    case SHIPMENT_LOCATIONS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case SHIPMENT_LOCATIONS_SUCCESS:
      return {
        ...state,
        cityProvince: action.payload,
        fetching: false,
      };

    case SHIPMENT_LOCATIONS_FAILED:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}

export default shipmentLocation;
