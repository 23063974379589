/* eslint-disable @typescript-eslint/default-param-last */

/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { SCREEN_MOBILE } from "./constants";

type InitProps = {
  status: boolean;
};
const initialState: InitProps = {
  status: false,
};

function isMobile(state: any = initialState, action: any) {
  if (action.type && action.type === SCREEN_MOBILE) {
    return {
      ...state,
      status: action.payload,
    };
  } else {
    return {
      ...state,
    };
  }
}

export default isMobile;
 