/* eslint-disable no-warning-comments */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-case-declarations */
import {
  FETCH_LAYOUTS_BLOCKS_REQUEST,
  FETCH_LAYOUTS_BLOCKS_SUCCESS,
  FETCH_LAYOUTS_BLOCKS_FAIL
} from "./constants";

const initialState: any = {
  blocks: [],
  fetching: false,
};

let newState = null;

function layouts(state: any = initialState, action: any) {
  switch (action.type) {
    case FETCH_LAYOUTS_BLOCKS_REQUEST:
      const fetching = !action.payload?.turnOffLoader;
      return {
        ...state,
        fetching,
      };

    case FETCH_LAYOUTS_BLOCKS_SUCCESS:
      // FIXME: Brainfuck code convert object to array.
      newState = Object.keys(action.payload.blocks)
        .map((k) => {
          action.payload.blocks[k].location = action.payload.location;
          return action.payload.blocks[k];
        })
        .sort((a, b) => a.order - b.order);
      return {
        ...state,
        blocks: newState,
        fetching: false,
      };

    case FETCH_LAYOUTS_BLOCKS_FAIL:  
      return {
        ...state,
        fetching: false,
      };


    default:
      return state;
  }
}

export default layouts;
