/* eslint-disable @typescript-eslint/indent */
import { SCREEN_MOBILE, DEVICE_MOBILE_WIDTH } from "../constants";

export function ScreenMobile(screenWidth: number) {
  return (
    dispatch: (arg0: { type: string; payload: boolean; }) => void
  ) => {
    dispatch({
      type: SCREEN_MOBILE,
      payload: screenWidth < DEVICE_MOBILE_WIDTH,
    });
  };
}
