import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../reducers/cart/hooks";
import { TypeOfStates } from "../types/stateTypes";
import i18n from "../utils/i18n";

const SearchInputComponent = () => {
  const navigate = useNavigate();
  const [searchvalue, setSearchvalue] = useState("");
  const [searchInput, setSearchInput] = useState(false);

  const getStates = useAppSelector((state: TypeOfStates) => state);
  const isMobile = getStates.isMobile.status;
  
  if(isMobile){
    return <></>
  }
  if (searchInput) {
    return (
      <>
        <Input
          placeholder={"Search"}
          onPressEnter={(s: any) => {
            if (s.target.value.length > 3) {
              navigate(`/search?q=${s.target.value}`);
            }
          }}
          onMouseLeave={(s: any) => {
            if (s.target.value.length > 3) {
              navigate(`/search?q=${s.target.value}`);
            }
          }}
          onChange={(s: any) => {
            if (s.target.value.length > 3) {
              setSearchvalue(s.target.value);
            }
          }}
          style={{
            maxWidth: 240,
          }}
        />
        <Button
          onClick={() => {
            navigate(`/search?q=${searchvalue}`);
          }}
          style={{
            marginLeft: 5,
          }}
          type="text"
        >
          <SearchOutlined />
        </Button>
      </>
    );
  }
  return (
    <>
      <Button
        icon={<SearchOutlined />}
        onClick={() => {
          setSearchInput(!searchInput);
        }}
        type={"text"}
      >
        {i18n.t("Search")}
      </Button>
    </>
  );
};

export default SearchInputComponent;
