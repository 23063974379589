/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/default-param-last */

import {
    FETCH_TRANSLATIONS_REQUEST,
    FETCH_TRANSLATIONS_SUCCESS,
    FETCH_TRANSLATIONS_FAIL,
  } from "./constants";
  type Initialstate = {
    fetching: boolean;
  };
  const initialState: Initialstate = {
    fetching: false,
  };
  
  const boutiques = (
    state = initialState,
    action: { type: any; payload: any }
  ) => {
    switch (action.type) {
      case FETCH_TRANSLATIONS_REQUEST:
        return {
          ...state,
          fetching: true,
        };
  
      case FETCH_TRANSLATIONS_SUCCESS:
        return {
          ...state,
          fetching: false,
        };
  
      case FETCH_TRANSLATIONS_FAIL:
        return {
          ...state,
          fetching: false,
        };
      default:
        return state;
    }
  };
  
  export default boutiques;
  