/* eslint-disable @typescript-eslint/indent */
import type { FC } from "react";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Col, Divider, Row } from "antd";
import { useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";
import { FooterMenu, Item } from "../../types/mainMenu";

type TypeFooterUrls = {
  text: string;
  url: string;
  type: string;
};

type TypeFooter = {
  title: string;
  urls: TypeFooterUrls[];
};
const footerPages: TypeFooter[] = [
  {
    title: "ABOUT US",
    urls: [
      {
        text: "History",
        url: "/page/about-us",
        type: "url",
      },
      {
        text: "Contact",
        url: "/page/contact",
        type: "url",
      },
      {
        text: "Careers",
        url: "/page/careers",
        type: "url",
      },
    ],
  },
  {
    title: "ONLINE SERVICES",
    urls: [
      {
        text: "Payment Methods",
        url: "/page/payment-methods",
        type: "url",
      },
      {
        text: "Shipping Options ",
        url: "/page/shipping-options",
        type: "url",
      },
      {
        text: "My Account ",
        url: "/page/my-account",
        type: "url",
      },
      {
        text: "Returns ",
        url: "/page/returns",
        type: "url",
      },
      {
        text: "FAQ ",
        url: "/page/faq",
        type: "url",
      },
      {
        text: "Care & Services ",
        url: "/page/care-services ",
        type: "url",
      },
    ],
  },

  {
    title: "BOUTIQUE SERVICES",
    urls: [
      {
        text: "Store Locator ",
        url: "/page/store-locator ",
        type: "url",
      },
      {
        text: "MOBILE APPLICATION",
        url: "#",
        type: "text",
      },
      {
        text: "IOS Download",
        url: "/page/ios-download",
        type: "url",
      },
      {
        text: "Android Download",
        url: "/page/android-download",
        type: "url",
      },
    ],
  },
  {
    title: "ONLINE CATALOGUE",
    urls: [
      {
        text: "Download",
        url: "/page/online-catalogue-download ",
        type: "url",
      },
      {
        text: "ONLINE CATALOGUE",
        url: "#",
        type: "text",
      },
      {
        text: "Legal",
        url: "/page/legal",
        type: "url",
      },
      {
        text: "Privacy ",
        url: "/page/privacy",
        type: "url",
      },
    ],
  },
];

const style: React.CSSProperties = { background: "black", paddingLeft: "22px" };
const textlink: React.CSSProperties = { color: "#e5e5e5" };

const LayoutFooter: FC<any> = () => {
  const fetching = useAppSelector(
    (state: TypeOfStates) => state.mainMenus.fetching
  );
  const footerMenu = useAppSelector(
    (state: TypeOfStates) => state.mainMenus?.footer_menu?.data
  );

  return (
    <div className="footer-section">
      <div className="page-horizontal-layout">
        <Row style={style}>
          {footerMenu &&
            Object.values(footerMenu).map(
              (footer: FooterMenu, index: number) => (
                <Col
                  className="gutter-row"
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  key={index}
                >
                  <h2 className="font-bold text-xs text-gray-50 mb-2 ">
                    {footer.title}
                  </h2>
                  {footer?.sub_item &&
                    Object.values(footer?.sub_item).map(
                      (subitem: Item, indx: number) =>
                        subitem?.url ? (
                          <Link key={indx} to={subitem?.url}>
                            <p
                              style={textlink}
                              className="text-hover-bold-white"
                            >
                              {subitem?.title}
                            </p>
                          </Link>
                        ) : (
                          <h2
                            key={indx}
                            className="font-bold text-xs text-gray-50 mb-2 "
                          >
                            {subitem.title}
                          </h2>
                        )
                    )}
                </Col>
              )
            )}
        </Row>

        <div className="flex flex-col center-items p-10">
          <img
            className="w-48"
            src="/footerlogo.png"
            alt="choihainzan.com"
            style={{
              marginBottom: 10,
            }}
          />
          <div className="flex flex-row gap-4">
            <a href={"https://www.facebook.com/choihainzan/"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="-204.79995 -341.33325 1774.9329 2047.9995"
                className="bg-black rounded-full p-[0px] pb-[2px] items-center"
              >
                <path
                  d="M948.4 880l30.267-197.333H789.333V554.609C789.333 500.623 815.78 448 900.584 448h86.083V280s-78.124-13.333-152.814-13.333c-155.936 0-257.853 94.506-257.853 265.6v150.4H402.667V880H576v477.04a687.805 687.805 0 00106.667 8.293c36.288 0 71.91-2.84 106.666-8.293V880H948.4"
                  fill="white"
                />
              </svg>
            </a>

            <a href={"https://www.facebook.com/choihainzan/"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="-100.7682 -167.947 873.3244 1007.682"
                className="bg-black rounded-full p-[2px]"
              >
                <g fill="white">
                  <path d="M335.895 0c-91.224 0-102.663.387-138.49 2.021-35.752 1.631-60.169 7.31-81.535 15.612-22.088 8.584-40.82 20.07-59.493 38.743-18.674 18.673-30.16 37.407-38.743 59.495C9.33 137.236 3.653 161.653 2.02 197.405.386 233.232 0 244.671 0 335.895c0 91.222.386 102.661 2.02 138.488 1.633 35.752 7.31 60.169 15.614 81.534 8.584 22.088 20.07 40.82 38.743 59.495 18.674 18.673 37.405 30.159 59.493 38.743 21.366 8.302 45.783 13.98 81.535 15.612 35.827 1.634 47.266 2.021 138.49 2.021 91.222 0 102.661-.387 138.488-2.021 35.752-1.631 60.169-7.31 81.534-15.612 22.088-8.584 40.82-20.07 59.495-38.743 18.673-18.675 30.159-37.407 38.743-59.495 8.302-21.365 13.981-45.782 15.612-81.534 1.634-35.827 2.021-47.266 2.021-138.488 0-91.224-.387-102.663-2.021-138.49-1.631-35.752-7.31-60.169-15.612-81.534-8.584-22.088-20.07-40.822-38.743-59.495-18.675-18.673-37.407-30.159-59.495-38.743-21.365-8.302-45.782-13.981-81.534-15.612C438.556.387 427.117 0 335.895 0zm0 60.521c89.686 0 100.31.343 135.729 1.959 32.75 1.493 50.535 6.965 62.37 11.565 15.68 6.094 26.869 13.372 38.622 25.126 11.755 11.754 19.033 22.944 25.127 38.622 4.6 11.836 10.072 29.622 11.565 62.371 1.616 35.419 1.959 46.043 1.959 135.73 0 89.687-.343 100.311-1.959 135.73-1.493 32.75-6.965 50.535-11.565 62.37-6.094 15.68-13.372 26.869-25.127 38.622-11.753 11.755-22.943 19.033-38.621 25.127-11.836 4.6-29.622 10.072-62.371 11.565-35.413 1.616-46.036 1.959-135.73 1.959-89.694 0-100.315-.343-135.73-1.96-32.75-1.492-50.535-6.964-62.37-11.564-15.68-6.094-26.869-13.372-38.622-25.127-11.754-11.753-19.033-22.943-25.127-38.621-4.6-11.836-10.071-29.622-11.565-62.371-1.616-35.419-1.959-46.043-1.959-135.73 0-89.687.343-100.311 1.959-135.73 1.494-32.75 6.965-50.535 11.565-62.37 6.094-15.68 13.373-26.869 25.126-38.622 11.754-11.755 22.944-19.033 38.622-25.127 11.836-4.6 29.622-10.072 62.371-11.565 35.419-1.616 46.043-1.959 135.73-1.959" />
                  <path d="M335.895 447.859c-61.838 0-111.966-50.128-111.966-111.964 0-61.838 50.128-111.966 111.966-111.966 61.836 0 111.964 50.128 111.964 111.966 0 61.836-50.128 111.964-111.964 111.964zm0-284.451c-95.263 0-172.487 77.224-172.487 172.487 0 95.261 77.224 172.485 172.487 172.485 95.261 0 172.485-77.224 172.485-172.485 0-95.263-77.224-172.487-172.485-172.487m219.608-6.815c0 22.262-18.047 40.307-40.308 40.307-22.26 0-40.307-18.045-40.307-40.307 0-22.261 18.047-40.308 40.307-40.308 22.261 0 40.308 18.047 40.308 40.308" />
                </g>
              </svg>
            </a>

            <a href={"https://www.facebook.com/choihainzan/"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="-35.20005 -41.33325 305.0671 247.9995"
                className="bg-black rounded-full p-[4px]"
              >
                <path
                  d="M93.333 117.559V47.775l61.334 34.893zm136.43-91.742c-2.699-10.162-10.651-18.165-20.747-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.555 7.652 7.603 15.655 4.904 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.904 56.849c2.699 10.163 10.65 18.165 20.747 20.883 18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.683-4.934c10.096-2.718 18.048-10.72 20.747-20.883 4.904-18.42 4.904-56.85 4.904-56.85s0-38.43-4.904-56.849"
                  fill="white"
                />
              </svg>
            </a>

            <a href={"https://www.facebook.com/choihainzan/"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="none"
                viewBox="-4.004985 -7.600025 34.70987 45.60015"
                className="bg-black rounded-full p-[4px]"
              >
                <path
                  fill="#25F4EE"
                  d="M10.4 11.9999v-1.2c-.4-.1-.8-.0999-1.2-.0999-5.1 0-9.2 4.0999-9.2 9.1999 0 3.1 1.6 5.9 3.9 7.5l-.1-.1C2.3 25.6999 1.5 23.5 1.5 21.2c0-5.1 4-9.1001 8.9-9.2001z"
                />
                <path
                  fill="#25F4EE"
                  d="M10.6002 25.4c2.3 0 4.1-1.8 4.2-4.1v-20h3.6c-.1-.4-.1-.8-.1-1.3h-5v20c-.1 2.2-1.9 4-4.2 4-.7 0-1.4-.2-1.9-.5.8 1.1 2 1.9 3.4 1.9zM25.3 8.1V6.9c-1.4 0-2.7-.4-3.8-1.1 1 1.1 2.3 2 3.8 2.3z"
                />
                <path
                  fill="#FE2C55"
                  d="M21.4999 5.8c-1.1-1.2-1.7-2.8-1.7-4.6h-1.4c.4 2 1.6 3.6 3.1 4.6zM9.2 15.5999c-2.3 0-4.2 1.9-4.2 4.2 0 1.6 1 3 2.3 3.7-.5-.7-.8-1.5-.8-2.4 0-2.3 1.9-4.2 4.2-4.2.4 0 .8.1 1.2.2v-5.1c-.4-.1-.8-.1-1.2-.1h-.2v3.8c-.5 0-.9-.1-1.3-.1z"
                />
                <path
                  fill="#FE2C55"
                  d="M25.2999 8.1001v3.8c-2.6 0-5-.8-6.9-2.2v10.2c0 5.1-4.1 9.2-9.2 9.2-2 0-3.8-.6-5.3-1.6 1.7 1.8 4.1 2.9 6.7 2.9 5.1 0 9.2-4.1 9.2-9.2v-10.2c2 1.4 4.4 2.2 6.9 2.2v-5c-.4 0-.9 0-1.4-.1z"
                />
                <path
                  fill="#fff"
                  d="M18.3999 19.8999v-10.2c2 1.4001 4.4 2.2 6.9 2.2v-3.9c-1.5-.3-2.8-1.1-3.8-2.2-1.6-1-2.7-2.7-3-4.6h-3.7V21.2c-.1 2.2-1.9 4-4.2 4-1.4 0-2.6-.7001-3.4-1.7001-1.3-.6-2.2-2-2.2-3.6 0-2.3 1.9-4.1999 4.2-4.1999.4 0 .8.0999 1.2.1999v-3.9c-5 .1-9 4.2001-9 9.2001 0 2.4.9 4.5999 2.5 6.2999 1.5 1 3.3 1.7001 5.3 1.7001 5.1-.1 9.2-4.3001 9.2-9.3001z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutFooter;
