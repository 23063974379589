import { get } from "lodash";
import {
  STORE_KEY,
  RESTORE_STATE,
  GET_LANGUAGES,
  GET_CURRENCIES,
  SET_CURRENCY,
  SET_LANGUAGE,
  LANGUAGE_CURRENCY_FEATURE_FLAG_OFF,
  SET_UUID,
  SET_ADDONS_SETTINGS,
  SET_LAYOUT_ID,
  STORE_LANGUAGE_CODE,
} from "../constants";
import API from "../../services/configApi";
import store from "../store";
import i18n from "../../utils/i18n";
import enLanguage from "../../config/locales/en.json";
import mnLanguage from "../../config/locales/mn.json";

const getLocalTranslations = (langCode: string) => {
  if (langCode === "en") {
    return enLanguage;
  }
  return mnLanguage;
};

// Gets languages, currencies and date format settings and sets them to the store.
export async function setStartSettings(
  currentLanguage: any,
  currentCurrency: any
) {
  const platformLanguage = "mn";
  const deviceLanguage = platformLanguage.split("_")[0];

  try {
    // Gets lists of languages and currencies
    const {
      data: { currencies, languages, properties },
    } = await API.get("sra_storefront");

    store.dispatch({
      type: SET_ADDONS_SETTINGS,
      payload: properties,
    });

    store.dispatch({
      type: SET_LAYOUT_ID,
      payload: properties.layout_id,
    });

    if (!currentCurrency?.currencyCode) {
      currencies.forEach((el: any) => {
        if (el.is_default) {
          currentCurrency = {
            currencyCode: el.currency_code,
            symbol: el.symbol,
          };
        }
      });

      if (!currentCurrency?.currencyCode && currencies.length) {
        currentCurrency = {
          currencyCode: currencies[0].currency_code,
          symbol: currencies[0].symbol,
        };
      }
    }

    store.dispatch({
      type: SET_CURRENCY,
      payload: currentCurrency,
    });

    if (!currentLanguage?.langCode) {
      // If the device language is among the languages of the store
      // use device language.
      let isDeviceLanguage = false;
      languages.forEach((el: any) => {
        if (el.lang_code === deviceLanguage) {
          isDeviceLanguage = true;
        }
      });

      if (isDeviceLanguage) {
        currentLanguage = {
          langCode: deviceLanguage,
          name: deviceLanguage,
        };
      } else {
        languages.forEach((el: any) => {
          if (el.is_default) {
            currentLanguage = {
              langCode: el.lang_code,
              name: el.name,
            };
          }
        });
      }

      store.dispatch({
        type: SET_LANGUAGE,
        payload: currentLanguage,
      });
    }

    // Set list of languages and currencies to store
    store.dispatch({
      type: GET_CURRENCIES,
      payload: currencies,
    });

    store.dispatch({
      type: GET_LANGUAGES,
      payload: languages,
    });

    return currentLanguage;
  } catch (e) {
    currentLanguage = {
      langCode: deviceLanguage,
      name: deviceLanguage,
    };
    store.dispatch({
      type: SET_LANGUAGE,
      payload: currentLanguage,
    });
    store.dispatch({
      type: LANGUAGE_CURRENCY_FEATURE_FLAG_OFF,
    });
    console.log("Error loading languages and currencies", e);

    return currentLanguage;
  }
}

export async function initApp() {
  let language;
  let clanguageCode = "mn";
  if (localStorage.getItem(STORE_LANGUAGE_CODE)) {
    if (localStorage.getItem(STORE_LANGUAGE_CODE)?.toString() === "mn") {
      clanguageCode = "mn";
    }
    if (localStorage.getItem(STORE_LANGUAGE_CODE)?.toString() === "en") {
      clanguageCode = "en";
    }
  }

  try {
    const persist: any = localStorage.getItem(STORE_KEY);

    if (persist) {
      store.dispatch({
        type: RESTORE_STATE,
        payload: JSON.parse(persist),
      });
    } else {
      store.dispatch({
        type: SET_UUID,
      });
    }
    const savedLanguage = get(JSON.parse(persist), "settings.selectedLanguage");
    const savedCurrency = get(JSON.parse(persist), "settings.selectedCurrency");
    language = await setStartSettings(savedLanguage, savedCurrency);

    // Load remote lang variables
    const transResult = await API.get(
      `/GetTranslations&lang_code=${clanguageCode}`
    );

    i18n.addResourceBundle(language.langCode, "translation", {
      ...getLocalTranslations(language.langCode),
      ...transResult.data,
    });
  } catch (error) {
    if (clanguageCode) {
      i18n.addResourceBundle(
        clanguageCode,
        "translation",
        getLocalTranslations(clanguageCode)
      );
    }
    console.log("Error loading translations", error);
  }

  if (language?.langCode) {
    i18n.changeLanguage(language.langCode);
  }
}
