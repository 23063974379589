/* eslint-disable camelcase */
import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_FAIL,
  FETCH_PRODUCTS_SUCCESS,
  CHANGE_PRODUCTS_SORT,
  FETCH_CART_PRODUCTS_REQUEST,
  FETCH_CART_PRODUCTS_SUCCESS,
  FETCH_CART_PRODUCTS_FAIL,
} from "./constants";

const initialState = {
  sortParams: {
    sort_by: "product",
    sort_order: "asc",
  },
  params: {
    page: 1,
  },
  items: {},
  filters: [],
  fetching: true,
  hasMore: false,
  cart_products: [],
};

let params: any = {};
let items: any = {};

function Products(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
    case FETCH_CART_PRODUCTS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_PRODUCTS_SUCCESS:
      items = {
        ...state.items,
      };
      params = {
        ...action.payload.params,
      };
      if (
        items[params.cid] &&
        action.payload.params.page !== 1 &&
        action.payload?.products
      ) {
        items[params.cid] = [...items[params.cid], ...action.payload.products];
      } else {
        if (action.payload?.products) {
          items[params.cid] = [...action.payload.products];
        }
      }

      return {
        ...state,
        params,
        items,
        filters: action.payload.filters || [],
        hasMore:
          params.items_per_page * params.page < Number(params.total_items),
        fetching: false,
      };

    case FETCH_CART_PRODUCTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        cart_products: action.payload,
      };
    case FETCH_PRODUCTS_FAIL:
    case FETCH_CART_PRODUCTS_FAIL:
      return {
        ...state,
        fetching: false,
      };

    case CHANGE_PRODUCTS_SORT:
      return {
        ...state,
        sortParams: action.payload,
      };

    default:
      return state;
  }
}

export default Products;
