import {
  CH_CAMPAIGN_REQUEST,
  CH_CAMPAIGN_SUCCESS,
  CH_CAMPAIGN_FAILED,
} from "../../reducers/constants";
import Api from "../../services/configApi";

export function fetch(campaignId = "") {
  return async (dispatch: any) => {
    dispatch({
      type: CH_CAMPAIGN_REQUEST,
    });

    try {
      const response = await Api.get(
        `/PressJsonsRows/${campaignId}?press_type=choi_campaign`
      );
      dispatch({
        type: CH_CAMPAIGN_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: CH_CAMPAIGN_FAILED,
        error,
      });
    }
  };
}
