import React from "react";
import { Button } from "antd";
import { useAppSelector } from "../reducers/cart/hooks";
import { TypeOfStates } from "../types/stateTypes";
import { createMarkup } from "../lib/Functions";

interface UserProfileProps {
  userProfile: any;
}

export const HumanSizeTable = ({ userProfile }: UserProfileProps) => (
  <div className="w-full">
    <table
      style={{
        width: "100%",
        textTransform: "uppercase",
        fontSize: 12,
        color: "#323232",
        marginTop: 10,
      }}
    >
      <tbody>
        {userProfile?.fields?.C?.fields &&
          userProfile.fields.C.fields.map((field: any, index: number) => (
            <React.Fragment key={field.field_id}>
              {index > 5 && (
                <tr
                  style={{
                    background: index % 2 === 0 ? "#f5f5f5" : "#FFF",
                    color: "#000",
                  }}
                >
                  <td
                    style={{
                      padding: 8,
                    }}
                  >
                    {field.description}
                  </td>
                  <td
                    style={{
                      textTransform: "lowercase",
                      color: "#000",
                      padding: 8,
                      borderLeft: "gray",
                      borderLeftStyle: "solid",
                      borderLeftWidth: 1,
                    }}
                  >
                    {field.value}
                    {field.field_name === "customerweight" ? "kg" : "cm"}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
      </tbody>
    </table>
  </div>
);

type SectionTitleProps = {
  title: string;
  urlText?: string;
  url?: string;
  onClick?: () => void;
  isUrlText?: boolean;
  marginTop?: number;
  marginBottom?: number;
};
export const SectionTitle = ({
  title,
  urlText = "Edit | Засварлах",
  url = "/account/edit",
  onClick,
  isUrlText = true,
  marginTop = 14,
  marginBottom = 14,
}: SectionTitleProps) => {
  return (
    <div className="profile-section-title">
      <h5
        style={{
          marginTop: marginTop,
          marginBottom: marginBottom,
        }}
      >
        {title}
      </h5>
      {isUrlText === true && (
        <Button type={"text"} onClick={onClick}>
          {urlText}
        </Button>
      )}
    </div>
  );
};

export const PointsInfo = () => {
  const front_settings = useAppSelector(
    (state: TypeOfStates) => state.mainMenus.front_settings
  );

  if (!front_settings.CUSTOMER_PROFILE_DESCRIPTION_TEXT) {
    return <></>;
  }

  return (
    <div className="w-full ">
      <div
        dangerouslySetInnerHTML={createMarkup(
          front_settings.CUSTOMER_PROFILE_DESCRIPTION_TEXT?.description
        )}
      />
    </div>
  );
};
