import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";
import i18n from "../../utils/i18n";
import * as blogsActions from "../../reducers/actions/blogsActions";
import * as newPressActions from "../../reducers/actions/newPressActions";
import Loader from "../Loader";
import { createMarkup } from "../../lib/Functions";
import ImageComponent from "../../components/ImageComponent";
import { BlogTitle } from "../../types/blogs";
import * as layoutActions from "../../reducers/actions/layoutActions";
import * as commonActions from "../../reducers/actions/commonActions";
import { Layout } from "../../layouts";
import NewpressItems from "../../components/NewpressItems";

const DetailScreen = () => {
  const selectedLanguageName = useAppSelector(
    (state: TypeOfStates) => state.settings.selectedLanguage.name
  );
  const dispatch = useAppDispatch();
  const blogDetail = useAppSelector(
    (state: TypeOfStates) => state.blog?.blogDetail
  );
  const fetching = useAppSelector(
    (state: TypeOfStates) => state.blog?.fetching
  );
  const newpressItems = useAppSelector((state: TypeOfStates) => state.newpress);
  const { pressId } = useParams<"pressId">();

  useEffect(() => {
    dispatch(blogsActions.fetchPageDetail(pressId, 'press'));
    dispatch(commonActions.mainNavShowHide(true));
    dispatch(newPressActions.fetch(Number(pressId), 'press'));
  }, [dispatch, pressId, selectedLanguageName]);

  useEffect(() => {
    if (
      blogDetail?.pages &&
      Number(blogDetail?.pages?.linked_layout_location_id) > 0
    ) {
      dispatch(
        layoutActions.fetchLayout(
          Number(blogDetail?.pages?.linked_layout_location_id),
          3
        )
      );
    }
  }, [blogDetail?.pages, dispatch]);

  return (
    <Layout>
      <div className="product-page-horizontal-layout">
        <div className="blog-screen">
          {fetching && <Loader />}
          <div className="top-categories">
            <div className="category-links">
              {blogDetail?.get_titles?.map(
                (title: BlogTitle, index: number) => (
                  <Link
                    to={"/press?pt=" + title?.page_id}
                    key={title?.page_id}
                    className={"link"}
                  >
                    {title.page}
                  </Link>
                )
              )}
            </div>
          </div>

          {blogDetail?.pages ? (
            <>
              {blogDetail?.pages?.new_layout_view === "Y" ? (
                <NewpressItems items={newpressItems.newpressItems} />
              ) : (
                <div className="press-detail-section">
                  <h1 className="title">{blogDetail?.pages?.page}</h1>
                  <ImageComponent
                    src={blogDetail?.pages?.main_pair?.icon?.image_path}
                    style={{
                      width: "100%",
                      maxHeight: 800,
                      objectFit: "cover",
                      objectPosition: "top left",
                      padding: 20,
                    }}
                  />
                  <div
                    className="blog-description"
                    dangerouslySetInnerHTML={createMarkup(
                      blogDetail?.pages?.description
                    )}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="empty-category">
              <h2>{i18n.t("Coming soon")}</h2>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default DetailScreen;
