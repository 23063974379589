import { get } from "lodash";
import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_FAIL,
  AUTH_LOGOUT,
  AUTH_REGESTRATION_SUCCESS,
  REGISTER_DEVICE_SUCCESS,
  RESTORE_STATE,
  AUTH_LOGIN_SUCCESS,
  SET_UUID,
  UNSUBSCRIBE_FROM_NOTIFICATIONS_SUCCESS,
  AUTH_LOADING,
  AUTH_LOADED,
  AUTH_REGESTRATION_FAIL,
  GET_VERIFY_CODE_REQUEST,
  GET_VERIFY_CODE_SUCCESS,
  GET_VERIFY_CODE_FAIL,
} from "./constants";

import { AuthState } from "../types/authTypes";

const initialState: AuthState = {
  token: "",
  ttl: 0,
  logged: false,
  uuid: "",
  fetching: false,
  error: "",
  errorStatus: 0,
  deviceToken: "",
  profile_id: "",
  resetPasswordStatus: "",
  pushNotificationId: 0,
  verifyFetching: false
};

function AuthReduce(state = initialState, action: any) {
  switch (action.type) {
    case GET_VERIFY_CODE_REQUEST:
    case GET_VERIFY_CODE_FAIL:
      return {
        ...state,
        verifyFetching: true,
      };
    case GET_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        verifyFetching: false,
      };

    case AUTH_REGESTRATION_FAIL:
      return {
        ...state,
        fetching: false,
        error: "error",
        errorStatus: 400,
      };
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
        errorStatus: null,
      };

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        ttl: action.payload.ttl,
        logged: true,
        error: null,
        errorStatus: null,
      };

    case AUTH_REGESTRATION_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        ttl: action.payload.ttl,
        logged: true,
        fetching: false,
        error: null,
        errorStatus: null,
      };

    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload?.message,
        errorStatus: action.payload?.status,
      };

    case REGISTER_DEVICE_SUCCESS:
      return {
        ...state,
        deviceToken: action.payload.token,
        pushNotificationId: action.payload.id,
      };

    case AUTH_LOGOUT:
      return { ...initialState, uuid: state.uuid };

    case SET_UUID:
      return { ...state, uuid: (+new Date()).toString(16) };

    case UNSUBSCRIBE_FROM_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        pushNotificationId: null,
      };

    case RESTORE_STATE:
      return {
        ...state,
        ...action.payload.auth,
        uuid: get(action.payload, "auth.uuid")
          ? action.payload.auth.uuid
          : (+new Date()).toString(16),
      };

    case AUTH_LOADING:
      return {
        ...state,
        fetching: true,
      };

    case AUTH_LOADED:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}

export default AuthReduce;
