import { MessengerChat } from "react-messenger-chat-plugin";

const MessengerChatComp = () => {
  return (
    <div className="facebook-chat-div">
      <MessengerChat
        pageId="1594360100777175"
        themeColor={"#000000"}
        loggedInGreeting=""
        loggedOutGreeting=""
      />
    </div>
  );
};

export default MessengerChatComp;
 