import { useState } from "react";
import { Button } from "antd";
import earthGrid from "../assets/icons/earth-grid.svg";
import { drawerContext } from "../hooks/DrawerModal";
import { TypeOfStates } from "../types/stateTypes";
import { useAppDispatch, useAppSelector } from "../reducers/cart/hooks";
import { Language } from "../types/settings";
import * as settingsActions from "../reducers/actions/settingsActions";
import * as appActions from "../reducers/actions/appActions";
import { STORE_LANGUAGE_CODE } from "../reducers/constants";
import LanguageList from "./LanguageList";

const LanguageSelectionBtn = () => {
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);

  const changeLanguageHandler = async (language: Language) => {
    localStorage.setItem(STORE_LANGUAGE_CODE, language.langCode);
    dispatch(settingsActions.setLanguage(language))
      .then(() =>
        appActions
          .initApp()
          .then(() => {
            drawerContext.hide();
            setLoader(false);
          })
          .catch((e) => setLoader(false))
      )
      .catch((e) => setLoader(false));
  };

  return (
    <Button
      className="language-selection-btn"
      onClick={() =>
        drawerContext.show(
          <LanguageList
            changeLanguageHandler={changeLanguageHandler}
            loader={loader}
            setLoader={setLoader}
            getStates={getStates}
          />,
          "",
          "right"
        )
      }
    >
      <img src={earthGrid} width={22} height={20} alt={""} />
      <span
        style={{
          textTransform: "uppercase",
          fontSize: 15,
          marginLeft: 4,
          marginRight: 4,
        }}
      >
        {getStates.settings.selectedLanguage.langCode}
      </span>
    </Button>
  );
};

export default LanguageSelectionBtn;
