/* eslint-disable @typescript-eslint/indent */
import { useEffect, useState } from "react";
import { useAppSelector } from "../reducers/cart/hooks";
import { TypeOfStates } from "../types/stateTypes";
import { createMarkup } from "../lib/Functions";
import { notification } from "antd";
import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons";

const Notifications = () => {
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const notifications = getStates.notifications;
  const [lastNotif, setLastNotif] = useState<any>();

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (notifications?.items) {
      setLastNotif(
        notifications.items[getStates.notifications.items.length - 1]
      );
    }
  }, [getStates.notifications, notifications.items]);

  useEffect(() => {
    if (lastNotif?.text) {
      api.open({
        message: "",
        description: (
          <div style={{
            fontWeight: "bold"
          }} dangerouslySetInnerHTML={createMarkup(lastNotif.text)} />
        ),
        placement: "top",
        icon:
          lastNotif.type === "success" ? (
            <CheckOutlined style={{ color: "black", fontSize: 18 }} />
          ) : (
            <ExclamationOutlined style={{ color: "red", fontSize: 18 }} />
          ),
      });
    }
  }, [api, lastNotif]);

  return <>{contextHolder}</>;
};

export default Notifications;
