import React from "react";
import { Button, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import i18n from "../utils/i18n";
import { drawerContextHide } from "../lib/Functions";

interface Props {
  loader: boolean;
  setLoader: (arg: boolean) => void;
  changeLanguageHandler: (arg: any) => void;
  getStates: any
}
const LanguageList: React.FC<Props> = ({
  loader,
  setLoader,
  changeLanguageHandler,
  getStates
}) => {
  return (
    <div className="language-selection-list">
      <Button
        onClick={() => drawerContextHide()}
        className="close-btn "
        type="text"
        size="small"
      >
        <CloseOutlined className="icon" />
      </Button>
      <Spin spinning={loader}>
        <div className="header">
          <div className="text">
            <span>{i18n.t("Select your language")}</span>
          </div>
        </div>
        <div className="languages">
          <Button
            onClick={() => {
              if (getStates.settings.selectedLanguage.langCode !== "mn") {
                setLoader(true);
                changeLanguageHandler({
                  langCode: "mn",
                  name: "Mongolia",
                  selected: true,
                })
              }
            }}
            type={"text"}
            className={`${0 % 2 === 0 ? "topbtn" : "bottombtn"} 
                
                ${
                  getStates.settings.selectedLanguage.langCode === "mn"
                    ? `active language-mn 0`
                    : `language-mn 0`
                }
  
                `}
          >
            Монгол
          </Button>
          <Button
            onClick={() => {
              if (getStates.settings.selectedLanguage.langCode !== "en") {
                setLoader(true);
                changeLanguageHandler({
                  langCode: "en",
                  name: "English",
                  selected: true,
                })
              }
            }}
            type={"text"}
            className={`${1 % 2 === 0 ? "topbtn" : "bottombtn"} 
                
                ${
                  getStates.settings.selectedLanguage.langCode === "en"
                    ? `active language-mn 1`
                    : `language-mn 1`
                }
  
                `}
          >
            English
          </Button>
        
        </div>
      </Spin>
    </div>
  );
};

export default LanguageList;
