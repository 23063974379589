/* eslint-disable @typescript-eslint/default-param-last */

/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import {MINI_CART_SHOW_REQUEST} from './constants';

type InitProps = {
  show: boolean;
};
const initialState: InitProps = {
  show: false,
};

function miniCartShowHide(state: any = initialState, action: any) {
  switch (action.type) {
    case MINI_CART_SHOW_REQUEST:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export default miniCartShowHide;
