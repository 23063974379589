export const MOBILE_BANNER_KEY = 'MOBILE_BANNER_KEY';
export const GIFT_BOX_PRODUCT_ID = 'GIFT_BOX_PRODUCT_ID';
export const PURCHASE_AND_POINT_MESSAGE = 'PURCHASE_AND_POINT_MESSAGE';
export const CONTACT_US_PRODUCT_PAGE = "CONTACT_US_PRODUCT_PAGE";
export const CUSTOMER_PROFILE_DESCRIPTION_TEXT = "CUSTOMER_PROFILE_DESCRIPTION_TEXT";
export const CUSTOMER_SERVICE_PHONE_TEXT = "CUSTOMER_SERVICE_PHONE_TEXT";
export const AGREE_POLICY_REGISTER = "AGREE_POLICY_REGISTER";
export const SIZE_GUIDE_PRODUCT = "SIZE_GUIDE_PRODUCT";


