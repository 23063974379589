/* eslint-disable @typescript-eslint/indent */

import { NOTIFICATION_HIDE, NOTIFICATION_SHOW } from "../constants";

export function showOrHide(isShow = false) {
  return (
    dispatch: (arg0: { type: string; payload: { show: boolean } }) => void
  ) => {
    dispatch({
      type: NOTIFICATION_SHOW,
      payload: {
        show: isShow,
      },
    });
  };
}

export function showWithTimer(type = "", title = "", text = "", second = 5000) {
  return (dispatch: any) => {
    dispatch({
      type: NOTIFICATION_SHOW,
      payload: {
        show: true,
        type,
        title,
        text,
      },
    });
    setTimeout(() => {
      dispatch({
        type: NOTIFICATION_HIDE,
        payload: {
          show: false,
        },
      });
    }, second);
  };
}
