/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import CategoriesProduct from "../../components/CategoryPage/Product";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { createMarkup } from "../../lib/Functions";
import * as categoryActions from "../../reducers/actions/categoryActions";
import * as productsActions from "../../reducers/actions/productsActions";
import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";
import Loader from "./Loader";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Row, Col } from "antd";
import i18n from "../../utils/i18n";
import AdvancedModalFilter from "../../components/CategoryPage/AdvancedModalFilter";
import { useWindowWidth } from "@react-hook/window-size";
import { Layout } from "../../layouts";

const CategoryScreeen = () => {
  const [sortBy, setSortBy] = useState<string>("timestamp");
  const [sortOrder, setSortOrder] = useState<string>("asc");
  const { categoryId } = useParams<"categoryId">();
  const dispatch = useAppDispatch();
  const categoryState = useAppSelector((state: TypeOfStates) => state.category);
  const allstates = useAppSelector((state: TypeOfStates) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemsPerPage, setItemsPerPage] = useState(200);
  const subcategoryId = searchParams.get("sc");
  const [page, setPage] = useState(1);
  const selectedLangCode = allstates.settings.selectedLanguage.langCode;
  const [getShowFilterView, setShowFilterView] = useState<boolean>(false);
  const [activeCatId, setactiveCatId] = useState(categoryId || 0);
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);
  const [getFilters, setFilters] = useState<any>(allstates?.products?.filters);
  const [getFeaturesHash, setFeaturesHash] = useState<string>();

  const isMobile = allstates.isMobile.status;
  const screenWidth = useWindowWidth();

  useEffect(() => {
    if (categoryId) {
      if (subcategoryId && Number(subcategoryId) > 0) {
        setactiveCatId(subcategoryId);
        dispatch(categoryActions.fetchCategoryDetail(Number(subcategoryId)));
      } else {
        setactiveCatId(categoryId);
        dispatch(categoryActions.fetchCategoryDetail(Number(categoryId)));
      }
    }
  }, [categoryId, dispatch, subcategoryId, selectedLangCode]);

  useEffect(() => {
    if (Number(categoryId) > 0) {
      dispatch(categoryActions.fetchCategoryTree(Number(categoryId)));
    }
  }, [categoryId, dispatch, itemsPerPage, page, selectedLangCode]);

  useEffect(() => {
    if (Number(categoryId) > 0 && activeCatId) {
      dispatch(
        productsActions.fetchByCategory(Number(activeCatId), page, false, {
          items_per_page: itemsPerPage,
        })
      );
    }
  }, [activeCatId, categoryId, dispatch, itemsPerPage, page, selectedLangCode]);

  const LoadData = useCallback(() => {
    if (activeCatId) {
      let advParams: any = {};

      if (getFeaturesHash) {
        advParams = { features_hash: getFeaturesHash };
      }
      dispatch(
        productsActions.fetchByCategory(
          Number(activeCatId),
          page,
          false,
          advParams
        )
      );
    }
  }, [activeCatId, dispatch, getFeaturesHash, page]);

  useEffect(() => {
    LoadData();
  }, [
    categoryId,
    itemsPerPage,
    sortBy,
    sortOrder,
    page,
    getFeaturesHash,
    getFilters,
    activeCatId,
    LoadData,
    selectedLangCode,
  ]);

  useEffect(() => {
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
    return () => {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    };
  }, []);

  const categoryTree = useMemo(() => {
    if (
      categoryState.categorytree &&
      Object.keys(categoryState.categorytree).length > 0
    ) {
      return Object.values(categoryState.categorytree).sort(
        (a: any, b: any) => a.position - b.position
      );
    }
    return [];
  }, [categoryState.categorytree]);

  const widthColumns = () => {
    return isMobile ? 24 : screenWidth < 880 ? 12 : 8;
  };

  return (
    <Layout>
      <div className="product-page-horizontal-layout">
        <div className="top-categories">
          {categoryState.categorytree &&
            Object.values(categoryState.categorytree).length === 0 && (
              <h2 className="page-category-title">
                {categoryState?.categorydetail?.page_title}
              </h2>
            )}

          {categoryState.categorytree &&
            Object.values(categoryState.categorytree).length > 0 && (
              <div className="category-links">
                <Link
                  className={` link ${
                    subcategoryId === null ? "font-bold" : ""
                  }`}
                  to={`/category/${categoryId}`}
                >
                  {i18n.t("view_all")}
                </Link>
                {Object.values(categoryTree).map((category: any) => (
                  <Link
                    style={{}}
                    key={category.category_id}
                    className={`link  ${
                      activeCatId === category.category_id ? "font-bold" : ""
                    }`}
                    to={`/category/${categoryId}?sc=${category.category_id}`}
                  >
                    {category.category}
                  </Link>
                ))}
              </div>
            )}

          {categoryState.categorydetail &&
            categoryState.categorydetail?.description && (
              <div
                className="category-description"
                style={
                  screenWidth < 600
                    ? {
                        marginLeft: 20,
                        marginRight: 20,
                      }
                    : {}
                }
                dangerouslySetInnerHTML={createMarkup(
                  categoryState.categorydetail?.description
                )}
              />
            )}
        </div>

        <div className="filter-and-products">
          <div className="filter">
            {activeCatId && (
              <AdvancedModalFilter
                filters={allstates?.products?.filters}
                params={allstates?.products?.params}
                onChangeFilter={(filters: any) => {
                  setFilters({ filters });
                  setFeaturesHash(filters);
                }}
                setShowFilterView={() => setShowFilterView(false)}
                setActiveFiltersCount={setActiveFiltersCount}
                isMobile={isMobile}
              />
            )}
            {allstates?.products?.items[activeCatId] &&
            allstates?.products?.items[activeCatId].length > 0 && (
              <span className="total-active-products-count">
                Нийт: {allstates?.products?.items[activeCatId].length}
              </span>
            )}
          </div>

          

          <div className="products">
            {categoryState.fetching && <Loader />}
            {!categoryState.fetching && (
              <Fragment>
                {activeCatId && allstates?.products?.items[activeCatId] && (
                  <Row gutter={[0, 26]}>
                    {Object.values(allstates?.products?.items[activeCatId]).map(
                      (product: any) => (
                        <Col
                          lg={widthColumns()}
                          xl={widthColumns()}
                          xxl={widthColumns()}
                          md={widthColumns()}
                          xs={24}
                          sm={24}
                          key={product.product_id}
                        >
                          <CategoriesProduct
                            key={product.product_id}
                            product={{
                              item: product,
                            }}
                          />
                        </Col>
                      )
                    )}
                  </Row>
                )}
                {!activeCatId ||
                  !allstates?.products?.items[activeCatId] ||
                  (Object.values(allstates?.products?.items[activeCatId])
                    .length === 0 && (
                    <div className="empty-category">
                      <h2>{i18n.t("Coming soon")}</h2>
                    </div>
                  ))}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CategoryScreeen;
