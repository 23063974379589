/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_FAIL,
  FETCH_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS_REQUEST,
  SEARCH_PRODUCTS_FAIL,
  SEARCH_PRODUCTS_SUCCESS,
  FETCH_ONE_PRODUCT_REQUEST,
  FETCH_ONE_PRODUCT_FAIL,
  FETCH_ONE_PRODUCT_SUCCESS,
  RECALCULATE_PRODUCT_PRICE_REQUEST,
  RECALCULATE_PRODUCT_PRICE_FAIL,
  RECALCULATE_PRODUCT_PRICE_SUCCESS,
  FETCH_DISCUSSION_REQUEST,
  FETCH_DISCUSSION_SUCCESS,
  FETCH_DISCUSSION_FAIL,
  DISCUSSION_DISABLED,
  CHANGE_PRODUCTS_SORT,
  FETCH_COMMON_PRODUCTS_REQUEST,
  FETCH_COMMON_PRODUCTS_FAIL,
  FETCH_COMMON_PRODUCTS_SUCCESS,
  FETCH_CART_PRODUCTS_REQUEST,
  FETCH_CART_PRODUCTS_SUCCESS,
  FETCH_CART_PRODUCTS_FAIL,
} from "../constants";
import Api from "../../services/configApi";
import { filterObject } from "../../utils/index";

export function fetchDiscussion(
  id: any,
  params: any = { page: 1 },
  type: any = "P"
) {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_DISCUSSION_REQUEST,
    });

    return Api.get(
      `/sra_discussion/?object_type=${type}&object_id=${id}&params[page]=${params.page}`
    )
      .then((response: any) => {
        dispatch({
          type: FETCH_DISCUSSION_SUCCESS,
          payload: {
            id: `${type.toLowerCase()}_${id}`,
            page: params.page,
            discussion: response.data,
          },
        });
        return response.data;
      })
      .catch((error: any) => {
        dispatch({
          type: FETCH_DISCUSSION_FAIL,
          error,
        });
      });
  };
}

//
export function recalculatePrice(pid: any, options: any) {
  function formatOptionsToUrl(selectedOptions: any) {
    const options: any = [];
    Object.keys(selectedOptions).forEach((optionId) => {
      options.push(
        `${encodeURIComponent(`selected_options[${optionId}]`)}=${
          selectedOptions[optionId].variant_id
        }`
      );
    });
    return options.join("&");
  }

  return async (dispatch: any) => {
    dispatch({ type: RECALCULATE_PRODUCT_PRICE_REQUEST });

    return Api.get(`sra_products/${pid}/?${formatOptionsToUrl(options)}`)
      .then((response: any) => {
        response.data = filterFeaturesAndVariations(response.data);
        dispatch({
          type: RECALCULATE_PRODUCT_PRICE_SUCCESS,
          payload: {
            product: response.data,
            pid,
          },
        });
      })
      .catch((error: any) => {
        dispatch({
          type: RECALCULATE_PRODUCT_PRICE_FAIL,
          error,
        });
      });
  };
}

const filterFeaturesAndVariations = (oldProductData: any) => {
  const newProductData: any = { ...oldProductData };

  if (!newProductData.variation_features_variants) {
    return oldProductData;
  }

  // Filters variants field of variation_features_variants elements.
  // If the variant doesn`t have product_id, we just delete this variant from the list.
  Object.keys(newProductData.variation_features_variants).forEach(
    (featureVariant: any) => {
      newProductData.variation_features_variants[featureVariant].variants =
        filterObject(
          newProductData.variation_features_variants[featureVariant].variants,
          (variant: any) => variant.product_id
        );
    }
  );

  // Checking if the variation has options. If not, we make it a feature.
  newProductData.variation_features_variants = filterObject(
    newProductData.variation_features_variants,
    (featuresVariant: any) => Object.keys(featuresVariant.variants).length > 1
  );

  // We remove features, if they are in variations.
  newProductData.product_features = filterObject(
    newProductData.product_features,
    (feature: any) =>
      !Object.keys(newProductData.variation_features_variants).includes(
        feature.feature_id
      )
  );

  return newProductData;
};

const convertProductOptions = (oldProductOptions: any) => {
  const newProductOptions: any = Object.keys(oldProductOptions).map(
    (option) => {
      const newProductOption: any = { ...oldProductOptions[option] };
      const OPTION_TYPE_IMAGES: any = "I";

      // If option has images, we change option type to 'I'
      if (
        Object.keys(
          newProductOption?.variants[Object.keys(newProductOption.variants)[0]]
            .image_pair
        ).length
      ) {
        newProductOption.option_type = OPTION_TYPE_IMAGES;
      }

      newProductOption.selectTitle = oldProductOptions[option].option_name;
      newProductOption.selectDefaultId = oldProductOptions[option].option_id;

      newProductOption.selectVariants = Object.keys(
        oldProductOptions[option].variants
      ).map((variantId: any) => {
        const selectVariant: any = {
          ...oldProductOptions[option].variants[variantId],
        };
        selectVariant.selectVariantName = selectVariant.variant_name;
        if (Object.keys(selectVariant.image_pair).length) {
          selectVariant.selectImgPath =
            selectVariant.image_pair.icon.image_path;
        }

        selectVariant.selectValue = selectVariant.variant_name;
        selectVariant.selectId = selectVariant.option_id;

        return selectVariant;
      });

      return newProductOption;
    }
  );

  return newProductOptions;
};

// Interface SsProps {
//   dropdown_images: string
//   dropdown_labels: string
//   dropdown: string
// }
const convertProductVariants = (oldProductVariants: any) => {
  const featureStyleValues: any = {
    dropdown_images: "I",
    dropdown_labels: "S",
    dropdown: "S",
  };

  if (oldProductVariants) {
    const newProductVariants: any = Object.keys(oldProductVariants).map(
      (variant: any) => {
        const newProductVariant = { ...oldProductVariants[variant] };
        newProductVariant.selectTitle =
          oldProductVariants[variant].internal_name;
        newProductVariant.selectDefaultId =
          oldProductVariants[variant].variant_id;
        newProductVariant.option_type =
          featureStyleValues[oldProductVariants[variant].feature_style];

        newProductVariant.selectVariants = Object.keys(
          oldProductVariants[variant].variants
        ).map((variantId) => {
          const selectVariant = {
            ...oldProductVariants[variant].variants[variantId],
          };
          selectVariant.selectVariantName = selectVariant.variant;
          if (selectVariant.product?.main_pair?.detailed?.image_path) {
            selectVariant.selectImgPath =
              selectVariant.product.main_pair.detailed.image_path;
          }

          selectVariant.selectValue = selectVariant.variant;
          selectVariant.selectId = selectVariant.variant_id;
          return selectVariant;
        });

        return newProductVariant;
      }
    );

    return newProductVariants;
  }

  return [];
};

export function fetch(pid: any) {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_ONE_PRODUCT_REQUEST,
      payload: {
        pid,
      },
    });
    try {
      const res = await (await Api.get(`/sra_products/${pid}`)).data;
      const response = filterFeaturesAndVariations(res);
      if (response?.product_options) {
        response.convertedOptions = convertProductOptions(
          response?.product_options
        );
      }
      if (response?.variation_features_variants) {
        response.convertedVariants = convertProductVariants(
          response?.variation_features_variants
        );
      }

      dispatch({
        type: FETCH_ONE_PRODUCT_SUCCESS,
        payload: {
          product: response,
          pid,
        },
      });
      // Load discussion if it is not disabled.
      if (response?.discussion_type !== DISCUSSION_DISABLED) {
        setTimeout(async () => fetchDiscussion(pid)(dispatch), 100);
      }

      return response;
    } catch (error: any) {
      dispatch({
        type: FETCH_ONE_PRODUCT_FAIL,
        payload: {
          pid,
        },
        error,
      });
    }
  };
}

export function fetchProductOffers(pid: any) {
  return async (dispatch: any) => {
    dispatch({ type: FETCH_COMMON_PRODUCTS_REQUEST });

    return Api.get(
      `/sra_products/?vendor_products_by_product_id=${pid}&sort_by=price`
    )
      .then((response: any) => {
        dispatch({
          type: FETCH_COMMON_PRODUCTS_SUCCESS,
        });
        return response;
      })
      .catch((error: any) => {
        dispatch({
          type: FETCH_COMMON_PRODUCTS_FAIL,
          error,
        });
      });
  };
}

export function search(params: any = {}) {
  return async (dispatch: any) => {
    dispatch({ type: SEARCH_PRODUCTS_REQUEST });
    return Api.get("/sra_products", {
      params: {
        ...params,
      },
    })
      .then((response: any) => {
        dispatch({
          type: SEARCH_PRODUCTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: SEARCH_PRODUCTS_FAIL,
          error,
        });
      });
  };
}

export function fetchByCategory(
  categoryId: number | 0,
  page = 1,
  companyId = false,
  advParams: any = {}
) {
  const params: any = {
    page,
    subcats: "Y",
    items_per_page: 100,
    company_id: companyId || "",
    get_filters: true,
    cid: categoryId,
    ...advParams,
  };
  return async (dispatch: any) => {
    dispatch({ type: FETCH_PRODUCTS_REQUEST });
    await Api.get(`categories/${categoryId}/sra_products`, {
      params,
    })
      .then((response: any) => {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: FETCH_PRODUCTS_FAIL,
          error,
        });
      });
  };
}

export function changeSort(params: any) {
  return (dispatch: any) => {
    dispatch({
      type: CHANGE_PRODUCTS_SORT,
      payload: params,
    });
  };
}

export const productIdBySlug = async (productSlug = "") => {
  const r = await Api.get(`/get_seoname?object_name=${productSlug}&type=p`);
  return r.data;
};

export function fetchProducts(pid: any) {
  return async (dispatch: any) => {
    dispatch({ type: FETCH_CART_PRODUCTS_REQUEST });
    return Api.get(`/sra_products&pid=${pid}`)
      .then((response: any) => {
        dispatch({
          type: FETCH_CART_PRODUCTS_SUCCESS,
          payload: {
            pid_products: response.products,
            pid_params: response.params,
          },
        });
      })
      .catch((error: any) => {
        dispatch({
          type: FETCH_CART_PRODUCTS_FAIL,
          error,
        });
      });
  };
}
