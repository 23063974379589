/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import * as React from "react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../reducers/cart/hooks";
import * as layoutActions from "../reducers/actions/layoutActions";
import * as commonActions from "../reducers/actions/commonActions";

import { TypeOfStates } from "../types/stateTypes";
import Loader from "./Loader";
import GridRender from "../lib/grid-render";
import { Layout } from "../layouts";

function IndexScreen() {
  const dispatch = useAppDispatch();
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const isMobile = getStates.isMobile.status;
  const getpageblocks = getStates.getpageblocks;
  const langCode = getStates.settings.selectedLanguage.langCode;
  
  useEffect(() => {
    dispatch(layoutActions.fetchHomeLayout());
    dispatch(commonActions.mainNavShowHide(true));
  }, [dispatch, langCode]);

  if (getpageblocks.fetching || !getpageblocks.grids.homelayouts) {
    return <Loader />;
  }

  return (
    <Layout>
      {
        <GridRender
          grids={getpageblocks.grids.homelayouts}
          isMobile={isMobile}
        />
      }
    </Layout>
  );
}

export default IndexScreen;
