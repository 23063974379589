import { Row, Col, Image } from "antd";
export const Youtube: React.FC<{
  item: any;
}> = ({ item }) => {
  return (
    <div className="youtube-container" key={item.id}>
      <iframe
        src={`//www.youtube.com/embed/${item.value}?autoplay=1`}
        frameBorder="0"
        allowFullScreen
        className="youtube-video"
        title={item?.value}
        allow="autoplay"
      ></iframe>
    </div>
  );
};

export const ImageItem: React.FC<{
  item: any;
}> = ({ item }) => {
  return (
    <Image
      key={item.id}
      src={item?.value}
      alt=""
      style={{
        objectFit: "cover",
        objectPosition: "center center",
        width: "100%",
      }}
    />
  );
};
