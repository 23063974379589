/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/default-param-last */

import { BlogDetail, Blogs } from "../types/blogs";
import {
  BLOGS_REQUEST,
  BLOGS_SUCCESS,
  BLOGS_FAILED,
  BLOG_DETAIL_REQUEST,
  BLOG_DETAIL_SUCCESS,
  BLOG_DETAIL_FAILED,
} from "./constants";
type Initialstate = {
  fetching: boolean;
  blogs: Blogs;
  blogDetail: BlogDetail;
};
const initialState: Initialstate = {
  fetching: false,
  blogs: {},
  blogDetail: {}
};

const localCart = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BLOGS_REQUEST:
    case BLOG_DETAIL_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case BLOGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        blogs: action.payload,
      };

    case BLOG_DETAIL_SUCCESS:
      return {
        ...state,
        fetching: false,
        blogDetail: action.payload,
      };

    case BLOGS_FAILED:
    case BLOG_DETAIL_FAILED:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};

export default localCart;
