import * as Spinner from "react-spinners";
const Loader = () => {

  return (
    <div className="category-loader">
      <Spinner.PropagateLoader color="#202020" />
    </div>
  );
};

export default Loader;
