/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Carousel } from "antd";
import { TypeOfStates } from "../types/stateTypes";
import { useAppSelector } from "../reducers/cart/hooks";
import ArrowRight from "../assets/svg/ArrowRight.svg";
import ArrowLeft from "../assets/svg/ArrowLeft.svg";
import ArrowRightSmall from "../assets/svg/ArrowRightSmall.svg";
import ArrowLeftSmall from "../assets/svg/ArrowLeftSmall.svg";
import i18n from "../utils/i18n";

const arrowRightBox: React.CSSProperties = {
  top: "50%",
  zIndex: 9,
  position: "absolute",
  right: 4,
  cursor: "pointer",
  height: 50,
  width: 50,
};
const arrowLeftBox: React.CSSProperties = {
  top: "50%",
  zIndex: 9,
  position: "absolute",
  left: 4,
  cursor: "pointer",
  height: 50,
  width: 50,
};

export const carouselSettings = (
  getStates: TypeOfStates,
  slidesToShow = 1,
  slidesToScroll = 1,
  dots = true,
  responsive?: any,
  carouselType?: "S"
) => {
  const styleArrow = {
    fontSize: getStates.isMobile.status ? 16 : 30,
    color: "#c5c5c5",
  };

  const contentStyleArrowRight: React.CSSProperties = {
    height: getStates.isMobile.status ? 32 : 74,
    width: getStates.isMobile.status ? 32 : 50,
    background: "none",
    boxShadow: "none",
  };

  const ArrowLeft = ({ type, style, className, onClick }: any) => (
    <div style={arrowLeftBox} className={""} onClick={() => onClick()}>
      <Button
        type="text" 
        style={contentStyleArrowRight}
        icon={
          carouselType === "S" ? (
            <LeftArrow style={styleArrow} />
          ) : (
            <LeftArrowSmall style={styleArrow} />
          )
        }
      />
    </div>
  );
  const ArrowRight = ({ type, style, className, onClick }: any) => (
    <div style={arrowRightBox} className={""} onClick={() => onClick()}>
      <Button
        type="text"
        style={contentStyleArrowRight}
        icon={
          carouselType === "S" ? (
            <RightArrow style={styleArrow} />
          ) : (
            <RightArrowSmall style={styleArrow} />
          )
        }
      />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    draggable: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    focusOnSelect: true,
    ...responsive,
  };

  return settings;
};

const BannerBlock: React.FC<any> = (props): any => {
  const { items }: any = props;
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const settings = carouselSettings(getStates, 1, 1, true, "", "S");
  const navigate = useNavigate();

  const settings3 = carouselSettings(
    getStates,
    Object.values(items).length === 2 ? 2 : 3,
    1,
    false,
    "",
    "S"
  );

  if (props?.block_properties === "addons/banners/blocks/carousel.tpl") {
    return (
      <Carousel
        {...settings3}
        className={"carousel-products"}
        style={{
          marginBottom: 0,
        }}
      >
        {items &&
          Object.values(items).map((item: any) => (
            <div key={item?.banner_id} className={"carusel-product"}>
              {item?.image_path && (
                <img
                  alt={"choihainzan.com"}
                  src={item?.image_path}
                  placeholder="blur"
                  style={{
                    objectFit: "cover",
                    width: "99%",
                    objectPosition: "center center",
                    height: getStates.isMobile.status === true ? 400 : "100%",
                  }}
                  className=""
                />
              )}
            </div>
          ))}
      </Carousel>
    );
  }

  return (
    <Carousel {...settings}>
      {items &&
        Object.values(items).map((item: any) => (
          <div
            key={item?.banner_id}
            className="mt-0 relative w-full bg-blue-100 flex items-end "
          >
            <div
              className={`absolute z-10 text-center text-white mb-6 ml-20 
                ${
                  getStates.isMobile.status
                    ? "slider-item-left-mobile "
                    : " slider-item-left-web"
                }  `}
            >
              {item?.show_title === "Y" && (
                <h1
                  className={`uppercase margin-0 ${
                    getStates.isMobile.status ? "font-3" : " font-36 "
                  } `}
                  style={getStates.isMobile.status ? { fontSize: 14 } : {}}
                >
                  {item?.banner}
                </h1>
              )}

              {item?.url && item.url.length > 0 && (
                <Button
                  onClick={() => navigate(item?.url)}
                  className={`${
                    getStates.isMobile.status
                      ? "home-category-link-btn-mobile "
                      : "home-category-link-btn"
                  } `}
                  style={
                    getStates.isMobile.status
                      ? {
                          background: "#FFF",
                          paddingLeft: 20,
                          paddingRight: 20,
                        }
                      : {}
                  }
                  size={"small"}
                >
                  {i18n.t("Discover")}
                </Button>
              )}
            </div>

            {item?.image_path && (
              <img
                alt={"choihainzan.com"}
                src={item?.image_path}
                placeholder="blur"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  objectPosition: "left center",
                  height: getStates.isMobile.status === true ? 280 : "100%",
                }}
                className=""
              />
            )}
          </div>
        ))}
    </Carousel>
  );
};

export default BannerBlock;

export function RightArrow({ onClick, style }: any) {
  return <img onClick={onClick} style={style} alt="left" src={ArrowRight} />;
}

export function LeftArrow({ onClick, style }: any) {
  return <img onClick={onClick} style={style} alt="left" src={ArrowLeft} />;
}

export function RightArrowSmall({ onClick, style }: any) {
  return (
    <img onClick={onClick} style={style} alt="left" src={ArrowRightSmall} />
  );
}

export function LeftArrowSmall({ onClick, style }: any) {
  return (
    <img onClick={onClick} style={style} alt="left" src={ArrowLeftSmall} />
  );
}
