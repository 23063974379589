import { useEffect } from "react";
import { Layout } from "../layouts";
import * as commonActions from "../reducers/actions/commonActions";
import { useAppDispatch } from "../reducers/cart/hooks";

function NoMatchScreen() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(commonActions.mainNavShowHide(true));
  }, [dispatch]);

  return (
    <Layout>
      <div
        className="
        bg-white mt-10 max-width-layout
          text-center  flex   items-center flex-col

        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={0.5}
          stroke="currentColor"
          className="w-20 h-20 mt-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>

        <h2
          className="mt-1 mb-20 text-lg"
          style={{
            fontFamily: "system-ui",
          }}
        >
          Уучлаарай хуудас үүсээгүй байна
        </h2>
      </div>
    </Layout>
  );
}

export default NoMatchScreen;
