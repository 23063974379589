import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";
import i18n from "../../utils/i18n";
import Loader from "../Loader";
import * as commonActions from "../../reducers/actions/commonActions";
import * as campaignActions from "../../reducers/actions/campaignActions";
import { Layout } from "../../layouts";
import NewpressItems from "../../components/CampaignItems";

const CampaignDetailScreen = () => {
  const selectedLanguageName = useAppSelector(
    (state: TypeOfStates) => state.settings.selectedLanguage.name
  );
  const dispatch = useAppDispatch();
  const campaignDetail = useAppSelector(
    (state: TypeOfStates) => state.campaign.detail
  );
  const fetching = useAppSelector(
    (state: TypeOfStates) => state.campaign?.fetching
  );
  const { campaignId } = useParams<"campaignId">();

  useEffect(() => {
    dispatch(campaignActions.fetch(campaignId));
    dispatch(commonActions.mainNavShowHide(true));
  }, [dispatch, campaignId, selectedLanguageName]);

  return (
    <Layout>
      <div
        className="campaign-layout"
        style={{
          paddingTop: 0,
          width: "100%",
        }}
      >
        <div className="blog-screen">
          {fetching && <Loader />}

          {campaignDetail ? (
            <NewpressItems items={campaignDetail} />
          ) : (
            <div className="empty-category">
              <h2>{i18n.t("Coming soon")}</h2>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CampaignDetailScreen;
