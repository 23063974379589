import user from "../../assets/svg/user.svg";
import wishlist from "../../assets/svg/wishlist.svg";

export function UserIcon({ onClick, style }: any) {
  return <img onClick={onClick} style={style} alt="left" src={user} />;
}

export function WishlistIcon({ onClick, style }: any) {
  return <img onClick={onClick} style={style} alt="left" src={wishlist} />;
}
