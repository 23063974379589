import {
  MAINMENU_REQUEST,
  MAINMENU_SUCCESS,
  MAINMENU_FAILED,
} from "./constants";

const initialState = {
  fetching: false,
};

function MainMenu(state = initialState, action: any) {
  switch (action.type) {
    case MAINMENU_REQUEST:
    case MAINMENU_FAILED:
      return {
        ...state,
        fetching: true,
      };

    case MAINMENU_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}

export default MainMenu;
