/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */

import axios from "axios";
import config from "../config";
import store from "../reducers/store";
import {
  AUTH_LOGOUT,
  SHOP_CLOSED,
  STORE_KEY_AUTH,
} from "../reducers/constants";
import base64 from "base-64";
const AxiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 100000,
});

const getLocalStorage = localStorage.getItem(STORE_KEY_AUTH);

AxiosInstance.interceptors.request.use((conf: any) => {
  const state: any = store.getState();
  let newConf: any = {};

  newConf = {
    ...conf,
  };

  newConf.headers.StorefBearerToken = config.apiKey;
  newConf.headers["Cache-Control"] = "no-cache";
  newConf.params = conf.params || {};
  newConf.params.items_per_page = 40;
  newConf.params.s_layout = 2;
  newConf.params.sl = state.settings.selectedLanguage.langCode;
  newConf.params.lang_code = state.settings.selectedLanguage.langCode;
  newConf.headers.Authorization = "empty";
  if (state?.auth?.token) {
    newConf.headers.Authorization = `Basic ${base64.encode(
      state?.auth?.token
    )}:`;
  }

  if (getLocalStorage && JSON.parse(getLocalStorage)?.auth?.token) {
    newConf.headers.Authorization = `Basic ${base64.encode(
      JSON.parse(getLocalStorage)?.auth?.token
    )}:`;
  }

  return newConf;
});

AxiosInstance.interceptors.response.use(
  (config: any) => config,
  async (error: any) => {
    if (error.response?.status === 401) {
      store.dispatch({
        type: AUTH_LOGOUT,
      });
    } else if (
      error.response?.status === 408 ||
      error.code === "ECONNABORTED"
    ) {
      console.log(`A time happend on url ${error.config.url}`);
    } else if (error.response?.status === 404) {
      store.dispatch({
        type: SHOP_CLOSED,
      });
    }

    return Promise.reject(error);
  }
);

export default AxiosInstance;
