import { Row, Col, Image } from "antd";
import { Fragment, useEffect, useState } from "react";
import * as Spinner from "react-spinners";

interface PropsImages {
  productDetail: any;
  sortable: any;
  setVisiblePairId: (arg: number) => void;
  onClickImage: () => void;
  fetching: boolean;
}
const ProductImages: React.FC<PropsImages> = ({
  productDetail,
  sortable,
  setVisiblePairId,
  fetching,
  onClickImage,
}) => {
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setInterval(() => setLoad(fetching), 700);
  }, [fetching]);

  if (!productDetail?.product?.main_pair?.detailed?.image_path) {
    return <></>;
  }

  if (load) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "98vh",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Spinner.PropagateLoader color="#202020" />
      </div>
    );
  }

  return (
    <div className="product-detail-images">
      <Row gutter={[9, 9]}>
        {sortable &&
          Object.values(sortable).map((productImage: any, index: number) => {
            if (index <= 3) {
              return (
                <Col key={index} span={12}>
                  <ImageView
                    src={productImage.detailed.image_path}
                    pairId={Number(productImage.pair_id)}
                    setVisiblePairId={setVisiblePairId}
                    onClick={() => onClickImage()}
                  />
                </Col>
              );
            } else {
              return <Fragment key={index}></Fragment>;
            }
          })}

        {sortable && Object.values(sortable).length <= 3 ? (
          <Col span={12}>
            <ImageView
              src={productDetail?.product?.main_pair?.detailed?.image_path}
              pairId={Number(productDetail?.product.main_pair.pair_id)}
              setVisiblePairId={setVisiblePairId}
              onClick={() => onClickImage()}
            />
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row gutter={[8, 8]} className="mt-10">
        {sortable &&
          Object.values(sortable)?.map(
            (productImage: any, index: number) =>
              index > 3 && (
                <Col
                  key={index}
                  span={Object.keys(sortable).length % 2 !== 0 ? 12 : 8}
                >
                  <ImageView
                    src={productImage.detailed.image_path}
                    pairId={Number(productImage.pair_id)}
                    setVisiblePairId={setVisiblePairId}
                    onClick={() => onClickImage()}
                  />
                </Col>
              )
          )}

        {sortable && Object.values(sortable).length > 3 && (
          <Col
            span={
              Object.keys(sortable).length < 6 ||
              Object.keys(sortable).length % 2 !== 0
                ? 12
                : 8
            }
          >
            <ImageView
              src={productDetail?.product?.main_pair?.detailed?.image_path}
              pairId={Number(productDetail?.product.main_pair.pair_id)}
              setVisiblePairId={setVisiblePairId}
              onClick={() => onClickImage()}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ProductImages;

export function ImageView({
  src,
  pairId,
  setVisiblePairId,
  onClick,
}: {
  src: string;
  pairId: number;
  setVisiblePairId: (arg: number) => void;
  onClick: () => void;
}) {
  return (
    <Image
      preview={{ visible: false }}
      style={{
        objectFit: "fill",
        objectPosition: "center center",
      }}
      src={src}
      loading="lazy"
      onMouseOver={() => setVisiblePairId(Number(pairId))}
      onClick={() => {
        setVisiblePairId(Number(pairId));
        onClick();
      }}
    />
  );
}
