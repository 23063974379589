import Loader from "../../layouts/Loader";
import { useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";

const ContactBox: React.FC<any> = () => {
  const allstate = useAppSelector(
    (state: TypeOfStates) => state.mainMenus.front_settings
  );

  if (!allstate?.CONTACT_US_PRODUCT_PAGE) {
    return <Loader />;
  }

  return (
    <div className="contact-block services-block">
      <div className="services-block__row">
        <div className="services-block__row__icon">
          <span aria-hidden="true" className="icon css-1itvdnv" color="black">
            <svg
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 17.28c-1.63 0-3.73-.83-6.34-3.44-5.08-5.08-3.43-8.24-2-9.68A3.89 3.89 0 016.46 3a.46.46 0 01.37.13l2 2a.47.47 0 01.06.6L7.11 8.44l4.45 4.45 2.67-1.79a.48.48 0 01.6.06l2 2a.49.49 0 01.14.38 3.94 3.94 0 01-1.14 2.3 4.68 4.68 0 01-3.33 1.44zM6.33 4a3.12 3.12 0 00-1.5.83c-2.61 2.61-.35 6 2 8.32 2.35 2.32 5.72 4.62 8.33 2a3.12 3.12 0 00.83-1.5l-1.55-1.55-2.68 1.8a.48.48 0 01-.6-.06l-5-5a.48.48 0 01-.06-.6l1.79-2.68L6.33 4z"
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
        <div className="services-block__row__value">
          <span className="services-block__row__value__link__phone-link">
            {allstate.CONTACT_US_PRODUCT_PAGE.title}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactBox;
