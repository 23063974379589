
import {
  FETCH_MAIN_LAYOUTS_REQUEST,
  FETCH_MAIN_LAYOUTS_SUCCESS,
  FETCH_MAIN_LAYOUTS_FAIL,
  FETCH_HOMEPAGE_LAYOUTS_REQUEST,
  FETCH_HOMEPAGE_LAYOUTS_SUCCESS,
  FETCH_HOMEPAGE_LAYOUTS_FAIL,
  FETCH_CUSTOM_LAYOUTS_REQUEST,
  FETCH_CUSTOM_LAYOUTS_SUCCESS,
  FETCH_CUSTOM_LAYOUTS_FAIL
} from "../constants";
import Api from "../../services/configApi";

export function fetchLayout(locationId = 0, layoutId = 1) {
  return async (dispatch: any) => {
    dispatch({ type: FETCH_CUSTOM_LAYOUTS_REQUEST });

    try {
      const response = await Api.get(
        `/Homepage/${layoutId}?location_id=${locationId}`
      );
      dispatch({
        type: FETCH_CUSTOM_LAYOUTS_SUCCESS,
        payload: {
          homelayouts: response.data,
        },
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: FETCH_CUSTOM_LAYOUTS_FAIL,
        error,
      });
    }
  };
}

export function fetchHomeLayout() {
  const layoutId = 1,
    locationId = 2;
  return async (dispatch: any) => {
    dispatch({ type: FETCH_HOMEPAGE_LAYOUTS_REQUEST });

    try {
      const response = await Api.get(
        `/Homepage/${layoutId}?location_id=${locationId}`
      );
      dispatch({
        type: FETCH_HOMEPAGE_LAYOUTS_SUCCESS,
        payload: {
          homelayouts: response.data,
        },
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: FETCH_HOMEPAGE_LAYOUTS_FAIL,
        error,
      });
    }
  };
}

export function fetchMainLayout() {
  const layoutId = 1,
    locationId = 1;
  return async (dispatch: any) => {
    dispatch({ type: FETCH_MAIN_LAYOUTS_REQUEST });

    try {
      const response = await Api.get(
        `/Homepage/${layoutId}?location_id=${locationId}`
      );
      dispatch({
        type: FETCH_MAIN_LAYOUTS_SUCCESS,
        payload: {
          mainlayouts: response.data,
        },
      });
      return response;
    } catch (error) {
      dispatch({
        type: FETCH_MAIN_LAYOUTS_FAIL,
        error,
      });
    }
  };
}
