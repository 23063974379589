import * as React from "react";
import CartStepper from "../../components/Checkout/CartStepper";
import { useNavigate, useParams } from "react-router-dom";
import TheGiftBox from "../../components/TheGiftBox";
import Information from "../../components/Checkout/Information";
import * as productsActions from "../../reducers/actions/productsActions";
import * as cartActions from "../../reducers/actions/cartActions";
import * as shipmentLocationActions from "../../reducers/actions/shipmentLocationActions";
import * as commonActions from "../../reducers/actions/commonActions";

import { useEffect, useState } from "react";
import Loader from "../../layouts/Loader";
import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";
import { Row, Col, Spin } from "antd";
import i18n from "../../utils/i18n";
import { UpdateProfileParamsCheckout } from "../../types/authTypes";
import { GIFT_BOX_PRODUCT_ID } from "../../reducers/constants/settings";
import EmptyCart from "../../components/Checkout/EmptyCart";
import Api from "../../services/configApi";
import { NOTIFICATION_SHOW } from "../../reducers/constants";
import Layout from "../../layouts/Layout";
import VerifyScreen from "../CustomerAccount/VerifyScreen";

const CartScreen = React.lazy(() => import("./CartScreen"));
const CheckoutScreen = React.lazy(() => import("./CheckoutScreen"));
const ConfirmationScreen = React.lazy(() => import("./ConfirmationScreen"));

export interface CheckoutScreenProps {
  text: string;
  url: string;
  nexturl: string;
  key: string;
}
export interface CheckoutScreensProps {
  cart: CheckoutScreenProps;
  checkout: CheckoutScreenProps;
  confirmation: CheckoutScreenProps;
}
const checkoutScreens: CheckoutScreensProps = {
  cart: {
    text: i18n.t("Proceed to checkout"),
    url: "/checkout/cart",
    nexturl: "/checkout/checkout",
    key: "cart",
  },
  checkout: {
    text: "Place order",
    url: "/checkout/checkout",
    nexturl: "/checkout/confirmation",
    key: "checkout",
  },
  confirmation: {
    text: i18n.t("orderComplete"),
    url: "/checkout/confirmation",
    nexturl: "/checkout/complete",
    key: "confirmation",
  },
};

const checkoutFunction = (checkoutAction = "cart") => {
  if (checkoutAction === checkoutScreens.cart.key) {
    return checkoutScreens.cart;
  }
  if (checkoutAction === checkoutScreens.checkout.key) {
    return checkoutScreens.checkout;
  }
  if (checkoutAction === checkoutScreens.confirmation.key) {
    return checkoutScreens.confirmation;
  }
  return checkoutScreens.cart;
};

function CheckoutLayout() {
  const navigate = useNavigate();

  let { checkoutAction } = useParams<"checkoutAction">();

  const [loader, setLoader] = useState<boolean>(false);

  const [isbtnShow, setisbtnShow] = useState<boolean>(true);
  const [productIdGift, setProductIdGift] = useState<string>();
  const [isGiftShow, setIsGiftShow] = useState<boolean>(false);

  const [giftBoxCartId, setGiftBoxCartId] = useState<string>();
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const isMobile = getStates.isMobile.status;

  const dispatch = useAppDispatch(),
    getStateSelect = useAppSelector((state: TypeOfStates) => state),
    localCart = getStateSelect.localCart;

  const cart: any = getStateSelect.cart.cartdata;

  const removeCartProduct = (cartId: string) => {
    void dispatch(cartActions.remove(cartId));
  };

  const removeGiftCartProduct = () => {
    if (giftBoxCartId) {
      void dispatch(cartActions.remove(giftBoxCartId));
    }
  };

  useEffect(() => {
    void dispatch(cartActions.fetchCart(undefined));
    void dispatch(shipmentLocationActions.fetch());
    dispatch(commonActions.mainNavShowHide(true));
  }, [dispatch]);

  useEffect(() => {
    if (
      getStates.mainMenus?.front_settings &&
      getStates.mainMenus?.front_settings?.GIFT_BOX_PRODUCT_ID
    ) {
      setProductIdGift(
        getStates.mainMenus.front_settings[GIFT_BOX_PRODUCT_ID].product_id
      );
    }
  }, [getStates.mainMenus.front_settings]);

  useEffect(() => {
    if (getStates.cart.cartdata.products && productIdGift) {
      const dCartId = Object.keys(getStates.cart.cartdata.products).filter(
        (cartId: any) =>
          getStates.cart.cartdata.products[cartId].product_id === productIdGift
      );
      setGiftBoxCartId(dCartId[0]);
      setisbtnShow(dCartId.length > 0 ? true : false);
    }
  }, [getStates.cart, productIdGift]);

  const giftProductAmount = async (productIdGift: number) => {
    const res: any = await Api.get(`/sra_products/${productIdGift}`);
    return res.data;
  };

  const handleAddToGiftCart = () => {
    if (productIdGift) {
      setLoader(true);
      giftProductAmount(Number(productIdGift)).then((res: any) => {
        if (res.amount && Number(res.amount) > 0) {
          const products: any = {
            [productIdGift]: {
              product_id: productIdGift,
              amount: 1,
            },
          };
          dispatch(cartActions.add({ products }, true, {}));
          dispatch(cartActions.fetchCart());
          setLoader(false);
          navigate("/checkout/cart");
        } else {
          dispatch({
            type: NOTIFICATION_SHOW,
            payload: {
              type: "warning",
              title: i18n.t("Notice"),
              text: i18n.t("Барааны үлдэгдэл хүрэцэхгүй байна ."),
            },
          });
          setLoader(false);
        }
      });
    }
  };

  useEffect(() => {
    const pidArray: any[] = [];
    if (localCart?.list) {
      Object.values(localCart?.list).forEach((pids: any) => {
        pidArray.push(pids.pid);
      });
      const pidlist = pidArray.join(",");
      void dispatch(productsActions.fetchProducts(pidlist));
    }
  }, [dispatch, localCart?.list]);

  const nextStep = () => {
    if (checkoutAction) {
      navigate(checkoutFunction(checkoutAction).nexturl);
    }
  };

  const ActionScreen = () => {
    if (checkoutAction) {
      if (checkoutAction === checkoutScreens.cart.key) {
        return (
          <CartScreen
            cart={cart}
            removeCartProduct={removeCartProduct}
            screenProps={checkoutScreens.cart}
            onClick={nextStep}
          />
        );
      }
      if (checkoutAction === checkoutScreens.checkout.key) {
        return getStateSelect.shipmentLocations.fetching === false ? (
          <CheckoutScreen
            cart={cart}
            locations={getStateSelect.shipmentLocations}
            screenProps={checkoutScreens.cart}
            onSubmitData={(data: UpdateProfileParamsCheckout) => {
              void dispatch(cartActions.fetchCart());
              nextStep();
            }}
          />
        ) : (
          <div />
        );
      }
      if (checkoutAction === checkoutScreens.confirmation.key) {
        return (
          <ConfirmationScreen
            cart={cart}
            screenProps={checkoutScreens.confirmation}
            isbtnShow={isbtnShow}
            removeGiftCartProduct={removeGiftCartProduct}
            setLoader={(arg: boolean) => setLoader(arg)}
          />
        );
      }
    }

    return (
      <CartScreen
        cart={cart}
        removeCartProduct={removeCartProduct}
        screenProps={checkoutScreens.cart}
        onClick={nextStep}
      />
    );
  };

  if (getStateSelect.shipmentLocations.fetching) {
    return <Loader />;
  }

  if (!cart?.products) {
    return (
      <Layout>
        <EmptyCart />
      </Layout>
    );
  }

  if (Object.keys(cart?.products).length === 0) {
    return (
      <Layout>
        <EmptyCart />
      </Layout>
    );
  }
  
  if (getStateSelect.profile.verify_status === "N") {
    return <VerifyScreen />;
  }
  

  return (
    <Layout>
      <Spin spinning={loader} delay={500}>
        <div className="checkout-section">
          <div className="checkout-section-m-1122">
            {!loader && (
              <CartStepper step={checkoutAction} isMobile={isMobile} />
            )}
            <Row>
              <Col
                xs={24}
                sm={24}
                md={16}
                lg={16}
                xl={16}
                style={{
                  padding: 18,
                }}
              >
                <ActionScreen />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{
                  padding: 18,
                }}
              >
                <TheGiftBox
                  isShowBtn={isbtnShow}
                  addToCartGift={() => {
                    handleAddToGiftCart();
                    setIsGiftShow(true);
                  }}
                  loader={loader}
                />

                <Information />
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </Layout>
  );
}

export default CheckoutLayout;
