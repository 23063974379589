/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/indent */
import type { FC } from "react";
import React from "react";
import freeShippingSvg from "../../assets/icons/free-shipping.svg";
import returnExchanges from "../../assets/icons/return-exchanges.svg";
import shopSecurity from "../../assets/icons/shop-security.svg";
import { createMarkup } from "../../lib/Functions";
import { useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";

const Information: FC = () => {
  const allState = useAppSelector((state: TypeOfStates) => state);
  const frontSettings = allState.mainMenus.front_settings;

  if(!frontSettings?.CUSTOMER_SERVICE_PHONE_TEXT){
    return <></>
  }
  return (
    <div className="information-box">
      <div className="icons">
        <div className="icon-box">
          <img
            className=" m-auto  h-[46px] block"
            height={37}
            width={36}
            alt={""}
            src={freeShippingSvg}
          />
          <span> Free standard shipping on orders</span>
        </div>
        <div className="icon-box">
          <img height={37} width={36} alt={""} src={returnExchanges} />
          <span> Returns & exchanges</span>
        </div>
        <div className="icon-box">
          <img
            height={37}
            width={36}
            alt={""}
            src={shopSecurity}
            className=" m-auto  h-[46px] block"
          />
          <span> Shop securely</span>
        </div>
      </div>
      <h2 style={{
        fontSize: 13
      }}>{frontSettings.CUSTOMER_SERVICE_PHONE_TEXT.title}</h2>
      <div
        dangerouslySetInnerHTML={createMarkup(
          frontSettings.CUSTOMER_SERVICE_PHONE_TEXT.description
        )}
      />
    </div>
  );
};

export default Information;
