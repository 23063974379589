/// https://beta.choihainzan.com/api/4.0/Press/
import {
  FETCH_PAGE_REQUEST,
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_FAIL,
} from "../constants";
import Api from "../../services/configApi";

export function fetch(pageId = 0) {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_PAGE_REQUEST,
    });

    try {
      const response = await Api.get(`/page_data/${pageId}`);
      dispatch({
        type: FETCH_PAGE_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: FETCH_PAGE_FAIL,
        error,
      });
    }
  };
}
