import * as Spinner from "react-spinners";
const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Spinner.PropagateLoader color="#202020" />
    </div>
  );
};

export default Loader;
