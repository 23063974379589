/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-negated-condition */

import * as React from "react";
import { numberFormat } from "../../lib/Functions";
import { Link } from "react-router-dom";
import { Product } from "../../types/categoryScreen";
import { Card } from "antd";
import getWindowDimensions from "../../hooks/useWindowDimension";
import { useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";

type PropsI = {
  item: Product;
  ditem?: any;
};
type Props = {
  product: {
    item: Product;
  };
};

function CategoriesProduct({ product }: Props): any {
  const { Meta } = Card;
  const { item }: PropsI = product;
  const { width, height } = getWindowDimensions();
  const allState = useAppSelector((state: TypeOfStates) => state);
  const isMobile = allState.isMobile.status;
  const imageUri: string = item?.main_pair?.detailed?.image_path;

  const HeightCalc = () => {
    if (isMobile) {
      return 400;
    } else if (width && width < 980) {
      return 240;
    } else {
      return 450;
    }
  };

  return (
    <>
      <Link to={`/product/${item.product_id}?type=productId`}>
        <Card
          style={{
            borderRadius: 0,
            marginLeft: 12,
            marginRight: 12,
            border: "none",
          }}
          cover={
            <img
              alt="choihainzan.com"
              src={imageUri || "./assets/no-image-icon-4.png"}
              style={{
                border: "none",
                objectFit: "cover",
                objectPosition: "center center",
                height: HeightCalc(),
                background: "white",
                borderRadius: 0,
              }}
            />
          }
        >
          <Meta
            title={item.product}
            description={<DescriptionItem item={item} />}
          />
        </Card>
      </Link>
    </>
  );
}

export default CategoriesProduct;

interface DescProps {
  item: any;
}

export const DescriptionItem = ({ item }: DescProps) => {
  if (item?.list_price && Number(item?.list_price) > 0) {
    return (
      <>
        <span
          style={{
            textDecoration: "line-through",
            fontSize: 13,
          }}
        >
          {numberFormat(Number(item?.list_price))}
        </span>
        <span
          style={{
            color: "red",
            marginLeft: 5,
          }}
        >
          {numberFormat(item.price)}
        </span>
      </>
    );
  }
  return <>{numberFormat(item.price)}</>;
};
 