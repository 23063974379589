/* eslint-disable @typescript-eslint/indent */
import React from "react";
import {
  AppModalContext,
  drawerContext,
  DrawerDtEffectuate,
  ModalContextEffect,
  ModalContext,
} from "../../hooks/DrawerModal";
import { Drawer } from "antd";

const initialState: any = new DrawerDtEffectuate(false, <div></div>);

const AppModal = () => {
  const provider: ModalContextEffect = drawerContext as ModalContextEffect;
  provider.iniReducer(React.useReducer(provider.reducer, initialState));
  const onClose = () => {
    document.body.style.overflow = "auto";
  };
  return (
    <AppModalContext.Consumer>
      {(context: ModalContext) =>
        context.isVisible() && (
          <Drawer
            title=""
            closable={false}
            width={context.contextWidth()}
            placement={context.leftRight() === "left" ? "left" : "right"}
            onClose={() => {
              onClose();
              context.hide();
            }}
            open={context.isVisible()}
          >
            {context.isSpinning() ? <div>Loading</div> : context.getContent()}
          </Drawer>
        )
      }
    </AppModalContext.Consumer>
  );
};

export default AppModal;
