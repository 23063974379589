import { Dispatch } from "redux";
import { stripTags } from "../../utils/index";
import {
  DeviceInfoData,
  CreateProfileParams,
  LoginData,
  UpdateProfileParams,
  LoginOneTimePassword,
  UpdateProfileParamsCheckout,
} from "../../types/authTypes";

import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  AUTH_RESET_STATE,
  AUTH_REGESTRATION_REQUEST,
  AUTH_REGESTRATION_SUCCESS,
  AUTH_REGESTRATION_FAIL,
  NOTIFICATION_SHOW,
  REGISTER_DEVICE_REQUEST,
  REGISTER_DEVICE_SUCCESS,
  REGISTER_DEVICE_FAIL,
  FETCH_PROFILE_FIELDS_REQUEST,
  FETCH_PROFILE_FIELDS_SUCCESS,
  FETCH_PROFILE_FIELDS_FAIL,
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  STORE_KEY,
  AUTH_LOGOUT,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  UNSUBSCRIBE_FROM_NOTIFICATIONS_REQUEST,
  UNSUBSCRIBE_FROM_NOTIFICATIONS_SUCCESS,
  UNSUBSCRIBE_FROM_NOTIFICATIONS_FAIL,
  AUTH_LOADING,
  AUTH_LOADED,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILED,
  STORE_KEY_AUTH,
  STORE_USER_MAIL,
  GET_VERIFY_CODE_REQUEST,
  GET_VERIFY_CODE_SUCCESS,
  GET_VERIFY_CODE_FAIL
} from "../constants";

import store from "../../reducers/store";
import configApi from "../../services/configApi";
import * as wishListActions from "./wishListActions";
import * as cartActions from "./cartActions";
import { UpdateProfileResponce } from "../../types/Profile";
import * as notificationsActions from "./notificationsActions";
import i18n from "../../utils/i18n";
// import * as layoutsActions from './layoutsActions';

const { settings }: any = store.getState();

export function fetchProfile() {
  const params = {
    langCode: "mn", ///settings.selectedLanguage.langCode,
  };

  return async (dispatch: any) => {
    dispatch({ type: FETCH_PROFILE_REQUEST });
    try {
      const response = await configApi.get("/ChoihainzanProfile", { params });
      if (Number(response.data?.status) === 401) {

        localStorage.removeItem(STORE_KEY_AUTH);
        localStorage.removeItem(STORE_KEY);
        localStorage.removeItem(STORE_USER_MAIL);
        
        dispatch({
          type: FETCH_PROFILE_FAIL,
        });
        return response.data;
      } else {
        dispatch({
          type: FETCH_PROFILE_SUCCESS,
          payload: {
            ...response.data,
          },
        });
      }
      return response.data;
    } catch (error: any) {
      dispatch({
        type: FETCH_PROFILE_FAIL,
        payload: error,
      });
    }
  };
}

export function profileFields(data = {}) {
  const params = {
    location: "profile",
    action: "add",
    langCode: settings.selectedLanguage.langCode,
    ...data,
  };

  let method = "/ChoihainzanProfile";
  if (params.location === "profile" && params.action === "add") {
    method = "/sra_profile_fields"; // at Registration.js app has not access to /ChoihainzanProfile
  }

  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: FETCH_PROFILE_FIELDS_REQUEST });
    try {
      const response = await configApi.get(method, { params });
      dispatch({
        type: FETCH_PROFILE_FIELDS_SUCCESS,
        payload: {
          ...data,
          ...response.data,
        },
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: FETCH_PROFILE_FIELDS_FAIL,
        payload: error,
      });
    }
  };
}

export function updateProfileCheckOutFirst(
  id: string,
  data: UpdateProfileParamsCheckout
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    try {
      const responceProfile: UpdateProfileResponce = await (
        await configApi.put(`/ChoihainzanProfile/${id}`, data)
      ).data;

      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: {},
      });
      await cartActions.fetch()(dispatch);

      return responceProfile;
    } catch (error: any) {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload: error,
      });
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          show: true,
          type: "warning",
          title: "Profile update fail",
          text: stripTags(error.message),
        },
      });
    }
  };
}

export function updateProfileCheckOut(
  id: string,
  data: UpdateProfileParamsCheckout
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    try {
      const responceProfile: UpdateProfileResponce = await (
        await configApi.put(`/ChoihainzanProfile/${id}`, data)
      ).data;

      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: {},
      });
      dispatch(
        notificationsActions.showWithTimer(
          Number(responceProfile?.status) === 400 ? "error" : "success",
          "Profile update",
          responceProfile?.message
            ? responceProfile.message
            : "Your details have been amended on our database. ",
          6000
        )
      );
      await cartActions.fetch()(dispatch);

      return responceProfile;
    } catch (error: any) {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload: error,
      });
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          show: true,
          type: "warning",
          title: "Profile update fail",
          text: stripTags(error.message),
        },
      });
    }
  };
}


 
export function getVerifyCode() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: GET_VERIFY_CODE_REQUEST });
    try {
      const verifResponce: any = await (
        await configApi.get(`/ChoihainzanVerifyProfile`)
      ).data;

      dispatch({
        type: GET_VERIFY_CODE_SUCCESS,
        payload: {},
      });
      dispatch(
        
 
        notificationsActions.showWithTimer(
          verifResponce?.verifyCodeSend === false ? "error" : "success",
          "",
          verifResponce?.message
            ? verifResponce.message
            : "Тоог илгээхэд алдаа гарлаа . ",
          6000
        )
      );
      return verifResponce;
    } catch (error: any) {
      dispatch({
        type: GET_VERIFY_CODE_FAIL,
        payload: error,
      });
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          show: true,
          type: "warning",
          title: "Profile update fail",
          text: stripTags(error.message),
        },
      });
    }
  };
}


export function verifyProfile(id: string, data: UpdateProfileParams) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    try {
      const responceProfile: UpdateProfileResponce = await (
        await configApi.post(`/ChoihainzanVerifyProfile`, data)
      ).data;

      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: {},
      });
      dispatch(
        notificationsActions.showWithTimer(
          responceProfile?.status === "error" ? "error" : "success",
          "",
          responceProfile?.message
            ? responceProfile.message
            : "Баталгаажуулхад алдаа гарлаа. ",
          6000
        )
      );
      await cartActions.fetch()(dispatch);

      return responceProfile;
    } catch (error: any) {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload: error,
      });
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          show: true,
          type: "warning",
          title: "Profile update fail",
          text: stripTags(error.message),
        },
      });
    }
  };
}


export function updateProfile(id: string, data: UpdateProfileParams) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    try {
      const responceProfile: UpdateProfileResponce = await (
        await configApi.put(`/ChoihainzanProfile/${id}`, data)
      ).data;

      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: {},
      });
      dispatch(
        notificationsActions.showWithTimer(
          Number(responceProfile?.status) === 400 ? "error" : "success",
          "Profile update",
          responceProfile?.message
            ? responceProfile.message
            : "Your details have been amended on our database. ",
          6000
        )
      );
      await cartActions.fetch()(dispatch);

      return responceProfile;
    } catch (error: any) {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload: error,
      });
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          show: true,
          type: "warning",
          title: "Profile update fail",
          text: stripTags(error.message),
        },
      });
    }
  };
}

export function createProfile(data: CreateProfileParams) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: AUTH_REGESTRATION_REQUEST });

    try {
      const response = await configApi.post("/ChoihainzanProfile", data);

      dispatch({
        type: AUTH_REGESTRATION_SUCCESS,
        payload: {
          token: response.data.auth.token,
          ttl: response.data.auth.ttl,
          profile_id: response.data.profile_id,
          user_id: response.data.user_id,
        },
      });
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          type: "success",
          title: "Registration",
          text: "Registration complete.",
        },
      });
      await cartActions.fetch()(dispatch);
      const { auth, settings }: any = store.getState();

      deviceInfo({
        platform: "web",
        locale: settings.selectedLanguage.langCode,
        device_id: auth.uuid + "" + auth?.token,
        token: auth?.token,
      })(dispatch);
    } catch (error: any) {
      dispatch({
        type: AUTH_REGESTRATION_FAIL,
        payload: error,
      });
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          type: "warning",
          title: "Registration failed",
          text: stripTags(error?.response?.data?.message),
        },
      });
    }
  };
}

export function deviceInfo(data: DeviceInfoData) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: REGISTER_DEVICE_REQUEST });
    try {
      const response = await configApi.post("/sra_notifications", data);
      dispatch({
        type: REGISTER_DEVICE_SUCCESS,
        payload: {
          ...data,
          ...response.data,
        },
      });
    } catch (error) {
      dispatch({
        type: REGISTER_DEVICE_FAIL,
        payload: error,
      });
    }
  };
}

export function unsubscribeNotifications(id: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: UNSUBSCRIBE_FROM_NOTIFICATIONS_REQUEST });
    try {
      await configApi.delete(`/sra_notifications/${id}`);
      dispatch({ type: UNSUBSCRIBE_FROM_NOTIFICATIONS_SUCCESS });
    } catch (error) {
      dispatch({ type: UNSUBSCRIBE_FROM_NOTIFICATIONS_FAIL });
      console.log("Unsubscribe from notifications failed.", error);
    }
  };
}

export const getUserData = (response: { ttl: number; token: string }) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await cartActions.fetch()(dispatch);
      await wishListActions.fetch(false)(dispatch);
      dispatch({
        type: AUTH_LOGIN_SUCCESS,
        payload: response,
      });
      setTimeout(() => {
        const { auth, settings }: any = store.getState();
        deviceInfo({
          token: auth?.token,
          platform: "web",
          locale: settings?.selectedLanguage?.langCode,
          device_id: auth.uuid + "" + auth?.token,
        })(dispatch);
      }, 1000);
      await fetchProfile()(dispatch);
    } catch (error: any) {
      dispatch({
        type: AUTH_LOGIN_FAIL,
        payload: error.response,
      });
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          show: true,
          type: "warning",
          title: "Error",
          text: "Нууц үг буруу.",
        },
      });
    }
  };
};

export function loginFunction(data: LoginData) {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_LOGIN_REQUEST });
    try {
      const response = await configApi.post("/sra_auth_tokens", data);
      if (response.data?.token) {
        dispatch({
          type: AUTH_LOGIN_SUCCESS,
          payload: response.data,
        });

        setTimeout(async () => {
          await dispatch(getUserData(response.data));
        }, 1000);
        dispatch(authLoaded());
      }
      return response.data;
    } catch (error: any) {
      dispatch({
        type: AUTH_LOGIN_FAIL,
        payload: error.response.data,
      });

      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          show: true,
          type: "warning",
          title: "Error",
          text: "Нууц үг буруу.",
        },
      });
    }
  };
}

export function logout() {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: AUTH_LOGOUT,
    });

    localStorage.removeItem(STORE_KEY);
    localStorage.removeItem(STORE_KEY_AUTH);
    localStorage.removeItem(STORE_USER_MAIL);
  };
}

export function resetState() {
  return (dispatch: Dispatch<any>) => dispatch({ type: AUTH_RESET_STATE });
}

export async function resetPasswordNew(data: { email: string }) {
  try {
    const res = await configApi.post("/sra_one_time_passwords", data);
    return res;
  } catch (err) {
    return err;
  }
}
export function resetPassword(data: { email: string }) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });
    try {
      await configApi.post("/sra_one_time_passwords", data);
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });

      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          show: true,
          type: "success",
          title: i18n.t("Success"),
          text: "The username you have entered does not match any account in our store. Please make sure you have entered the correct username and try again. ",
        },
      });

      return true;
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAILED,
      });

      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          show: true,
          type: "warning",
          title: i18n.t("Error"),
          text: "The username you have entered does not match any account in our store. Please make sure you have entered the correct username and try again. ",
        },
      });

      return false;
    }
  };
}

export async function loginWithOneTimePassword1({
  email,
  one_time_password,
}: LoginOneTimePassword) {
  const res = await configApi.post("/sra_auth_tokens", {
    email,
    one_time_password: one_time_password,
  });

  return res;
}

export function loginWithOneTimePassword({
  email,
  one_time_password,
}: LoginOneTimePassword) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const res = await configApi.post("/sra_auth_tokens", {
        email,
        one_time_password: one_time_password,
      });

      return res;
    } catch (error) {
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          show: true,
          type: "warning",
          title: i18n.t("Error"),
          text: "Нууц үг буруу байна ",
        },
      });
      return {
        error: "Error",
        status: 404,
      };
    }
  };
}

export function showRegistrationNotification(isSuccess: boolean) {
  return (dispatch: Dispatch<any>) => {
    if (isSuccess) {
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          type: "success",
          title: "Registration",
          text: "Registration complete.",
        },
      });
    } else {
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          type: "warning",
          title: "Registration failed",
          text: "Something went wrong. Please try again later.",
        },
      });
    }
  };
}

export function authLoading() {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: AUTH_LOADING,
    });
  };
}

export function authLoaded() {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: AUTH_LOADED,
    });
  };
}

export function deleteAccount(token: string, comment: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: DELETE_ACCOUNT_REQUEST,
      });
      await configApi.post("/sra_account_removal_requests", {
        token,
        comment,
      });
      dispatch({
        type: DELETE_ACCOUNT_SUCCESS,
      });
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          type: "success",
          title: "Delete account",
          text: "Your request has been sent successfully.",
        },
      });
    } catch (error: any) {
      dispatch({
        type: DELETE_ACCOUNT_FAILED,
      });
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          type: "warning",
          title: "Account delete fail",
          text: stripTags(error.response.data.message),
        },
      });
    }
  };
}
