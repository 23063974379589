/* eslint-disable @typescript-eslint/indent */
import {
  MAINNAV_SHOW,
  MAINNAV_HIDE,
  RELOAD_DATA_REQUEST,
  RELOAD_DATA_DONE,
  TOPHEADER_SHOW,
  TOPHEADER_HIDE,
  FOOTER_SHOW,
  FOOTER_HIDE,
} from "../constants";

export function footerHideShow(isShow = false) {
  if (isShow === false) {
    return (dispatch: any) => {
      dispatch({
        type: FOOTER_HIDE,
        payload: {
          footerShow: false,
        },
      });
    };
  }
  return (dispatch: any) => {
    dispatch({
      type: FOOTER_SHOW,
      payload: {
        footerShow: true,
      },
    });
  };
}

export function topHeaderHide(isShow = false) {
  if (isShow === false) {
    return (dispatch: any) => {
      dispatch({
        type: TOPHEADER_HIDE,
        payload: {
          topHeaderShow: false,
        },
      });
    };
  }
  return (dispatch: any) => {
    dispatch({
      type: TOPHEADER_SHOW,
      payload: {
        topHeaderShow: true,
      },
    });
  };
}

export function mainNavShowHide(isShow = false) {
  if (isShow === false) {
    return (dispatch: any) => {
      dispatch({
        type: MAINNAV_HIDE,
        payload: {
          mainnavShow: false,
        },
      });
    };
  }
  return (dispatch: any) => {
    dispatch({
      type: MAINNAV_SHOW,
      payload: {
        mainnavShow: true,
      },
    });
  };
}

export function reloadData(reload = false) {
  if (reload === false) {
    return (dispatch: any) => {
      dispatch({
        type: RELOAD_DATA_REQUEST,
        payload: {
          mainnavShow: false,
        },
      });
    };
  }
  return (dispatch: any) => {
    dispatch({
      type: RELOAD_DATA_DONE,
      payload: {
        mainnavShow: true,
      },
    });
  };
}
