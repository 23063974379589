import * as appActions from "./appActions";
import {
  SET_LANGUAGE,
  SET_CURRENCY,
  SET_LANGUAGE_REQUEST,
  SET_LANGUAGE_SUCCESS,
} from "../constants";
import { Language } from "../../types/settings";

export const setLanguage = (language: Language) => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_LANGUAGE_REQUEST,
    });

    dispatch({
      type: SET_LANGUAGE,
      payload: language,
    });
    await appActions.initApp();
  };
};

export const setLanguageSuccess = () => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_LANGUAGE_SUCCESS,
    });
  };
};

export const setCurrency = (currency: any, language: Language) => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_CURRENCY,
      payload: currency,
    });
    await appActions.initApp();
  };
};
