/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/default-param-last */
import {
    FETCH_PAGE_REQUEST,
    FETCH_PAGE_SUCCESS,
    FETCH_PAGE_FAIL,
} from "./constants";
 
const initialState = {
  fetching: false,
  pageDetail: {}
};

const Page = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_PAGE_REQUEST: 
      return {
        ...state,
        fetching: true,
      };

    case FETCH_PAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        pageDetail: action.payload,
      };

    case FETCH_PAGE_FAIL:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};

export default Page;
