/* eslint-disable @typescript-eslint/indent */

import * as React from "react";
import { useNavigate } from "react-router-dom";
import type { Category } from "../../types/block";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button } from "antd";
import getWindowDimensions from "../../hooks/useWindowDimension";
type Props = {
  items: Category[];
  isMobile: boolean;
  block_properties: {
    title_location: "left" | "right" | "top" | "bottom" | "center";
  };
};
const CategoryBlockHomePage = ({
  items,
  isMobile,
  block_properties,
}: Props) => {
  const navigate = useNavigate();

  if (items && typeof items !== "object") {
    return null;
  }

  const { width } = getWindowDimensions();

  const classNameLocation = (title_location: string) => {
    const classMap: { [key: string]: string } = {
      right: "items-center justify-end",
      left: "items-center justify-start",
      top: "flex-direction-column-align-items-center",
      center: "items-center justify-center",
    };

    return classMap[title_location] ?? "items-end justify-center";
  };
  const classNameLocation2 = (title_location: string) => {
    switch (title_location) {
      case "bottom":
        return " px-6 mb-8 center-align-items";
      case "left":
        return " left-align-items";
      case "right":
        return " single  right-align-items";
      default:
        return "px-6  mb-8 center-align-items";
    }
  };

  return (
    <>
      {items &&
        Object.keys(items).length > 0 &&
        Object.values(items).map((category: Category, index: number) => (
          <div
            style={
              isMobile
                ? {
                    overflow: "hidden",
                    minHeight: 240,
                  }
                : { overflow: "hidden" }
            }
            key={index}
            className={`relative  flex ${classNameLocation(
              block_properties.title_location
            )} `}
          >
            <div
              className={`z-10  text-white absolute
            ${
              isMobile
                ? classNameLocation2(block_properties.title_location) +
                  "-mobile"
                : classNameLocation2(block_properties.title_location)
            } 
          `}
            >
              <h1
                className={`                        
                  ${
                    isMobile === true
                      ? " homecategory-btn-figma-mobile "
                      : " homecategory-btn-figma "
                  }         
                  ${category?.seo_name === "campaign" ? " txt-1 " : " txt-2 "} 
                `}
                style={
                  isMobile
                    ? {
                        fontSize: 18,
                        lineHeight: "20px",
                        padding: 8,
                      }
                    : {}
                }
              >
                {category?.category}
              </h1>

              {category?.category_id && (
                <Button
                  className={`${
                    isMobile === true
                      ? " home-category-link-btn-mobile "
                      : " home-category-link-btn "
                  }  `}
                  onClick={() => navigate(`/category/${category?.category_id}`)}
                  type="text"
                  size={isMobile ? "small" : "middle"}
                >
                  <span>{category?.page_title || "Shop the Collection"}</span>
                </Button>
              )}
            </div>
            {category?.image_path && width && (
              <LazyLoadImage
                src={category?.image_path}
                style={
                  isMobile
                    ? {
                        height: 280,
                        background: "#efefef",
                        marginBottom: 0,
                        objectFit: "cover",
                        objectPosition: "center center",
                      }
                    : {
                        height: "100%",
                        width: "100%",
                        background: "#efefef",
                        marginBottom: 0,
                      }
                }
                alt={category?.category}
              />
            )}
          </div>
        ))}
    </>
  );
};

export default CategoryBlockHomePage;
