/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { useCallback, useState } from "react";
import CustomerService from "../../components/CustomerService";
import CircleLogo from "../../assets/logo-circle.svg";
import { drawerContextHide } from "../../lib/Functions";
import i18n from "../../utils/i18n";
import ForgotPassword from "./ForgotPassword";
import LoginForm from "./LoginForm";
import LoginOneTimePassword from "./LoginOneTimePassword";

export type FormType = {
  type: "LOGIN" | "ONETIMEPASSWORD" | "FORGOT";
};
type Props = {
  loginTitle?: string;
};
function LoginLayout({ loginTitle }: Props) {
  const [formType, setFormType] = useState<FormType>({
    type: "LOGIN",
  });

  const stFormType = useCallback((type: FormType) => {
    setFormType(type);
  }, []);

  return (
    <>
      <div className="auth-form-drawer">
        <div
          className="header"
          style={
            formType.type === "FORGOT"
              ? {
                  marginBottom: 80,
                }
              : {}
          }
        >
          <img
            src={CircleLogo}
            width={50}
            height={50}
            alt={"logo "}
            className={"circle-logo"}
            onClick={() => drawerContextHide()}
          />
          <h1 className="account">
            {formType &&
              formType.type === "ONETIMEPASSWORD" &&
              i18n.t("One time password")}
            {formType &&
              formType.type === "FORGOT" &&
              i18n.t("Forgot your password")}
            {formType && formType.type === "LOGIN" && i18n.t("Account")}
          </h1>
        </div>

        <p className="required-information">
          *{i18n.t("Required information")}
        </p>
        {formType.type === "LOGIN" && <LoginForm setFormType={stFormType} />}
        {formType.type === "FORGOT" && (
          <ForgotPassword setFormType={stFormType} />
        )}
        {formType.type === "ONETIMEPASSWORD" && <LoginOneTimePassword />}
        <CustomerService />
      </div>
    </>
  );
}

export default LoginLayout;
