/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import * as React from "react";
import { createMarkup, productImagePairsSlider } from "../../lib/Functions";
import type {
  getSeoName,
  ProductDetail,
  TypeOfStates,
} from "../../types/stateTypes";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { useCallback, useEffect, useState } from "react";
import { ProductImageSlider } from "../../components/Product";
import CartButton from "../../components/ui/CartButton.tsx";
import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";

// import Actions ...
import * as productsActions from "../../reducers/actions/productsActions";
import * as layoutActions from "../../reducers/actions/localCartActions";
import * as cartActions from "../../reducers/actions/cartActions";
import * as wishListActions from "../../reducers/actions/wishListActions";
import * as commonActions from "../../reducers/actions/commonActions";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { drawerContext } from "../../hooks/DrawerModal";
import LoginLayout from "../../components/Auth/LoginLayout";
import { Image } from "antd";
import ContactBox from "../../components/Product/ContactBox";
import i18n from "../../utils/i18n";
import Loader from "../Loader";
import SimilarProducts from "../../components/Product/SimilarProducts";
import VariationFeaturesVariants, {
  FeatureVariantsSingleSelectedComp,
} from "../../components/Product/VariationFeaturesVariants";
import { Layout } from "../../layouts";
import { NOTIFICATION_SHOW } from "../../reducers/constants";
import ProductImages from "../../components/Product/ProductImages";
import {
  ErrorTextView,
  FindAboutiqueBtn,
  PriceComponent,
  ProductCode,
  ProductNameAndDescription,
  ProductSizeGuide,
} from "../../components/Product/Elements";

interface Props {}

const ProductScreen: React.FC<Props> = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let { productslug } = useParams<"productslug">();
  const screenWidth = useWindowWidth();
  const dispatch: any = useAppDispatch();
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const [rqAmount, setrqAmount] = useState<number>(1);
  const [authToken, setAuthToken] = useState<string>();
  const productDetail: ProductDetail = getStates.productDetail;
  const cart = getStates.cart;
  const isMobile = getStates.isMobile.status;
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [selectedProductAmount, setSelectedProductAmount] = useState<number>();
  const [sortable, setSortable] = useState<any>();
  const [selectedOptions, setselectedOptions] = useState<any>({});
  const [errortext, setErrortext] = useState<string>();
  const [amount, setAmount] = useState(0);
  const [productAmountCalc, setproductAmountCalc] = useState(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [visiblePairId, setVisiblePairId] = useState<number>(0);
  const langCode = getStates.settings.selectedLanguage.langCode;

  const selectVariant = useCallback(() => {
    if (!productDetail || !productDetail.product) return;
    const variants = productDetail.product.variation_features_variants;
    if (!variants || Object.values(variants).length === 0) {
      setSelectedProductId(productDetail.product.product_id);
      return;
    }
    const firstVariantData: any = Object.values(variants)[0];
    const variantValues = Object.values(firstVariantData.variants);
    if (variantValues.length === 1) {
      const filteredVariant: any = variantValues.filter(
        (variant: any) => variant.amount !== 0
      );
      if (filteredVariant.length) {
        setSelectedProductId(filteredVariant[0].product_id);
      }
    } else {
      const filteredVariant: any = variantValues.filter(
        (variant: any) => variant.amount > 0
      );
      if (filteredVariant.length === 1) {
        setSelectedProductId(filteredVariant[0].product_id);
      }
    }
  }, [productDetail]);

  useEffect(() => {
    selectVariant();
  }, [selectVariant]);

  useEffect(() => {
    console.log("productslug", productslug);
    if (productslug) {
      dispatch(productsActions.fetch(productslug));
    } else if (productslug && searchParams.get("type")) {
      productsActions
        .productIdBySlug(productslug)
        .then()
        .catch(() => {
          navigate("/");
        });
    } else {
      navigate("/");
    }
  }, [productslug, dispatch, searchParams, navigate, langCode]);

  useEffect(() => {
    dispatch(commonActions.mainNavShowHide(true));
    if (authToken) {
      dispatch(layoutActions.fetchDiscussion(authToken, 142));
    }
  }, [dispatch, authToken, langCode]);

  useEffect(() => {
    if (productDetail?.product && productDetail?.product?.image_pairs) {
      let sortble: any = [];
      Object.values(productDetail?.product?.image_pairs).forEach(
        (image: any) => (sortble[image?.position] = image)
      );

      setSortable(sortble);
    }
  }, [productDetail.product]);

  const handleAddToWishList = (productOffer?: any) => {
    if (!getStates.auth.logged) {
      document.body.style.overflow = "hidden";
      drawerContext.show(
        <LoginLayout loginTitle="Sign in" />,
        "",
        "right",
        false,
        undefined,
        undefined,
        isMobile ? 334 : 440
      );
      return;
    }

    const productOptions: any = {};
    const currentProduct = productOffer || productDetail.product;

    // Convert product options to the option_id: variant_id array.
    Object.keys(selectedOptions).forEach((k) => {
      productOptions[k] = selectedOptions[k];
      if (selectedOptions[k].variant_id) {
        productOptions[k] = selectedOptions[k].variant_id;
      }
    });

    const products: any = {
      [currentProduct.product_id]: {
        product_id: currentProduct.product_id,
        amount: currentProduct.selectedAmount || 1,
        product_options: productOptions,
      },
    };

    dispatch(wishListActions.add({ products }));
  };

  useEffect(() => {
    if (
      productDetail.product?.category_ids &&
      productDetail.product?.category_ids.length > 0
    ) {
      dispatch(
        productsActions.search({
          cid: productDetail.product?.category_ids.join(","),
        })
      );
    }
  }, [dispatch, productDetail.product]);

  const handleAddToCart = async () => {
    const showNotification = true;

    if (!getStates.auth.logged) {
      document.body.style.overflow = "hidden";
      drawerContext.show(
        <LoginLayout loginTitle="Sign in" />,
        "",
        "right",
        false,
        undefined,
        undefined,
        isMobile ? 334 : 440
      );
      return;
    }

    const productOptions: any = Object.entries(selectedOptions).reduce(
      (acc: any, [k, v]: any) => {
        acc[k] = v.variant_id || v;
        return acc;
      },
      {}
    );

    if (!selectedProductId) {
      setErrortext("Размер сонгоно уу ");
      return;
    }

    const product = {
      [productDetail.product.product_id]: {
        product_id: selectedProductId,
        amount: rqAmount,
        product_options: productOptions,
      },
    };

    if (cart.cartdata.products) {
      const cartProductIds: any = Object.values(cart.cartdata.products).map(
        (p) => p.product_id
      );
      if (cartProductIds.includes(String(productDetail.product.product_id))) {
        dispatch({
          type: NOTIFICATION_SHOW,
          payload: {
            type: "success",
            title: i18n.t(""),
            text: i18n.t("add_product_your_cart_info"),
          },
        });
        if (
          cartProductIds[
            cartProductIds.indexOf(String(productDetail.product.product_id))
          ].amount +
            rqAmount >
          productDetail.product.amount
        ) {
          return;
        }
      }
    }

    dispatch(cartActions.add({ products: product }, showNotification, {}));
  };

  useEffect(() => {
    if (
      productDetail?.product?.status &&
      Number(productDetail?.product?.status) === 404
    ) {
      navigate("/");
    }
    if (productDetail.product?.variation_features_variants) {
      let tmpTotal = 0;
      const vfvs: any = productDetail.product?.variation_features_variants;
      Object.values(vfvs).forEach((vfv: any) => {
        Object.values(vfv?.variants).forEach((vfv_v: any) => {
          tmpTotal += Number(vfv_v.amount);
        });
      });
      setAmount(tmpTotal);
    }
  }, [
    navigate,
    productDetail.product?.status,
    productDetail.product?.variation_features_variants,
  ]);

  useEffect(() => {
    if (productDetail?.product?.amount) {
      if (selectedProductAmount) {
        setproductAmountCalc(selectedProductAmount);
      } else if (
        productDetail?.product?.amount &&
        amount &&
        amount >= Number(productDetail?.product?.amount)
      ) {
        setproductAmountCalc(amount);
      } else {
        setproductAmountCalc(Number(productDetail?.product.amount));
      }
    }
  }, [
    amount,
    productDetail?.product,
    selectedProductAmount,
    productDetail?.product?.amount,
  ]);

  const MainMenus = getStates.mainMenus;
  const SizeGuideProduct = MainMenus?.front_settings?.SIZE_GUIDE_PRODUCT;

  if (productDetail.fetching) {
    return <Loader />;
  }

  return (
    <Layout>
      <React.Fragment>
        {productDetail.fetching && <Loader />}
        <div className="min-h-screen product-page-horizontal-layout">
          <div
            className={
              isMobile ? "product-detail-mobile" : "product-detail-screen"
            }
          >
            {isMobile ? (
              <ProductImageSlider
                product={productDetail.product}
                screenWidth={screenWidth}
              />
            ) : (
              <ProductImages
                productDetail={productDetail}
                sortable={sortable}
                setVisiblePairId={setVisiblePairId}
                onClickImage={() => setVisible(true)}
                fetching={productDetail.fetching}
              />
            )}

            <div
              className={
                isMobile
                  ? " description-line product-detail-section"
                  : "min-w-350 max-w-350 product-detail-section"
              }
            >
              <ProductNameAndDescription
                isMobile={isMobile}
                productDetail={productDetail}
              />
              <PriceComponent item={productDetail.product} />
              <p className="product-amount">
                {i18n.t("product_amount")}: {productAmountCalc}
              </p>
              <ProductCode product_code={productDetail.product.product_code} />
              <FindAboutiqueBtn />
              <ContactBox />
              <div className="product-description-size">
                <h2 className="font-bold mb-3 small-title">
                  {i18n.t("product_description")}
                </h2>
                <div
                  style={{
                    minHeight: 100,
                    marginTop: 2,
                    marginBottom: 20,
                    paddingBottom: 20,
                    borderBottom: "1px solid #d7d7d7",
                  }}
                  className="border-b border-gray-400 pb-6 mb-6"
                  dangerouslySetInnerHTML={createMarkup(
                    productDetail.product.full_description
                  )}
                />
                <h2 className="font-bold mb-3 small-title">
                  {i18n.t("size_and_fit")}
                </h2>
                {productDetail?.product &&
                  productDetail?.product?.product_features &&
                  Object.keys(productDetail?.product?.product_features).length >
                    0 && (
                    <ul>
                      {Object.values(
                        productDetail?.product?.product_features
                      ).map((feature: any) => (
                        <li key={feature.feature_id}>
                          <span
                            style={{
                              marginRight: 8,
                            }}
                          >
                            {feature?.description}:
                          </span>
                          <span>{feature?.variant}</span>
                        </li>
                      ))}
                    </ul>
                  )}
              </div>

              {productDetail.product?.variation_features_variants ? (
                <VariationFeaturesVariants
                  variation_features_variants={
                    productDetail.product.variation_features_variants
                  }
                  setSelectedProductId={(pid) => {
                    setSelectedProductId(pid);
                    setErrortext("");
                  }}
                  selectedProductId={selectedProductId}
                  setSelectedProductAmount={setSelectedProductAmount}
                />
              ) : (
                <>
                  {productDetail?.product &&
                    productDetail?.product?.product_features &&
                    Object.keys(productDetail?.product?.product_features)
                      .length > 0 &&
                    Object.values(productDetail?.product?.product_features).map(
                      (feature: any, index: number) => {
                        if (
                          feature?.description === "Размер" ||
                          feature?.description === "Size"
                        ) {
                          return (
                            <div
                              className="features_box"
                              key={feature?.feature_id}
                            >
                              <div className="feature_description">
                                {feature?.description}
                              </div>
                              <div className="selection">
                                {feature?.variants && (
                                  <FeatureVariantsSingleSelectedComp
                                    fvariants={feature?.variants}
                                    setSelectedProductId={setSelectedProductId}
                                    setSelectedProductAmount={
                                      setSelectedProductAmount
                                    }
                                    selectedProductId={
                                      productDetail.product.product_id
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          );
                        } else {
                          return <div key={index}></div>;
                        }
                      }
                    )}
                </>
              )}

              <ErrorTextView errortext={errortext} />
              <ProductSizeGuide
                SizeGuideProduct={SizeGuideProduct}
                isMobile={isMobile}
              />
              {Number(productDetail.product.amount) > 0 && (
                <CartButton
                  loading={productDetail.fetching}
                  onClick={handleAddToCart}
                  handleAddToWishList={handleAddToWishList}
                  isMobile={isMobile}
                />
              )}
              <br />
            </div>
          </div>

          <SimilarProducts
            items={getStates.search?.items}
            pid={productDetail.product.product_id}
            screenWidth={screenWidth}
            isMobile={isMobile}
            fetching={getStates.search.fetching}
          />
        </div>

        <div style={{ display: "none" }}>
          <Image.PreviewGroup
            preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
          >
            {productImagePairsSlider(productDetail.product, visiblePairId)
              .length > 0 &&
              productImagePairsSlider(productDetail.product, visiblePairId).map(
                (imagepairs, index) => {
                  if (imagepairs) {
                    return (
                      <Image src={imagepairs.detailed.image_path} key={index} />
                    );
                  } else {
                    return <React.Fragment key={index}></React.Fragment>;
                  }
                }
              )}
          </Image.PreviewGroup>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default ProductScreen;
