import React, { Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import { Button, Image } from "antd";
import { LeftArrow, RightArrow } from "../BannerBlock";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import ModalSlider from "../CampaignItems/ModalSlider";
interface Props {
  slideNo: number;
  slides: any;
  isMobile: boolean;
}
const arrowLeftBox: React.CSSProperties = {
  top: "50%",
  zIndex: 9,
  position: "absolute",
  left: 4,
  cursor: "pointer",
  height: 50,
  width: 50,
};

const arrowRightBox: React.CSSProperties = {
  top: "50%",
  zIndex: 9,
  position: "absolute",
  right: 4,
  cursor: "pointer",
  height: 50,
  width: 50,
};

const NewsSlider: React.FC<Props> = ({ slideNo = 3, slides, isMobile }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [isPreviewButton, setIsPreviewButton] = useState<boolean>(false);
  const [currentSlider, setCurrentSlider] = useState<number>(0);
  const [slidersReOrder, setslidersReOrder] = useState<any[]>([]);

  const contentStyleArrowRight: React.CSSProperties = {
    height: isMobile ? 32 : 74,
    width: isMobile ? 32 : 50,
    background: "none",
    boxShadow: "none",
  };

  const ArrowLeftBtn = ({ type, style, className, onClick }: any) => (
    <div style={arrowLeftBox} className={""} onClick={() => onClick()}>
      <Button type="text" style={contentStyleArrowRight} icon={<LeftArrow />} />
    </div>
  );

  const ArrowRightBtn = ({ type, style, className, onClick }: any) => (
    <div style={arrowRightBox} className={""} onClick={() => onClick()}>
      <Button
        type="text"
        style={contentStyleArrowRight}
        icon={<RightArrow />}
      />
    </div>
  );

  useEffect(() => {
    const nslides = Object.values(slides);
    const currentSliderSlides = nslides.filter(
      (values: any) => Number(values.slider_id) === +currentSlider
    );
    const otherSlides = nslides.filter((values: any) =>
      Number(values.slider_id !== +currentSlider)
    );
    setslidersReOrder([...currentSliderSlides, ...otherSlides]);
  }, [currentSlider, slides]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 2 : slideNo,
    slidesToScroll: isMobile ? 2 : slideNo,
    initialSlide: 0,
    draggable: true,
    nextArrow: <ArrowRightBtn />,
    prevArrow: <ArrowLeftBtn />,
    width: "100%",
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <Slider {...settings}>
          {slides &&
            Object.values(slides)
              .sort((a: any, b: any) => a.sort_order - b.sort_order)
              .map((slide: any, index: number) => (
                <div
                  key={index}
                  className="slider-nc"
                  onClick={() => {
                    setCurrentSlider(Number(slide.slider_id));
                    setVisible(true);
                  }}
                >
                  {slide && slide?.value && (slide?.value).length > 0 ? (
                    <Fragment>
                      <Image
                        className="image"
                        src={slide.value}
                        preview={{ visible: false }}
                        alt={""}
                        style={{
                          maxHeight: "800px",
                          padding: 2,
                          objectFit: "cover",
                          objectPosition: "center center",
                        }}
                        prefixCls={""}
                        prefix={"prefix"}
                        onClick={() => {
                          setCurrentSlider(Number(slide.slider_id));
                          setTimeout(() => setIsPreviewButton(true), 1000);
                        }}
                      />
                    </Fragment>
                  ) : (
                    ""
                  )}
                  {slide.text && slide.text.length > 0 && (
                    <div className="text">{slide.text}</div>
                  )}
                  {slide.button_url && slide.button_url.length > 0 && (
                    <div className="buttonDiv">
                      <Button
                        onClick={() => navigate(slide.button_url)}
                        className="button"
                      >
                        Shop the Collection
                      </Button>
                    </div>
                  )}
                </div>
              ))}
        </Slider>
      </div>

      {isPreviewButton ? (
        <ModalSlider
          currentSlider={currentSlider}
          slidersReOrder={slides}
          isMobile={isMobile}
          isShow={isPreviewButton}
          setIsModalOpen={setIsPreviewButton}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default NewsSlider;
