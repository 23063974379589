import { Button, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { drawerContext } from "../../hooks/DrawerModal";
import { createMarkup, numberFormat } from "../../lib/Functions";
import i18n from "../../utils/i18n";

export const FindAboutiqueBtn: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Button
      type={"text"}
      onClick={() => navigate("/boutiques")}
      style={{
        width: "100%",
        borderRadius: 999,
        marginTop: 10,
        marginBottom: 14,
        height: 40,
        border: "1px solid #bdbdbd",
        fontSize: 13,
      }}
    >
      {i18n.t("find_a_boutique")}
    </Button>
  );
};

interface PropsPCode {
  product_code: string;
}
export const ProductCode: React.FC<PropsPCode> = ({ product_code }) => {
  return (
    <p className="reference">
      {product_code && (
        <>
          {i18n.t("reference")}:{" "}
          <span
            style={{
              fontSize: 14,
            }}
          >
            {product_code}
          </span>
        </>
      )}
    </p>
  );
};

interface PNameDescProps {
  isMobile: boolean;
  productDetail: any;
}
export function ProductNameAndDescription({
  isMobile,
  productDetail,
}: PNameDescProps) {
  return (
    <>
      <h1
        className="product-name	"
        style={
          isMobile
            ? {
                fontSize: 14,
              }
            : {}
        }
      >
        {productDetail.product.product}
      </h1>
      <div
        className="short_description"
        dangerouslySetInnerHTML={createMarkup(
          productDetail.product.short_description
        )}
      />
    </>
  );
}

interface PropsPSizeGuide {
  SizeGuideProduct: any;
  isMobile: boolean;
}
export const ProductSizeGuide: React.FC<PropsPSizeGuide> = ({
  SizeGuideProduct,
  isMobile,
}) => {
  return (
    <>
      <br />
      {SizeGuideProduct && SizeGuideProduct.image && (
        <Button
          className="size-guide"
          size="small"
          type="text"
          style={{
            width: "100%",
            marginBottom: 10,
          }}
          onClick={() => {
            document.body.style.overflow = "hidden";
            drawerContext.show(
              <div>
                {SizeGuideProduct.image && (
                  <Image
                    src={SizeGuideProduct.image}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                    preview={false}
                    loading="lazy"
                  />
                )}
              </div>,
              "",
              "right",
              false,
              undefined,
              undefined,
              isMobile ? 334 : 440
            );
          }}
        >
          {i18n.t("size_guide")}
        </Button>
      )}
    </>
  );
};

interface ErrorProps {
  errortext?: string;
}

export const ErrorTextView = ({ errortext }: ErrorProps) => {
  if (!errortext) {
    return <></>;
  }
  return (
    <div
      style={{
        color: "red",
        textAlign: "center",
        fontSize: 13,
        padding: 5,
      }}
    >
      {errortext}
    </div>
  );
};

interface PriceProps {
  item: any;
}

export const PriceComponent = ({ item }: PriceProps) => {
  const discountPercent = Math.round((item?.price / item?.list_price) * 1000);

  return (
    <div className="price">
      {item?.list_price && item.list_price > 0 ? (
        <>
          <div className="discounted-detail">
            <span style={{ textDecoration: "line-through", fontSize: 15.5 }}>
              {numberFormat(item.list_price)}
            </span>
            <span style={{ color: "red", marginLeft: 5 }}>
              {numberFormat(item.price)}
            </span>
          </div>

          <span className="discount-prc">(-{discountPercent}%)</span>
        </>
      ) : (
        <>
          {item?.discount_formatted?.price}
          {numberFormat(item.price)}
        </>
      )}
    </div>
  );
};
