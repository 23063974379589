import {
  NEWPRESS_DETAIL_REQUEST,
  NEWPRESS_DETAIL_SUCCESS,
  NEWPRESS_DETAIL_FAILED,
} from "./constants";

const initialState = {
  fetching: false,
  newpressItems: []
};

function newpress(state: any = initialState, action: any) {
  switch (action.type) {
    case NEWPRESS_DETAIL_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case NEWPRESS_DETAIL_SUCCESS:
      return {
        ...state,
        newpressItems: action.payload,
        fetching: false,
      };
    case NEWPRESS_DETAIL_FAILED:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}

export default newpress;
