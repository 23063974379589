import { createMarkup } from "../lib/Functions";

type Props = {
  item: any;
  name: any;
};
const BlockHtml = ({ item, name }: Props) => {
  const { content } = item;

  return (
    <div
      style={{
        padding: 10,
      }}
    >
      <h2
        style={{
          fontSize: 14,
          textAlign: "center",
        }}
      >
        {name}
      </h2>
      <div
        style={{
          padding: 20,
        }}
        dangerouslySetInnerHTML={createMarkup(content)}
      />
    </div>
  );
};

export default BlockHtml;
