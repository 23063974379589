/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/indent */

/**
 * // useWindowDimension.ts
 * * This hook returns the viewport/window height and width
 */
import { DEVICE_MOBILE_WIDTH } from "../reducers/constants";
import { useEffect, useState } from "react";

type WindowDimentions = {
  width: number | undefined;
  height: number | undefined;
};

const getWindowDimensions = (): WindowDimentions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimentions>({
    width: undefined,
    height: undefined,
  });
  
  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount

  return windowDimensions;
};

export const isDeviceMobile = () => {
  const { width } = getWindowDimensions();
  return width && DEVICE_MOBILE_WIDTH < width;
};

export default getWindowDimensions;
