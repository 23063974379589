/* eslint-disable @typescript-eslint/default-param-last */

/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { NOTIFICATION_SHOW, NOTIFICATION_HIDE, NOTIFICATION_CLEAR } from "./constants";

type InitProps = {
  show: boolean;
  items: any;
};
const initialState: InitProps = {
  show: false,
  items: [],
};


 

function showHideActions(state: any = initialState, action: any) {
  switch (action.type) {
    case NOTIFICATION_SHOW:
      return {
        items: [
          ...state.items,
          {
            ...action.payload,
            id: action.payload.id || Date.now(),
          },
        ],
      };

    case NOTIFICATION_HIDE:
      return {
        items: state.items.filter(
          (notification: any) => notification.id !== action.payload.id
        ),
      };

      
  case NOTIFICATION_CLEAR:
      return {
        items: [],
      };
    default:
      return {
        ...state,
      };
  }
}

export default showHideActions;
