/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-case-declarations */

import {
  FETCH_MAIN_LAYOUTS_REQUEST,
  FETCH_MAIN_LAYOUTS_SUCCESS,
  FETCH_MAIN_LAYOUTS_FAIL,
  TOP_PANEL,
  CONTENT,
  HEADER,
  FOOTER,
} from "./constants";

type inprops = {
  fetching: boolean;
  top_header: any[];
  header: any[];
  footer: any[];
  web_logos: any[];
};
const initialState: inprops = {
  fetching: false,
  top_header: [],
  header: [],
  footer: [],
  web_logos: [],
};

function MainLayoutsFetch(state: any = initialState, action: any) {
  switch (action.type) {
    case FETCH_MAIN_LAYOUTS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_MAIN_LAYOUTS_SUCCESS:
      return {
        ...state,
        top_header: Object.values(action.payload.mainlayouts).filter(
          (item: any) => item?.position === TOP_PANEL
        ),
        content: Object.values(action.payload.mainlayouts).filter(
          (item: any) => item?.position === CONTENT
        ),
        header: Object.values(action.payload.mainlayouts).filter(
          (item: any) => item?.position === HEADER
        ),
        footer: Object.values(action.payload.mainlayouts).filter(
          (item: any) => item?.position === FOOTER
        ),
        web_logos: {
          theme: action?.payload.mainlayouts.web_logos?.theme.image?.image_path,
          favicon:
            action?.payload.mainlayouts.web_logos?.favicon?.image.image_path,
        },
        fetching: false,
      };

    case FETCH_MAIN_LAYOUTS_FAIL:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}

export default MainLayoutsFetch;
