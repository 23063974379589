/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { drawerContext } from "../../hooks/DrawerModal";
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { drawerContextHide } from "../../lib/Functions";
import { useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";
import { ImageItem, LinkItem, MainMenu } from "../../types/mainMenu";
import DownOutlined from "@ant-design/icons/DownOutlined";
import UpOutlined from "@ant-design/icons/UpOutlined";
import { Button, Image, Input } from "antd";
import Loader from "../../layouts/Loader";
import MainLogo from "../MainLogo";

interface ItemTemp {
  url: string;
  menuId: string;
  subItem: string;
  isOpen: boolean;
}

export default function MobileNavigation() {
  const navigate = useNavigate();
  const [searchvalue, setSearchvalue] = useState("");
  const [subItems, setsubItems] = useState<Record<string, ItemTemp>>({});

  const mainMenuState = useAppSelector(
    (state: TypeOfStates) => state.mainMenus
  );

  const onCloseMenu = () => {
    drawerContextHide();
    document.body.style.overflow = " show";
  };

  const handleNavigating = (url: string, menuId: number, subItem: boolean) => {
    navigate(url);
    drawerContext.hide();
    document.body.style.overflow = "show";
    setsubItems((prevState) => ({
      ...prevState,
      [menuId]: {
        url: url,
        menuId: menuId,
        subItem: subItem,
        isOpen: true,
      },
    }));
  };

  const handelNavigate = (url: string) => {
    navigate(url);
    drawerContext.hide();
    document.body.style.overflow = " show";
  };

  const categoryAds = useMemo(() => {
    const returnArray: any[] = mainMenuState?.main_menu
      .filter(
        (item) =>
          item.sub_item?.right && item.sub_item?.right?.image_items.length > 0
      )
      .map((item: MainMenu) => item?.sub_item?.right.image_items);
    return Object.values(returnArray);
  }, [mainMenuState?.main_menu]);

  if (mainMenuState.fetching) {
    return <Loader />;
  }

  if (!mainMenuState?.front_settings?.MOBILE_BANNER_KEY) {
    return <Loader />;
  }

  const mobileBannerKey: {
    image: string;
    url: string;
  } = mainMenuState?.front_settings?.MOBILE_BANNER_KEY;

  const mobileMenu = mainMenuState?.main_menu;

  return (
    <div className=" mobile-navigation ">
      <div className=" header ">
        <MainLogo />
        <Button
          className="close-button  "
          onClick={() => onCloseMenu()}
          type="text"
          icon={
            <CloseOutlined
              style={{
                color: "black",
              }}
              className="close-icon"
              aria-hidden="true"
            />
          }
        >
          <span className="sr-only"></span>
        </Button>
      </div>
      <ul>
        <li>
          <div
            style={{
              display: "flex",
              marginBottom: 20,
            }}
          >
            <Input
              placeholder={"Search"}
              onPressEnter={(s: any) => {
                if (s.target.value.length > 3) {
                  navigate(`/search?q=${s.target.value}`);
                  document.body.style.overflow = " show";
                }
              }}
              onMouseLeave={(s: any) => {
                if (s.target.value.length > 3) {
                  navigate(`/search?q=${s.target.value}`);
                  document.body.style.overflow = " show";
                }
              }}
              onChange={(s: any) => {
                if (s.target.value.length > 3) {
                  setSearchvalue(s.target.value);
                  document.body.style.overflow = " show";
                }
              }}
              style={{
                maxWidth: 180,
              }}
            />
            <Button
              onClick={() => {
                navigate(`/search?q=${searchvalue}`);
                document.body.style.overflow = " show";
              }}
              style={{
                marginLeft: 5,
                width: 40,
              }}
              icon={
                <SearchOutlined
                  style={{
                    lineHeight: "14px",
                  }}
                  type="text"
                />
              }
            ></Button>
          </div>
        </li>
        {mobileMenu?.map((item: MainMenu) => (
          <li className={`clslink-${item?.url}`} key={item.frontmenu_id}>
            <div className="item-name">
              <span
                onClick={() =>
                  handleNavigating(
                    item.url,
                    Number(item.frontmenu_id),
                    item.is_sub_item
                  )
                }
              >
                {item.frontmenu}
              </span>
              {item?.is_sub_item && (
                <Button
                  type={"text"}
                  className="up-down-button"
                  onClick={() =>
                    setsubItems((prevState) => ({
                      ...prevState,
                      [Number(item.frontmenu_id)]: {
                        url: item.url,
                        menuId: Number(item.frontmenu_id),
                        subItem: item.is_sub_item,
                        isOpen: !subItems[Number(item.frontmenu_id)]?.isOpen,
                      },
                    }))
                  }
                  icon={
                    subItems[Number(item.frontmenu_id)]?.isOpen ? (
                      <UpOutlined />
                    ) : (
                      <DownOutlined />
                    )
                  }
                />
              )}
            </div>

            {item?.is_sub_item &&
              subItems[Number(item.frontmenu_id)]?.isOpen &&
              item.sub_item?.left && (
                <ol>
                  {item.sub_item?.left?.link_items.map((item: LinkItem) => (
                    <li
                      key={item.mainmenu_subitem_id}
                      className={`url-${item.url}`}
                    >
                      <Link
                        to={
                          item?.url.length > 0
                            ? item?.url
                            : "/category/" + item.cid
                        }
                        className=""
                        onClick={() => {
                          document.body.style.overflow = "show ";
                          drawerContext.hide();
                        }}
                      >
                        {item?.mainmenu_subitem}
                      </Link>
                    </li>
                  ))}
                </ol>
              )}
          </li>
        ))}
      </ul>

      <div>
        <div className="items-right-mobile">
          {categoryAds &&
            categoryAds.length > 0 &&
            categoryAds.map((d: any) =>
              d.map((item: ImageItem) => (
                <div
                  key={item.mainmenu_subitem_id}
                  className={`item ${item?.sort_order}`}
                >
                  <Button
                    type={"text"}
                    onClick={() => handelNavigate(item?.url)}
                    className={`link item-url-${item?.url}`}
                  >
                    {item?.mainmenu_subitem}
                  </Button>
                  {item?.main_pair?.icon?.image_path && (
                    <img
                      src={item?.main_pair?.icon?.image_path}
                      alt={"choihainzan.com "}
                      onClick={() => handelNavigate(item?.url)}
                      style={{
                        width: "98%",
                        objectFit: "cover",
                        objectPosition: "center top",
                        borderRadius: 4,
                      }}
                    />
                  )}
                </div>
              ))
            )}
        </div>
      </div>

      <div>
        {mobileBannerKey?.image && (
          <Link to={mobileBannerKey?.url}>
            <Image
              src={mobileBannerKey?.image}
              height={400}
              style={{
                objectFit: "cover",
                objectPosition: "center top",
                marginTop: 20,
              }}
              alt={"choihainzan"}
              preview={false}
              onClick={() => onCloseMenu()}
            />
          </Link>
        )}
      </div>
    </div>
  );
}
