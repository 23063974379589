/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { STORE_KEY, STORE_LANGUAGE_CODE } from "../reducers/constants";
import DrawerModal from "../components/Modal/DrawerModal";
import { useEffect } from "react";
import { TypeOfStates } from "../types/stateTypes";
import { useAppDispatch, useAppSelector } from "../reducers/cart/hooks";
import LayoutFooter from "../components/LayoutFooter";
/// import Actions
import * as miniCartActions from "../reducers/actions/miniCartActions";
import * as mobileAction from "../reducers/actions/mobileAction";
import * as authActions from "../reducers/actions/authActions";
import * as cartActions from "../reducers/actions/cartActions";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import Loader from "./Loader";
import Notifications from "../components/Notifications";
import { Layout, Col, Row } from "antd";
import { initApp } from "../reducers/actions/appActions";
import * as mainMenusActions from "../reducers/actions/mainMenusActions";
import MainNavigation from "../components/NavigationBar/MainNavigation";
import HeaderRightItems from "../components/NavigationBar/HeaderRightItems";
import * as commonActions from "../reducers/actions/commonActions";
import MessengerChatComp from "../components/MessengerChatComp";
import MainLogo from "../components/MainLogo";
import SearchInput from "../components/SearchInput";
import BurgerMenu from "../components/NavigationBar/BurgerMenu";

function LayoutComponent({ children }: any) {
  const { Content } = Layout;
  const screenWidth = useWindowWidth();
  const dispatch = useAppDispatch();
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const langCode = getStates.settings.selectedLanguage.langCode;
  const isMobile = getStates.isMobile.status;

  useEffect(() => {
    if (localStorage.getItem(STORE_LANGUAGE_CODE)) {
    } else {
      localStorage.setItem(STORE_LANGUAGE_CODE, "mn");
    }
  }, []);

  useEffect(() => {
    dispatch(mainMenusActions.fetch());
  }, [dispatch, langCode]);

  useEffect(() => {
    dispatch(commonActions.mainNavShowHide(true));
    dispatch(commonActions.footerHideShow(true));
    dispatch(commonActions.topHeaderHide(true));
  }, [dispatch]);

  useEffect(() => {
    const localStorageData = localStorage.getItem(STORE_KEY);
    if (localStorageData && JSON.parse(localStorageData)?.auth?.token) {
      void dispatch(
        authActions.getUserData({
          token: JSON.parse(localStorageData)?.auth?.token,
          ttl: JSON.parse(localStorageData)?.auth?.ttl,
        })
      );
      void dispatch(authActions.fetchProfile());
      void dispatch(cartActions.fetchCart());
    } else {
      console.log("notihing ;");
    }
    initApp();
  }, [dispatch, langCode]);

  useEffect(() => {
    dispatch(mobileAction.ScreenMobile(screenWidth));
  }, [dispatch, screenWidth]);

  if (!Object.keys(getStates.mainlayouts?.header).length) {
    return <Loader />;
  }

  if (getStates.getTranslations.fetching) {
    return <Loader />;
  }

  return (
    <Layout>
      <Notifications />
      {getStates.common.topHeaderShow === true && (
        <Row
          onMouseLeave={() => {
            dispatch(miniCartActions.showOrHide(false));
          }}
          style={{
            background: "#FFF",
          }}
        >
          <Col span={isMobile ? 4 : 8}>
            <div
              className="left-items"
              style={
                isMobile
                  ? {
                      paddingLeft: 14,
                    }
                  : {}
              }
            >
              {isMobile && <BurgerMenu />}
              <SearchInput />
            </div>
          </Col>
          <Col
            span={isMobile ? 12 : 8}
            style={{
              marginTop: 18,
              marginBottom: 18,
              textAlign: isMobile ? "left" : "center",
            }}
          >
            <MainLogo />
          </Col>
          <Col
            span={8}
            style={{
              marginTop: 10,
              marginBottom: 10,
              textAlign: "right",
            }}
          >
            <HeaderRightItems getStates={getStates} />
          </Col>
        </Row>
      )}
      {!isMobile && <MainNavigation />}
      <DrawerModal />
      <Content>{children}</Content>
      {getStates.common.footerShow === true && <LayoutFooter />}

      <MessengerChatComp />
    </Layout>
  );
}

export default LayoutComponent;
