import _ from "lodash";
import { useState } from "react";

interface RenderPickerProps {
  item: {
    variants?: any;
    filter_id: string | number;
    filter?: string;
  };
  setSelectedFilters: (obj: any) => void;
  selectedFilters: [];
  openIDs: [];
  setOpenIDs: any;
  isMobile: boolean;
}

export function RenderPicker({
  item,
  setSelectedFilters,
  selectedFilters,
  openIDs,
  setOpenIDs,
  isMobile,
}: RenderPickerProps): any {
  const { filter_id }: any = item;
  const VISIBLE_COUNT: number = 100;
  let variants: any[] = [...Object.values(item?.variants)];
  const [isOpen, setIsOpen] = useState<boolean>(
    openIDs.some((item: any) => item === filter_id)
  );

  if (!isOpen) {
    variants = _.take(variants, VISIBLE_COUNT);
  }

  const toggleVariant = (filter: any, variant: any): any => {
    const selectedFilterItem: any = {
      ...filter,
      ...variant,
    };
    if (filter.filter_style === "checkbox" || filter.filter_style === "color") {
      if (Object.keys(selectedFilters).length > 0) {
        if (
          selectedFilters.some(
            (element: any) =>
              element?.variant_id === selectedFilterItem.variant_id &&
              selectedFilterItem.filter_id === element?.filter_id
          )
        ) {
          setSelectedFilters(
            selectedFilters.filter(
              (item: any) => !_.isEqual(item, selectedFilterItem)
            )
          );
          return;
        }
      }

      setSelectedFilters([...selectedFilters, selectedFilterItem]);
    }
  };

  return (
    <div key={filter_id} className="filter-items-container">
      <div className="filter-action-item">
        {_.sortBy(variants, ["position"]).map((variant: any): any => {
          const isSelected = selectedFilters.some(
            (selectedFilterItem: any) =>
              selectedFilterItem.variant_id === variant.variant_id &&
              item.filter_id === selectedFilterItem.filter_id
          );
          return (
            <div
              key={variant.variant_id}
              className={"variant"}
              onClick={() => toggleVariant(item, variant)}
            >
              <div
                style={
                  isSelected
                    ? {
                        fontWeight: "bold",
                      }
                    : {}
                }
              >
                {variant.variant}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
