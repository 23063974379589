import {
  NEWPRESS_DETAIL_REQUEST,
  NEWPRESS_DETAIL_SUCCESS,
  NEWPRESS_DETAIL_FAILED,
} from "../constants";
import Api from "../../services/configApi";

export function fetch(pressId = 0, press_type: string) {
  return async (dispatch: any) => {
    dispatch({
      type: NEWPRESS_DETAIL_REQUEST,
    });

    try {
      const response = await Api.get(
        `/PressJsonsRows/${pressId}?press_type=${press_type}`
      );
      dispatch({
        type: NEWPRESS_DETAIL_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: NEWPRESS_DETAIL_FAILED,
        error,
      });
    }
  };
}
