import _ from "lodash";
import {
  NewPressColumn,
  NewPressItem,
  NewPressList,
  NewPressListRow,
} from "../../types/blogs";
import { Row, Col, Image } from "antd";
import { createMarkup } from "../../lib/Functions";
import NewsSlider from "./NewsSlider";
import { useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";
import { Fragment } from "react";

const RenderNewPress: React.FC<NewPressList> = ({ items }) => {
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const getColNum = (col = 24) => col * 2;
  if (!items) {
    return <>loading ...</>;
  }

  return (
    <div>
      {items &&
        _.orderBy(items, ["sort_order"], ["asc"]).map(
          (item: NewPressListRow) => (
            <Row key={item.row_id} gutter={[0, 0]} style={{}}>
              {item?.columns &&
                Object.values(item?.columns).map(
                  (column: NewPressColumn, indes: number) => (
                    <Col
                      xl={getColNum(Number(column.col_width_number))}
                      md={getColNum(Number(column.col_width_number))}
                      xs={24}
                      sm={getColNum(Number(column.col_width_number))}
                      xxl={getColNum(Number(column.col_width_number))}
                      key={indes}
                    >
                      {column.items &&
                        Object.keys(column.items).length > 0 &&
                        _.orderBy(column.items, ["id"], ["asc"]).map(
                          (item: NewPressItem) => {
                            switch (item.item_type) {
                              case "slider":
                                return (
                                  <div
                                    key={item.id}
                                    className={`item_max_sSwidth_${item?.text_max_width}`}
                                    style={
                                      item.slider_style_type === "back_black"
                                        ? {
                                            background: "black",
                                            padding: 80,
                                          }
                                        : {}
                                    }
                                  >
                                    <NewsSlider
                                      slideNo={Number(item.slider_view_no)}
                                      slides={item?.slider_items}
                                      isMobile={getStates.isMobile.status}
                                    />
                                  </div>
                                );
                              case "text":
                                return (
                                  <div
                                    key={item.id}
                                    style={{
                                      padding:
                                        column.col_width_number &&
                                        Number(column.col_width_number) < 12
                                          ? 20
                                          : 4,
                                    }}
                                    dangerouslySetInnerHTML={createMarkup(
                                      item.value
                                    )}
                                  />
                                );
                              case "image":
                                return (
                                  <Image
                                    key={item.id}
                                    src={item?.value}
                                    alt=""
                                    style={{
                                      objectFit: "cover",
                                      objectPosition: "center center",
                                      width: "100%",
                                    }}
                                  />
                                );
                              case "youtube":
                                return (
                                  <div
                                    className="youtube-container"
                                    key={item.id}
                                  >
                                    <iframe
                                      src={`//www.youtube.com/embed/${item.value}?autoplay=1`}
                                      frameBorder="0"
                                      allowFullScreen
                                      className="youtube-video"
                                      title={item?.value}
                                      allow="autoplay"
                                    ></iframe>
                                  </div>
                                );
                              default:
                                return <Fragment key={item.id}></Fragment>;
                            }
                          }
                        )}
                    </Col>
                  )
                )}
            </Row>
          )
        )}
    </div>
  );
};

export default RenderNewPress;
