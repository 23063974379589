import * as React from "react";
import type { FC } from "react";
import { Card, Col, Image, Row } from "antd";
import { useNavigate } from "react-router-dom";
import i18n from "../../utils/i18n";
import { DescriptionItem } from "../CategoryPage/Product";
import getWindowDimensions from "../../hooks/useWindowDimension";

type Props = {
  items: any;
  screenWidth: number;
  pid: number;
  isMobile: boolean;
  fetching: boolean;
};

const SimilarProducts: FC<Props> = ({
  items,
  screenWidth,
  pid,
  isMobile,
  fetching,
}: Props) => {
  const navigate = useNavigate();
  const { Meta } = Card;
  const { width, height } = getWindowDimensions();

  const HeightCalc = () => {
    if (isMobile) {
      return 300;
    } else if (width && width < 980) {
      return 240;
    } else {
      return 300;
    }
  };

  if (fetching) {
    return <></>;
  }

  return (
    <Row>
      {items.length > 0 &&
        items.filter((item: any) => Number(item.product_id) !== Number(pid))
          .length > 0 && (
          <>
            <Col
              span={24}
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 14,
                marginTop: 24,
                marginBottom: 28,
                textTransform: "uppercase",
              }}
            >
              {i18n.t("same_style")}
            </Col>
            {items
              .filter((item: any) => Number(item.product_id) !== Number(pid))
              .map(
                (product: any, index: number) =>
                  product &&
                  index < 4 && (
                    <Col
                      key={product.product_id}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      onClick={() =>
                        navigate(
                          `/product/${product.product_id}?type=productId`
                        )
                      }
                      style={{
                        padding: 5,
                        height: "100%",
                        textAlign: "left",
                        cursor: "pointer",
                      }}
                    >
                      <Card
                        style={{
                          borderRadius: 0,
                          marginLeft: 12,
                          marginRight: 12,
                          border: "none",
                        }}
                        className="card-similar-products"
                        cover={
                          <img
                            alt="choihainzan.com"
                            src={
                              product?.main_pair?.detailed.image_path ||
                              "./assets/no-image-icon-4.png"
                            }
                            style={{
                              border: "none",
                              objectFit: "cover",
                              objectPosition: "center center",
                              height: HeightCalc(),
                              background: "white",
                              borderRadius: 0,
                            }}
                          />
                        }
                      >
                        <Meta
                          title={product.product}
                          description={<DescriptionItem item={product} />}
                        />
                      </Card>
                    </Col>
                  )
              )}
          </>
        )}
    </Row>
  );
};

export default SimilarProducts;
