import { Row, Col, Card, Button, Carousel } from "antd";
import getWindowDimensions from "../hooks/useWindowDimension";
import { Link, useNavigate } from "react-router-dom";
import CartIcon from "../components/CartIcon";
import i18n from "../utils/i18n";
import { carouselSettings } from "../components/BannerBlock";
import { TypeOfStates } from "../types/stateTypes";
import { useAppSelector } from "../reducers/cart/hooks";
import SingleProductBlocks from "./SingleProductBlocks";

interface Props {
  name?: string;
  grid_block_descriptions?: string;
  wrapper: any;
  items: any;
  onPress: (arg: string) => void;
  isMobile: boolean;
  grid_width: number;
}
const ProductBlock = ({
  name,
  wrapper,
  items,
  onPress,
  isMobile,
  grid_width,
  grid_block_descriptions,
}: Props) => {
  const { width } = getWindowDimensions();
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const first_key = Object.keys(items)[0];

  const carousel_Settings = carouselSettings(
    getStates,
    getStates.isMobile.status ? 2 : 3,
    1,
    false
  );
  const navigate = useNavigate();

  return (
    <div
      className={Object.keys(items).length > 0 ? " w-full" : "bg-white  w-full"}
    >
      <div
        className={
          Object.keys(items).length > 0
            ? "product-page-horizontal-layout "
            : "product-page-horizontal-layout bg-white "
        }
      >
        <div className="grid-block-products ">
          {/* {Object.keys(items).length > 1 &&
            grid_block_descriptions &&
            Object.keys(items).length > 0 && (
              <h2 className="grid-block-descriptions">
                {grid_block_descriptions}
              </h2>
            )} */}
 
          {Object.keys(items).length > 2 && (
            <Carousel {...carousel_Settings} className={"carousel-products"}>
              {Object.keys(items)?.map((item_key: string, index: number) => (
                <div key={item_key} className={"carusel-product"}>
                  {items[item_key]?.main_image && (
                    <>
                      <Card
                        hoverable={false}
                        style={{
                          borderRadius: 0,
                          border: "none",
                          margin: 0,
                          padding: 20,
                        }}
                        cover={
                          <img
                            alt="choihainzan.com"
                            src={
                              items[item_key]?.main_image ||
                              "./assets/no-image-icon-4.png"
                            }
                            style={{
                              borderRadius: 0,
                              border: "none",
                              objectFit: "cover",
                              objectPosition: "center center",
                              maxHeight: 400,
                            }}
                          />
                        }
                      >
                        <Button
                          className=""
                          onClick={() =>
                            navigate(`/product/${items[item_key]?.seo_name}`)
                          }
                          type={"text"}
                        >
                          {items[item_key]?.product}
                        </Button>
                      </Card>
                    </>
                  )}
                </div>
              ))}
            </Carousel>
          )}

          {items && width && (
            <Row>
              {Object.keys(items).length > 1 &&
                Object.keys(items).length < 4 &&
                Object.keys(items)?.map((item_key: string, index: number) => (
                  <Col
                    key={item_key}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f7e6db" : "#fef4e9",
                    }}
                  >
                    {items[item_key]?.main_image && (
                      <Link to={`/product/${items[item_key].seo_name}`}>
                        <Card
                          hoverable={false}
                          style={{
                            borderRadius: 0,
                            marginLeft: 12,
                            marginRight: 12,
                          }}
                          cover={
                            <img
                              alt="choihainzan.com"
                              src={
                                items[item_key]?.main_image ||
                                "./assets/no-image-icon-4.png"
                              }
                              style={{
                                borderRadius: 0,
                                border: "none",
                                objectFit: "cover",
                                objectPosition: "center center",
                                maxHeight: 400,
                              }}
                            />
                          }
                        ></Card>
                        <Button className="grid-block-products-button">
                          {items[item_key]?.product}
                        </Button>
                      </Link>
                    )}
                  </Col>
                ))}

              {Object.keys(items).length === 1 && (
                <SingleProductBlocks
                  item={items[first_key]}
                  isMobile={isMobile}
                  grid_width={0}
                />
              )}
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductBlock;
