/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */

import {
  FETCH_CART_PRODUCTS_REQUEST,
  FETCH_CART_PRODUCTS_SUCCESS,
  FETCH_CART_PRODUCTS_FAIL,
} from './constants';

const initialState: any = {
  cartproducts: [],
  fetching: false,
};
function CProducts(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_CART_PRODUCTS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_CART_PRODUCTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        ...action.payload,
      };
    case FETCH_CART_PRODUCTS_FAIL:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}

export default CProducts;
