import React from "react";
import { useRoutes } from "react-router-dom";
import CheckoutLayoutScreen from "./Checkout/CheckoutLayout";
import NoMatchScreen from "./NoMatchScreen";
import Loader from "./Loader";
import ProductScreen from "./ProductScreen";
import CategoryScreeen from "./Category";
import IndexScreen from "./IndexScreen";
import CampaignScreen from "./Campaign";
import PressDetailScreen from "./Press/DetailScreen";
import PageScreen from "./PageScreen";
import AccountOrdersScreen from "./CustomerAccount/OrderList.";
import PointsDashboard from "./CustomerAccount/PointsDashboard";
import VerifyScreen from "./CustomerAccount/VerifyScreen";

const WishListScreen = React.lazy(() => import("./WishListScreen"));
const SignupScreen = React.lazy(() => import("./CustomerAccount/SignupScreen"));
const PressScreeen = React.lazy(() => import("./Press"));
const SearchScreen = React.lazy(() => import("./Search"));
const BoutiquesScreen = React.lazy(() => import("./Boutiques/index"));

const ProfileScreen = React.lazy(
  () => import("./CustomerAccount/ProfileScreen")
);

function AppRoutes() {
  return useRoutes([
    {
      path: "/",
      children: [
        {
          path: "/",
          element: (
            <React.Suspense fallback={<Loader />}>
              <IndexScreen />
            </React.Suspense>
          ),
        },
        {
          path: "page/:pageSlug",
          element: (
            <React.Suspense fallback={<Loader />}>
              <PageScreen />
            </React.Suspense>
          ),
        },
        {
          path: "category/:categoryId",
          element: (
            <React.Suspense fallback={<Loader />}>
              <CategoryScreeen />
            </React.Suspense>
          ),
        },
        {
          path: "search",
          element: (
            <React.Suspense fallback={<Loader />}>
              <SearchScreen />
            </React.Suspense>
          ),
        },
        {
          path: "product/:productslug",
          element: (
            <React.Suspense fallback={<Loader />}>
              <ProductScreen />
            </React.Suspense>
          ),
        },
        {
          path: "/account/profile",
          element: (
            <React.Suspense fallback={<Loader />}>
              <ProfileScreen />
            </React.Suspense>
          ),
        },
        {
          path: "/account/verify",
          element: (
            <React.Suspense fallback={<Loader />}>
              <VerifyScreen />
            </React.Suspense>
          ),
        },
        {
          path: "/account/signup",
          element: (
            <React.Suspense fallback={<Loader />}>
              <SignupScreen />
            </React.Suspense>
          ),
        },
        {
          path: "/account/orders",
          element: (
            <React.Suspense fallback={<Loader />}>
              <AccountOrdersScreen />
            </React.Suspense>
          ),
        },
        {
          path: "/account/points-dashboard",
          element: (
            <React.Suspense fallback={<Loader />}>
              <PointsDashboard />
            </React.Suspense>
          ),
        },

        {
          path: "/account/wishlist",
          element: (
            <React.Suspense fallback={<Loader />}>
              <WishListScreen />
            </React.Suspense>
          ),
        },
        {
          path: "/press/",
          element: (
            <React.Suspense fallback={<Loader />}>
              <PressScreeen />
            </React.Suspense>
          ),
        },
        {
          path: "/press/:pressId",
          element: (
            <React.Suspense fallback={<Loader />}>
              <PressDetailScreen />
            </React.Suspense>
          ),
        },
        {
          path: "/checkout/:checkoutAction",
          element: (
            <React.Suspense fallback={<Loader />}>
              <CheckoutLayoutScreen />
            </React.Suspense>
          ),
        },
        {
          path: "/campaign/:campaignId",
          element: (
            <React.Suspense fallback={<Loader />}>
              <CampaignScreen />
            </React.Suspense>
          ),
        },
        {
          path: "/boutiques",
          element: (
            <React.Suspense fallback={<Loader />}>
              <BoutiquesScreen />
            </React.Suspense>
          ),
        },
        {
          path: "*",
          element: <NoMatchScreen />,
        },
      ],
    },
  ]);
}

export default AppRoutes;
