/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-sequences */
/* eslint-disable no-prototype-builtins */
import i18n from './i18n';
import {
  PRODUCT_STATUS_ACTIVE,
  PRODUCT_STATUS_HIDDEN,
  PRODUCT_STATUS_DISABLED,
  PRODUCT_STATUS_REQUIRES_APPROVAL,
  PRODUCT_STATUS_DISAPPROVED,
} from '../reducers/constants/index';
import {lodashGet, lodashHas, lodashValues} from './lodash';

// Strip tags
export const stripTags = (str: string) => {
  if (!str) {
    return str;
  }

  return str
    .replace(/<br[^>]*>/gi, '\n')
    .replace(/(<([^>]+)>)/gi, '')
    .trimLeft();
};

export const formatPrice = (price: number) => {
  if (price) {
    return String(price).replace(/&nbsp;/, '');
  }
  return price;
};

export const getImagePath = (data: any, size: any = '500x500') => {
  if (lodashHas(data, `main_pair.icons.${size}.image_path`)) {
    return lodashGet(data, `main_pair.icons.${size}.image_path`);
  }

  if (lodashHas(data, 'main_pair.detailed.image_path')) {
    return lodashGet(data, 'main_pair.detailed.image_path');
  }

  if (lodashHas(data, 'main_pair.icon.image_path')) {
    return lodashGet(data, 'main_pair.icon.image_path');
  }

  return null;
};

export const getProductImagesPaths = (
  data: any = {},
  size: any = '1000x1000',
) => {
  const images = [];
  if (lodashHas(data, `main_pair.icons.${size}.image_path`)) {
    images.push(lodashGet(data, `main_pair.icons.${size}.image_path`));
  } else if (lodashHas(data, 'main_pair.detailed.image_path')) {
    images.push(lodashGet(data, 'main_pair.detailed.image_path'));
  }

  lodashValues(data.image_pairs).forEach((img: any) => {
    if (lodashHas(img, `icons.${size}.image_path`)) {
      images.push(lodashGet(img, `icons.${size}.image_path`));
    } else if (lodashHas(img, 'detailed.image_path')) {
      images.push(lodashGet(img, 'detailed.image_path'));
    } else if (lodashHas(img, 'icon.image_path')) {
      images.push(lodashGet(img, 'icon.image_path'));
    }
  });

  return images;
};

export const toArray = (obj: Record<string, any>) =>
  obj && Object.keys(obj).map(k => obj[k]);

export const filterObject = (
  obj: Record<string, any>,
  predicate: {
    (step: any): boolean;
    (p: any): boolean;
    (variant: any): any;
    (featuresVariant: any): boolean;
    (feature: any): boolean;
    (arg0: any): unknown;
  },
) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res: any, key: any): any => ((res[key] = obj[key]), res), {});

export function getStates(code: string) {
  const result: any = {};
  return result;
}

export function isPriceIncludesTax(data: any) {
  return 0;
}

export function objectToQuerystring(obj: any) {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }

  return str.join('&');
}

export function parseQueryString(query: string) {
  const obj: any = {};
  const qPos: any = query.indexOf('?');
  const tokens: any = query.substr(qPos + 1).split('&');

  let i: any = tokens.length - 1;

  if (qPos !== -1 || query.includes('=')) {
    for (; i >= 0; i--) {
      const s = tokens[i].split('=');
      obj[unescape(s[0])] = s.hasOwnProperty(1) ? unescape(s[1]) : null;
    }
  }

  return obj;
}

export function getProductStatus(status: any) {
  switch (status) {
    case PRODUCT_STATUS_ACTIVE:
      return {
        text: i18n.t('Active'),
        style: {color: '#97cf4d'},
      };

    case PRODUCT_STATUS_HIDDEN:
      return {
        text: i18n.t('Hidden'),
        style: {color: '#000000'},
      };

    case PRODUCT_STATUS_DISABLED:
      return {
        text: i18n.t('Disabled'),
        style: {color: '#ff0000'},
      };

    case PRODUCT_STATUS_REQUIRES_APPROVAL:
      return {
        text: i18n.t('Requires approval'),
        style: {color: '#ff9c12'},
      };

    case PRODUCT_STATUS_DISAPPROVED:
      return {
        text: i18n.t('Disapproved'),
        style: {color: '#ff2b2b'},
      };

    default:
      return {
        text: i18n.t(''),
        style: {color: '#000000'},
      };
  }
}

export const getPaymentId = (res: any) => {
  Object.keys(res.data.payments).forEach(key => {
    res.data.payments[key].payment_id = key;
  });
  return res;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
