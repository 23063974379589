import {
  SHIPMENT_LOCATIONS_REQUEST,
  SHIPMENT_LOCATIONS_SUCCESS,
  SHIPMENT_LOCATIONS_FAILED,
} from "../constants";
import Api from "../../services/configApi";

export function fetch() {
  return async (dispatch: any) => {
    dispatch({
      type: SHIPMENT_LOCATIONS_REQUEST,
    });

    try {
      const response = await Api.get("/ShipmentLocation");
      dispatch({
        type: SHIPMENT_LOCATIONS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: SHIPMENT_LOCATIONS_FAILED,
        error,
      });
    }
  };
}
