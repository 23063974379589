import { Button } from "antd";
import { useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";

interface PropsY {
  variation_features_variants?: any[];
  setSelectedProductId: (pid: number) => void;
  setSelectedProductAmount: (pid: number) => void;
  selectedProductId: number;
}

const VariationFeaturesVariants: React.FC<PropsY> = ({
  variation_features_variants,
  setSelectedProductId,
  setSelectedProductAmount,
  selectedProductId,
}) => {
  const front_settings = useAppSelector(
    (state: TypeOfStates) => state.mainMenus.front_settings
  );
  console.log("variation_features_variants", variation_features_variants);
  if (!front_settings?.SIZE_GUIDE_PRODUCT) {
    return <></>;
  }
  if (!variation_features_variants) {
    return <></>;
  }

  return (
    <>
      {variation_features_variants &&
        Object.keys(variation_features_variants).length > 0 &&
        Object.values(variation_features_variants).map(
          (feature: any, index: number) => (
            <div key={index} className="features_box">
              <div className="feature_description">{feature?.description}</div>
              <div className="selection">
                {feature?.variants && (
                  <FeatureVariantsComp
                    fvariants={feature?.variants}
                    setSelectedProductId={setSelectedProductId}
                    setSelectedProductAmount={setSelectedProductAmount}
                    selectedProductId={selectedProductId}
                  />
                )}
              </div>
            </div>
          )
        )}
    </>
  );
};

export default VariationFeaturesVariants;

interface Props {
  fvariants: any;
  setSelectedProductId: (arg: number) => void;
  setSelectedProductAmount: (arg: number) => void;
  selectedProductId: number;
}
export function FeatureVariantsComp({
  fvariants,
  setSelectedProductId,
  setSelectedProductAmount,
  selectedProductId,
}: Props) {
  const fvariantsOrderd = () => {
    if (fvariants && Object.keys(fvariants).length > 0) {
      return Object.values(fvariants).sort(
        (a: any, b: any): any =>
          Number(a.variant_position) > Number(b.variant_position)
      );
    }
    return [];
  };

  if (!fvariants) {
    return <></>;
  }

  return (
    <>
      {fvariantsOrderd().map((variants: any) => (
        <Button
          size="small"
          key={variants.variant_id}
          className={`
          btn amount amount_no_${variants.amount} 
          product_id_${variants.product_id} 
        `}
          onClick={() => {
            setSelectedProductId(variants.product_id);
            setSelectedProductAmount(variants.amount);
          }}
          disabled={Number(variants.amount) === 0}
          type={
            Number(selectedProductId) === Number(variants.product_id)
              ? "primary"
              : "default"
          }
        >
          {variants?.variant}
        </Button>
      ))}
    </>
  );
}

export function FeatureVariantsSingleSelectedComp({
  fvariants,
  setSelectedProductId,
  setSelectedProductAmount,
  selectedProductId,
}: Props) {
  const fvariantsOrderd = () => {
    if (fvariants && Object.keys(fvariants).length > 0) {
      return Object.values(fvariants).sort(
        (a: any, b: any): any =>
          Number(a.variant_position) > Number(b.variant_position)
      );
    }
    return [];
  };

  if (!fvariants) {
    return <></>;
  }

  return (
    <>
      {fvariantsOrderd().map((variants: any) => (
        <Button
          size="small"
          key={variants.variant_id}
          className={`
          btn amount amount_no_${variants.amount} 
          product_id_${variants.product_id} 
        `}
          onClick={() => {
            setSelectedProductId(variants.product_id);
            setSelectedProductAmount(variants.amount);
          }}
          disabled={Number(variants.amount) === 0}
          type={"primary"}
        >
          {variants?.variant}
        </Button>
      ))}
    </>
  );
}
