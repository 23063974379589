/* eslint-disable camelcase */
import {
    FETCH_ONE_PRODUCT_REQUEST,
    FETCH_ONE_PRODUCT_FAIL,
    FETCH_ONE_PRODUCT_SUCCESS,
  } from './constants';
  
  const initialState = {
    fetching: true,
  };
  
  function ProductDetail(state = initialState, action: any ) {
    switch (action.type) {
      case FETCH_ONE_PRODUCT_REQUEST:
        return {
          ...state,
          fetching: true,
        };
  
      case FETCH_ONE_PRODUCT_SUCCESS:  
        return {
          ...state,
          fetching: false,
          ...action.payload,
        };
      case FETCH_ONE_PRODUCT_FAIL:
        return {
          ...state,
          fetching: false,          
        };

      default:
        return state;
    }
  }
  
  export default ProductDetail;
  