/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from "react";
import * as authActions from "../../reducers/actions/authActions";
import {
  HumanSizeTable,
  PointsInfo,
  SectionTitle,
} from "../../components/ProfileFields";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "../Loader";
import { drawerContext } from "../../hooks/DrawerModal";
import LoginInformationForm from "../../components/ProfileForms/LoginInformationForm";
import { TypeOfStates } from "../../types/stateTypes";
import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";
import i18n from "../../utils/i18n";
import * as commonActions from "../../reducers/actions/commonActions";
import PointsRedBox from "../../components/ProfileForms/PointsRedBox";
import BodyMeasurementForm from "../../components/ProfileForms/BodyMeasurementForm";
import { Layout } from "../../layouts";
import {
  Button,
  Input,
  Radio,
  Form as AntForm,
  Row,
  Col,
  Image,
  Spin,
} from "antd";
import { useForm, Controller } from "react-hook-form";
import { drawerContextHide } from "../../lib/Functions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as AuthActions from "../../reducers/actions/authActions";

function VerifyScreen() {
  const dispatch = useAppDispatch();
  const getStateSelect = useAppSelector((state: TypeOfStates) => state);

  const isMobile = getStateSelect.isMobile.status;
  const userProfile = getStateSelect.profile;
  const verifyFetching = getStateSelect.auth.verifyFetching;

  let navigate = useNavigate();
  let location = useLocation();
  const ref1: any = useRef();
  const defaultValues = {
    verify_code: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    dispatch(authActions.getVerifyCode())
  }, [dispatch])
  
  const onSubmit = (data: any) => {
    if (data) {
      dispatch(
        authActions.verifyProfile(userProfile.user_id, {
          email: userProfile.email,
          ...data,
        })
      )
        .then(() => {
          drawerContextHide();
        })
        .catch((err: any) => console.log(err));
    }
  };


  if (!userProfile.email) {
    return <Loader />;
  }
  return (
    <Layout>
      <div className={"w-full py-10 bg-white "}>
        <div className="max-width-layout profile-section">
          <Spin spinning={verifyFetching}>
            <div className="verify-form">
              <h2>И-Мэйл хаяг баталгаажуулах</h2>
              <p>
                Таны <b>{userProfile?.email} </b> хаягруу и-мэйл баталгаажуулах
                5 оронтой тоог илгээсэн Та цахим хаягаа шалган ирсэн кодыг доорх
                талбарт бөглөн баталгаажуулах товчыг дарна уу
              </p>

              <AntForm layout={"vertical"} onFinish={handleSubmit(onSubmit)}>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <AntForm.Item
                      hasFeedback
                      validateStatus={errors.verify_code && "error"}
                    >
                      <Input
                        ref={ref1}
                        type="text"
                        placeholder=""
                        value={value}
                        className="profile-input"
                        name={name}
                        onChange={(e) => {
                          if (e.target.value.length < 6) {
                            onChange(e.target.value);
                          } else {
                            console.log("e.target.value");
                          }
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </AntForm.Item>
                  )}
                  name={"verify_code"}
                  control={control}
                  rules={{ required: true }}
                />

                <Button
                  type={"primary"}
                  className="save-button"
                  style={{
                    width: "100%",
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Баталгаажуулах
                </Button>

                <Button
                  type={"text"}
                  className="resend-button"
                  onClick={() => dispatch(AuthActions.getVerifyCode())}
                >
                  Кодыг дахин авах
                </Button>
              </AntForm>
            </div>
          </Spin>
        </div>
      </div>
    </Layout>
  );
}

export default VerifyScreen;
