import { Badge } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { drawerContext } from "../../hooks/DrawerModal";
import LoginLayout from "../Auth/LoginLayout";
import CartIcon from "../CartIcon";
import { UserIcon, WishlistIcon } from "../Icons";
import LanguageSelectionBtn from "../LanguageSelectionBtn";

type Props = {
  getStates: any;
};
const HeaderRightItems = ({ getStates }: Props) => {
  const navigate = useNavigate();
  const isMobile = getStates.isMobile.status;

  const handleNavigate = () => {
    if (getStates.auth.logged) {
      navigate("/checkout/cart");
    } else {
      document.body.style.overflow = "hidden";
      drawerContext.show(
        <LoginLayout />,
        "",
        "right",
        false,
        undefined,
        undefined,
        isMobile ? 334 : 440
      );
    }
  };
  return (
    <div
      className="right-items"
      style={
        isMobile
          ? {
              paddingRight: 12,
            }
          : {}
      }
    >
      {
        <>
          {!getStates.isMobile.status && <LanguageSelectionBtn />}
          {getStates.auth.logged === true ? (
            <Link
              to={"/account/profile"}
              className=""
              aria-label={""}
              style={{
                height: 26,
              }}
            >
              <UserIcon
                style={{
                  height: 26,
                }}
              />
            </Link>
          ) : (
            <Link
              to={"#"}
              className=""
              aria-label={""}
              onClick={() => {
                document.body.style.overflow = "hidden";
                drawerContext.show(
                  <LoginLayout />,
                  "",
                  "right",
                  false,
                  undefined,
                  undefined,
                  isMobile ? 334 : 440
                );
              }}
              style={{
                height: 26,
              }}
            >
              <UserIcon
                style={{
                  height: 26,
                }}
              />
            </Link>
          )}
        </>
      }
      <Link
        to={"/account/wishlist"}
        style={{
          marginRight: 4,
          height: 26,
        }}
      >
        <WishlistIcon
          style={{
            height: 26,
          }}
        />
      </Link>
      <div
        onClick={() => {
          handleNavigate();
        }}
        style={{
          cursor: "pointer",
          height: 26,
        }}
      >
        <Badge
          count={getStates.cart?.cartdata?.amount}
          style={{
            fontSize: 12,
            lineHeight: "18px",
          }}
        >
          <CartIcon
            style={{
              height: 26,
            }}
          />
        </Badge>
      </div>
    </div>
  );
};

export default HeaderRightItems;
