import { POINTS_REQUEST, POINTS_SUCCESS, POINTS_FAILED } from "../constants/";

import Api from "../../services/configApi";

export function fetch(page = 1) {
  return async (dispatch: any) => {
    dispatch({
      type: POINTS_REQUEST,
    });
    try {
      const params = {
        page,
      };
      const response = await Api.get("/Points", {
        params,
      });
      dispatch({
        type: POINTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: POINTS_FAILED,
        error,
      });
    }
  };
}
