import {
  MAINMENU_REQUEST,
  MAINMENU_SUCCESS,
  MAINMENU_FAILED,
} from "../constants";
import Api from "../../services/configApi";

export function fetch() {
  return async (dispatch: any) => {
    dispatch({
      type: MAINMENU_REQUEST,
    });

    try {
      const response = await Api.get(`/MainMenus`, {
        params: {
          footer_menu_id: 7,
        },
      });
      dispatch({
        type: MAINMENU_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: MAINMENU_FAILED,
        error,
      });
    }
  };
}
