import * as React from "react";
const CartIcon = ({ className = "", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 578.000000 721.000000"
    preserveAspectRatio="xMidYMid meet"
    width="14pt"
  >
    <g
      transform="translate(0.000000,721.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M2679 7195 c-678 -97 -1214 -618 -1329 -1292 -19 -106 -20 -165 -20
-784 l0 -669 -57 -29 c-160 -81 -253 -232 -253 -411 0 -138 46 -245 144 -338
212 -200 545 -158 706 90 53 81 74 165 68 275 -3 77 -9 100 -41 166 -45 96
-116 169 -209 217 l-68 35 0 636 c0 699 2 727 61 904 70 208 175 371 340 529
163 155 343 256 553 310 673 175 1353 -186 1570 -834 64 -191 66 -221 66 -942
l0 -648 -38 -19 c-66 -34 -137 -97 -177 -156 -166 -251 -59 -586 224 -696 79
-31 232 -32 313 -2 228 85 354 324 292 558 -35 137 -156 270 -286 316 l-37 13
-4 715 c-3 692 -4 719 -25 812 -111 489 -409 876 -842 1094 -116 58 -266 110
-397 136 -139 27 -414 35 -554 14z m-1097 -3042 c17 -10 44 -35 61 -55 79 -99
22 -254 -106 -289 -190 -51 -336 159 -209 301 20 22 55 48 77 56 50 18 135 12
177 -13z m2885 2 c70 -35 106 -88 111 -165 14 -195 -225 -296 -352 -149 -38
45 -60 116 -50 163 31 145 164 214 291 151z"
      />
      <path
        d="M0 2943 c0 -2461 -3 -2320 57 -2463 75 -181 208 -321 388 -406 169
-80 -30 -74 2528 -74 2256 0 2285 0 2363 20 190 49 349 201 417 400 l22 65 3
2358 2 2357 -470 0 -470 0 0 -145 0 -145 315 0 315 0 -2 -2177 -3 -2178 -28
-57 c-36 -73 -119 -150 -193 -177 l-55 -21 -2267 2 -2267 3 -78 37 c-150 72
-245 205 -267 373 -7 54 -10 750 -8 2135 l3 2055 358 3 357 2 0 145 0 145
-510 0 -510 0 0 -2257z"
      />
      <path d="M1940 5055 l0 -145 990 0 990 0 0 145 0 145 -990 0 -990 0 0 -145z" />
    </g>
  </svg>
);

export default CartIcon;
