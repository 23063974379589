/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable react/prop-types */

import type { ButtonHTMLAttributes, JSXElementConstructor } from "react";
import React from "react";
import { Button } from "antd";

export type ButtonProps = {
  className?: string;
  active?: boolean;
  Component?: string | JSXElementConstructor<any>;
  width?: string | number;
  loading?: boolean;
  disabled?: boolean;
  handleAddToWishList: () => void;
  isMobile: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const CartButton: React.FC<ButtonProps> = (props) => {
  const {
    className,
    children,
    active,
    width,
    loading = false,
    disabled = false,
    handleAddToWishList,
    isMobile,
    ...rest
  }: any = props;
  return (
    <div className={isMobile ? " cart-buttons-mobile " : "cart-buttons "}>
      <Button
        className={"add-cart-button"}
        {...rest}
        disabled={loading || disabled}
        loading={loading}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="icon"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
          />
        </svg>

        <span className="text ">Add to shopping bag</span>
      </Button>

      <Button
        className="add-wishlist-button"
        onClick={() => handleAddToWishList()}
        loading={loading}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon"
          fill={"none"}
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
      </Button>
    </div>
  );
};
export default CartButton;
