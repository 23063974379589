import * as React from "react";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      Thanks to your Choi Hainzan account, you will be able to:
      <ul>
        <li>Access your shopping cart</li>
        <li>Save your billing and delivery information to order faster</li>
        <li>Manage your address book</li>
        <li>Access all your orders and download the related invoices</li>
        <li>Manage your newsletter subscription</li>
        <li>Update your personal data</li>
      </ul>
    </div>
  );
}

export default PrivacyPolicy;
