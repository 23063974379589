/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from "react";
import * as authActions from "../../reducers/actions/authActions";
import { SectionTitle } from "../../components/ProfileFields";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import { TypeOfStates } from "../../types/stateTypes";
import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";
import { Row, Col } from "antd";
import i18n from "../../utils/i18n";
import * as commonActions from "../../reducers/actions/commonActions";
import * as pointsActions from "../../reducers/actions/pointsActions";
import PointsRedBox from "../../components/ProfileForms/PointsRedBox";
import Moment from "react-moment";
import BackCircleBtn from "../../components/BackCircleBtn";
import { createMarkup } from "../../lib/Functions";
import VerifyScreen from "./VerifyScreen";

function PointsDashboard() {
  Moment.globalFormat = "YYYY.MM.DD";

  const dispatch = useAppDispatch();
  const getStateSelect = useAppSelector((state: TypeOfStates) => state);
  const [totalPoint, setTotalPoint] = useState(0);
  const [totalRw, setTotalRw] = useState(0);

  const isMobile = getStateSelect.isMobile.status;
  const userProfile = getStateSelect.profile;
  const front_settings = getStateSelect.mainMenus.front_settings;

  let navigate = useNavigate();

  useEffect(() => {
    if (!userProfile.email || getStateSelect.cart.fetching === true) {
      dispatch(authActions.fetchProfile());
    }
  }, [dispatch, getStateSelect.cart.fetching, userProfile]);

  useEffect(() => {
    if (!front_settings?.PURCHASE_AND_POINT_MESSAGE) {
      navigate("/account/profile");
    } else {
      dispatch(pointsActions.fetch());
    }
  }, [dispatch, front_settings, navigate]);

  useEffect(() => {
    if (
      getStateSelect?.points?.points &&
      getStateSelect?.points?.points.length > 0
    ) {
      let tmpTotal = 0;
      let tmpTotalRw = 0;

      Object.values(getStateSelect?.points.points).forEach((point: any) => {
        if (point?.action === "A") {
          tmpTotal += Number(point.amount);
          tmpTotalRw += 1;
        }
      });
      setTotalPoint(tmpTotal);
      setTotalRw(tmpTotalRw);
    }
  }, [getStateSelect?.points.points]);

  if (!userProfile.email) {
    return <Loader />;
  }

  if (userProfile.verify_status === "N") {
    return <VerifyScreen />;
  }
  
  return (
    <div className={"w-full py-10 bg-white "}>
      <div className="max-width-layout profile-section">
        <div
          className="username-section"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h5
            style={{
              paddingLeft: 20,
              fontSize: 13,
            }}
          >
            {i18n.t("hello_hi")}: {userProfile?.lastname}{" "}
            {userProfile?.firstname}
          </h5>
          <BackCircleBtn
            onClick={() => {
              navigate("/account/profile");
            }}
          />
        </div>

        <Row>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              maxWidth: 520,
            }}
          >
            <PointsRedBox profile={userProfile} isMobile={isMobile} />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={9}
            lg={9}
            xl={9}
            style={{
              paddingLeft: isMobile === true ? 20 : 90,
            }}
          >
            <SectionTitle
              title={i18n.t("points")}
              isUrlText={false}
              marginTop={0}
            />
            <div style={{ fontSize: 11 }}>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  front_settings.PURCHASE_AND_POINT_MESSAGE.description
                )}
              />
            </div>
          </Col>
        </Row>

        <h2
          style={{
            fontSize: 13,
            textTransform: "uppercase",
            padding: 20,
            margin: 0,
            paddingBottom: 1,
            marginBottom: 1,
          }}
        >
          {i18n.t("points_dashboard")}
        </h2>
        <div className="points-list-table-container">
          <table
            className="point-list-table"
            border={0}
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th className="th-tbl">{i18n.t("date_ognoo")}</th>
                <th className="th-tbl"></th>
                <th className="th-tbl">{i18n.t("pointsBigp")}</th>
              </tr>
            </thead>
            <tbody>
              {getStateSelect?.points?.points &&
                getStateSelect?.points?.points.length > 0 &&
                Object.values(getStateSelect?.points.points).map(
                  (point: any, index: number) =>
                    point?.action !== "O" && (
                      <tr
                        key={point.change_id}
                        style={{
                          background: index % 2 === 0 ? "#F5F5F5" : "#FFF",
                        }}
                      >
                        <td
                          style={{
                            width: 90,
                          }}
                        >
                          <Moment unix>{point?.timestamp}</Moment>
                        </td>

                        <td
                          style={{
                            borderRightColor: "#bfbfbf",
                            borderRightWidth: 0.5,
                            borderRightStyle: "solid",
                            borderLeftColor: "#bfbfbf",
                            borderLeftWidth: 0.5,
                            borderLeftStyle: "solid",
                          }}
                        >
                          {point?.reasons === "online_purchase"
                            ? i18n.t("online_purchase")
                            : point?.reasons}
                        </td>
                        <td
                          style={{
                            width: 110,
                            textAlign: "right",
                          }}
                        >
                          <span>
                            {point?.amount} {i18n.t("pts")}
                          </span>
                        </td>
                      </tr>
                    )
                )}
              <EmptyRw totalRw={totalRw + 1} />
              <EmptyRw totalPoint={totalPoint} totalRw={totalRw + 2} />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PointsDashboard;

type Type = {
  totalPoint?: number;
  totalRw?: number;
};
export function EmptyRw({ totalPoint, totalRw }: Type) {
  return (
    <tr
      style={{
        background: totalRw && totalRw % 2 === 0 ? "#F5F5F5" : "#FFFFFF",
      }}
    >
      <td
        style={{
          width: 90,
        }}
      ></td>

      <td
        style={{
          borderRightColor: "#bfbfbf",
          borderRightWidth: 0.5,
          borderRightStyle: "solid",
          borderLeftColor: "#bfbfbf",
          borderLeftWidth: 0.5,
          borderLeftStyle: "solid",
        }}
      ></td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        {totalPoint && (
          <>
            {totalPoint} {i18n.t("pts")}
          </>
        )}
      </td>
    </tr>
  );
}
