/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React from "react";
import type { GridType } from "../types/grids";
import BannerBlock from "../components/BannerBlock";
import CategoryBlock from "../components/CategoryBlock/HomePageBlocks";
import {
  BLOCK_BANNERS,
  BLOCK_CATEGORIES,
  BLOCK_HTML_CONTENT,
  BLOCK_PRODUCTS,
} from "../reducers/constants";
import { toArray } from "../utils";
import { Row, Col } from "antd";
import ProductBlock from "../ProductBlock";
import BlockHtml from "../BlockHtml";
type Props = {
  grids: GridType[];
  isMobile: boolean;
};

const RenderGrids: React.FC<Props> = ({ grids, isMobile }) => {
  const renderGridRecursive = (
    grid: GridType,
    index: number,
    grid_width: number
  ): any => {
    if (!grid.sub_grid && grid.sub_grid_items === null) {
      const items = toArray(grid?.grid_items);
      switch (grid.block_type) {
        case BLOCK_BANNERS:
          return (
            <div className={"block-items"}>
              <BannerBlock
                name={grid.name}
                wrapper={grid.wrapper}
                items={items}
                block_properties={grid?.block_properties?.template}
                onPress={(banner: any) => {
                  console.log(banner.url, {
                    ...banner,
                    title: banner.banner,
                  });
                }}
                key={index}
              />
            </div>
          );

        case BLOCK_PRODUCTS:
          return (
            <ProductBlock
              name={grid.name}
              wrapper={grid.wrapper}
              items={items[0]}
              onPress={(product: any) => console.log(product)}
              key={index}
              isMobile={isMobile}
              grid_width={grid_width}
              grid_block_descriptions={grid?.block_descriptions}
            />
          );

        case BLOCK_HTML_CONTENT:
          return (
            <BlockHtml
              item={grid?.unserialized}
              name={grid?.block_descriptions}
            />
          );

        case BLOCK_CATEGORIES:
          return (
            <div className={"block-items"}>
              <CategoryBlock
                items={items}
                block_properties={grid?.block_properties}
                key={index}
                isMobile={isMobile}
              />
            </div>
          );

        default:
          return <div key={index}></div>;
      }
    }

    if (grid.sub_grid && grid.sub_grid_items) {
      return (
        <Row gutter={[0, 0]} style={{}}>
          {Object.values(grid.sub_grid_items).map(
            (subGrid: GridType, itemKey: any) => (
              <Col
                className={`cls-${subGrid.grid_width}`}
                span={Number(subGrid.grid_width) * 2}
                key={itemKey}
              >
                {renderGridRecursive(
                  subGrid,
                  itemKey,
                  Number(subGrid.grid_width)
                )}
              </Col>
            )
          )}
        </Row>
      );
    }

    return <div className="empty-block" key={index}></div>;
  };

  if (grids && Object.keys(grids).length === 0) {
    return <></>;
  }

  return (
    <div>
      {grids &&
        Object.values(grids).map((grid: GridType, index: number) => (
          <React.Fragment key={index}>
            {renderGridRecursive(grid, index, Number(grid.grid_width))}
          </React.Fragment>
        ))}
    </div>
  );
};

export default RenderGrids;
