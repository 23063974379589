/* eslint-disable @typescript-eslint/indent */
import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../utils/i18n";

type Props = {
  step?: string;
  isMobile: boolean;
};
const CartStepper = ({ step = "cart", isMobile }: Props) => (
  <div className="cstep">
    {isMobile ? (
      <></>
    ) : (
      <div className="tabs">
        <Link
          to={"/checkout/cart"}
          className={`tab tab-bordered ${"tab-active"} font-bold`}
        >
          {i18n.t("step_cart")}
        </Link>
        <Link
          to={"/checkout/checkout"}
          className={`tab tab-bordered ${
            step === "checkout" || step === "confirmation" ? "tab-active" : ""
          } font-bold`}
        >
          {i18n.t("step_checkout")}
        </Link>
        <Link
          to={"/checkout/confirmation"}
          className={`tab tab-bordered ${
            step === "confirmation" ? "tab-active" : ""
          } font-bold`}
        >
          {i18n.t("step_confirmation")}
        </Link>
      </div>
    )}
  </div>
);

export default CartStepper;
