/* eslint-disable @typescript-eslint/indent */
import {
  FETCH_TRANSLATIONS_REQUEST,
  FETCH_TRANSLATIONS_SUCCESS,
  FETCH_TRANSLATIONS_FAIL,
} from "../constants";
import Api from "../../services/configApi";

export function fetch() {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_TRANSLATIONS_REQUEST,
    });

    try {
      const response = await Api.get(`/GetTranslations`);
      dispatch({
        type: FETCH_TRANSLATIONS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: FETCH_TRANSLATIONS_FAIL,
        error,
      });
    }
  };
}
