/* eslint-disable @typescript-eslint/naming-convention */
export const FETCH_PRODUCTS_REQUEST = 'Бараа хүсэлт';
export const FETCH_PRODUCTS_SUCCESS = 'Бараа хүсэлт амжилттай';
export const FETCH_PRODUCTS_FAIL = 'Бараа хүсэлт амжилтгүй';

export const SEARCH_PRODUCTS_REQUEST = 'SERACH_PRODUCTS_ХҮСЭЛТ';
export const SEARCH_PRODUCTS_SUCCESS = 'SERACH_PRODUCTS_АМЖИЛТТАЙ';
export const SEARCH_PRODUCTS_FAIL = 'SERACH_PRODUCTS_АМЖИЛТГҮЙ';

export const SCREEN_MOBILE = 'SCREEN_MOBILE';

export const LANG_CODE_STORAGE = '@choi_language_screen';

export const FETCH_ONE_PRODUCT_REQUEST = 'ТҮГЭЭХ_ONE_PRODUCT_ХҮСЭЛТ';
export const FETCH_ONE_PRODUCT_FAIL = 'ТҮГЭЭХ_ONE_PRODUCT_АМЖИЛТГҮЙ';
export const FETCH_ONE_PRODUCT_SUCCESS = 'ТҮГЭЭХ_ONE_PRODUCT_АМЖИЛТТАЙ';

export const FETCH_CM_PRODUCTS_REQUEST = 'ТҮГЭЭХ_CM_PRODUCTS_ХҮСЭЛТ';
export const FETCH_COMMON_PRODUCTS_FAIL = 'ТҮГЭЭХ_COMMON_PRODUCTS_АМЖИЛТГҮЙ';
export const FETCH_COMMON_PRODUCTS_SUCCESS = 'ТҮГЭЭХ_COMMON_PRODUCTS_АМЖИЛТТАЙ';
export const FETCH_COMMON_PRODUCTS_REQUEST = 'FETCH_COMMON_PRODUCTS_REQUEST';

export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_HIDE = 'NOTIFICATION_HIDE';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';

export const UNSUBSCRIBE_FROM_NOTIFICATIONS_REQUEST
  = 'UNSUBSCRIBE_FROM_NOTIFICATIONS_ХҮСЭЛТ';
export const UNSUBSCRIBE_FROM_NOTIFICATIONS_SUCCESS
  = 'UNSUBSCRIBE_FROM_NOTIFICATIONS_АМЖИЛТТАЙ';
export const UNSUBSCRIBE_FROM_NOTIFICATIONS_FAIL
  = 'UNSUBSCRIBE_FROM_NOTIFICATIONS_АМЖИЛТГҮЙ';

export const FETCH_ORDERS_REQUEST = 'ТҮГЭЭХ_ORDERS_ХҮСЭЛТ';
export const FETCH_ORDERS_SUCCESS = 'ТҮГЭЭХ_ORDERS_АМЖИЛТТАЙ';
export const FETCH_ORDERS_FAIL = 'ТҮГЭЭХ_ORDERS_АМЖИЛТГҮЙ';

export const ORDER_CREATE_REQUEST = 'ЗАХИАЛГА_CREATE_ХҮСЭЛТ';
export const ORDER_CREATE_SUCCESS = 'ЗАХИАЛГА_CREATE_АМЖИЛТТАЙ';
export const ORDER_CREATE_FAIL = 'ЗАХИАЛГА_CREATE_АМЖИЛТГҮЙ';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_RESET_STATE = 'НЭВТРЭЛТ_RESET_STATE';
export const AUTH_LOGOUT = 'НЭВТРЭЛТ_LOGOUT';
export const AUTH_LOADING = 'НЭВТРЭЛТ_LOADING';
export const AUTH_LOADED = 'НЭВТРЭЛТ_LOADED';

export const AUTH_REGESTRATION_REQUEST = 'НЭВТРЭЛТ_REGESTRATION_ХҮСЭЛТ';
export const AUTH_REGESTRATION_SUCCESS = 'НЭВТРЭЛТ_REGESTRATION_АМЖИЛТТАЙ';
export const AUTH_REGESTRATION_FAIL = 'AUTH_REGESTRATION_FAIL';

export const FETCH_PROFILE_FIELDS_REQUEST = 'ТҮГЭЭХ_PROFILE_FIELDS_ХҮСЭЛТ';
export const FETCH_PROFILE_FIELDS_SUCCESS = 'ТҮГЭЭХ_PROFILE_FIELDS_АМЖИЛТТАЙ';
export const FETCH_PROFILE_FIELDS_FAIL = 'ТҮГЭЭХ_PROFILE_FIELDS_АМЖИЛТГҮЙ';

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAIL = 'FETCH_PROFILE_FAIL';

export const UPDATE_PROFILE_REQUEST = 'ШИНЭЧЛЭХ_PROFILE_ХҮСЭЛТ';
export const UPDATE_PROFILE_SUCCESS = 'ШИНЭЧЛЭХ_PROFILE_АМЖИЛТТАЙ';
export const UPDATE_PROFILE_FAIL = 'ШИНЭЧЛЭХ_PROFILE_АМЖИЛТГҮЙ';

export const REGISTER_DEVICE_REQUEST = 'БҮРТГҮҮЛЭЛТ_DEVICE_ХҮСЭЛТ';
export const REGISTER_DEVICE_SUCCESS = 'БҮРТГҮҮЛЭЛТ_DEVICE_АМЖИЛТТАЙ';
export const REGISTER_DEVICE_FAIL = 'БҮРТГҮҮЛЭЛТ_DEVICE_АМЖИЛТГҮЙ';

export const CART_LOADING = 'САГС_LOADING';
export const CART_LOADED = 'САГС_LOADED';

export const CART_SUCCESS = 'САГС_АМЖИЛТТАЙ';
export const CART_FAIL = 'САГС_АМЖИЛТГҮЙ';

export const CART_CHANGE_REQUEST = 'САГС_CHANGE_ХҮСЭЛТ';
export const CART_CHANGE_SUCCESS = 'САГС_CHANGE_АМЖИЛТТАЙ';
export const CART_CHANGE_FAIL = 'САГС_CHANGE_АМЖИЛТГҮЙ';

export const CART_CLEAR_REQUEST = 'САГС_CLEAR_ХҮСЭЛТ';
export const CART_CLEAR_SUCCESS = 'САГС_CLEAR_АМЖИЛТТАЙ';
export const CART_CLEAR_FAIL = 'САГС_CLEAR_АМЖИЛТГҮЙ';

export const CART_REMOVE_REQUEST = 'САГС_REMOVE_ХҮСЭЛТ';
export const CART_REMOVE_SUCCESS = 'САГС_REMOVE_АМЖИЛТТАЙ';
export const CART_REMOVE_FAIL = 'САГС_REMOVE_АМЖИЛТГҮЙ';

export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAIL = 'ADD_TO_CART_FAIL';

export const CART_CONTENT_SAVE_REQUEST = 'CART_CONTENT_SAVE_REQUEST';
export const CART_CONTENT_SAVE_SUCCESS = 'CART_CONTENT_SAVE_SUCCESS';
export const CART_CONTENT_SAVE_FAIL = 'CART_CONTENT_SAVE_FAIL';

export const CART_RECALCULATE_REQUEST = 'САГС_RECALCULATE_ХҮСЭЛТ';
export const CART_RECALCULATE_SUCCESS = 'САГС_RECALCULATE_АМЖИЛТТАЙ';
export const CART_RECALCULATE_FAIL = 'САГС_RECALCULATE_АМЖИЛТГҮЙ';

export const CART_ADD_COUPON_CODE = 'САГС_ADD_COUPON_CODE';
export const CART_ADD_COUPON_CODE_FAILED = 'САГС_ADD_COUPON_CODE_FAILED';
export const CART_REMOVE_COUPON_CODE = 'САГС_REMOVE_COUPON_CODE';

export const CHANGE_AMOUNT = 'CHANGE_AMOUNT';

export const FETCH_PAGES_REQUEST = 'ТҮГЭЭХ_PAGES_ХҮСЭЛТ';
export const FETCH_PAGES_SUCCESS = 'ТҮГЭЭХ_PAGES_АМЖИЛТТАЙ';
export const FETCH_PAGES_FAIL = 'ТҮГЭЭХ_PAGES_АМЖИЛТГҮЙ';

export const SETTLEMENTS_REQUEST = 'SETTLEMENTS_ХҮСЭЛТ';
export const SETTLEMENTS_SUCCESS = 'SETTLEMENTS_АМЖИЛТТАЙ';
export const SETTLEMENTS_FAIL = 'SETTLEMENTS_АМЖИЛТГҮЙ';

export const FETCH_LAYOUTS_BLOCKS_REQUEST = 'ТҮГЭЭХ_BLOCK_ХЭСГҮҮД_ХҮСЭЛТ';
export const FETCH_LAYOUTS_BLOCKS_SUCCESS = 'ТҮГЭЭХ_BLOCK_ХЭСГҮҮД_АМЖИЛТТАЙ';
export const FETCH_LAYOUTS_BLOCKS_FAIL = 'ТҮГЭЭХ_BLOCK_ХЭСГҮҮД_АМЖИЛТГҮЙ';

export const BLOCK_CATEGORIES = 'categories';
export const BLOCK_BANNERS = 'banners';
export const BLOCK_PRODUCTS = 'products';
export const BLOCK_PAGES = 'pages';
export const BLOCK_VENDORS = 'vendors';
export const BLOCK_HTML_CONTENT = 'html_block';


export const WISH_LIST_FETCH_REQUEST = 'WISH_LIST_FETCH_REQUEST';
export const WISH_LIST_FETCH_SUCCESS = 'WISH_LIST_FETCH_SUCCESS';
export const WISH_LIST_FETCH_FAIL = 'WISH_LIST_FETCH_FAIL';

export const WISH_LIST_ADD_REQUEST = 'ХҮСЭЛИЙН_LIST_ADD_ХҮСЭЛТ';
export const WISH_LIST_ADD_SUCCESS = 'ХҮСЭЛИЙН_LIST_ADD_АМЖИЛТТАЙ';
export const WISH_LIST_ADD_FAIL = 'ХҮСЭЛИЙН_LIST_ADD_АМЖИЛТГҮЙ';

export const WISH_LIST_REMOVE_REQUEST = 'ХҮСЭЛИЙН_LIST_REMOVE_ХҮСЭЛТ';
export const WISH_LIST_REMOVE_SUCCESS = 'ХҮСЭЛИЙН_LIST_REMOVE_АМЖИЛТТАЙ';
export const WISH_LIST_REMOVE_FAIL = 'ХҮСЭЛИЙН_LIST_REMOVE_АМЖИЛТГҮЙ';

export const WISH_LIST_CLEAR = 'ХҮСЭЛИЙН_LIST_ЦЭВЭРЛЭХ';

export const FETCH_DISCUSSION_REQUEST = 'ТҮГЭЭХ_DISCUSSION_ХҮСЭЛТ';
export const FETCH_DISCUSSION_SUCCESS = 'ТҮГЭЭХ_DISCUSSION_АМЖИЛТТАЙ';
export const FETCH_DISCUSSION_FAIL = 'ТҮГЭЭХ_DISCUSSION_АМЖИЛТГҮЙ';

export const POST_DISCUSSION_REQUEST = 'POST_DISCUSSION_ХҮСЭЛТ';
export const POST_DISCUSSION_SUCCESS = 'POST_DISCUSSION_АМЖИЛТТАЙ';
export const POST_DISCUSSION_FAIL = 'POST_DISCUSSION_АМЖИЛТГҮЙ';

export const POST_SEND_REVIEW_REQUEST = 'POST_SEND_REVIEW_ХҮСЭЛТ';
export const POST_SEND_REVIEW_SUCCESS = 'POST_SEND_REVIEW_АМЖИЛТТАЙ';
export const POST_SEND_REVIEW_FAIL = 'POST_SEND_REVIEW_АМЖИЛТГҮЙ';

export const DISCUSSION_DISABLED = 'D';
export const DISCUSSION_COMMUNICATION = 'C';
export const DISCUSSION_RATING = 'R';
export const DISCUSSION_COMMUNICATION_AND_RATING = 'B';


export const CHANGE_PRODUCTS_SORT = 'CHANGE_PRODUCTS_SORT';

export const FEATURE_TYPE_DATE = 'D';
export const FEATURE_TYPE_CHECKBOX = 'C';
export const FEATURE_TYPE_CHECKBOX_MULTIPLE = 'M';
export const FEATURE_TYPE_BRAND = 'E';
export const FEATURE_TYPE_SELECT = 'S';
export const FEATURE_TYPE_TEXT = 'T';
export const FEATURE_TYPE_NUMBER_SLIDER = 'N';


export const RECALCULATE_PRODUCT_PRICE_REQUEST
  = 'RECALCULATE_PRODUCT_PRICE_ХҮСЭЛТ';
export const RECALCULATE_PRODUCT_PRICE_FAIL = 'RECALCULATE_PRODUCT_PRICE_АМЖИЛТГҮЙ';
export const RECALCULATE_PRODUCT_PRICE_SUCCESS
  = 'RECALCULATE_PRODUCT_PRICE_АМЖИЛТТАЙ';


export const IMAGE_PICKER_TOGGLE = 'IMAGE_PICKER_TOGGLE';
export const IMAGE_PICKER_CLEAR = 'IMAGE_PICKER_ЦЭВЭРЛЭХ';

export const RESTORE_STATE = 'RESTORE_STATE';

export const USER_TYPE_VENDOR = 'V';
export const USER_TYPE_CUSTOMER = 'C';

export const SET_CURRENCY = 'ТОХИРУУЛАХ ВАЛЮТ';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LANGUAGE_REQUEST = 'SET_LANGUAGE_REQUEST';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_CURRENCIES = 'ВАЛЮТ';

export const LANGUAGE_CURRENCY_FEATURE_FLAG_OFF
  = 'LANGUAGE_CURRENCY_FEATURE_FLAG_OFF';
export const SHOP_CLOSED = 'SHOP_CLOSED';

export const FETCH_ORDER_STATUSES_REQUEST = 'ТҮГЭЭХ_ORDER_STATUSES_ХҮСЭЛТ';
export const FETCH_ORDER_STATUSES_FAIL = 'ТҮГЭЭХ_ORDER_STATUSES_АМЖИЛТГҮЙ';
export const FETCH_ORDER_STATUSES_SUCCESS = 'ТҮГЭЭХ_ORDER_STATUSES_АМЖИЛТТАЙ';

export const VENDOR_ORDERS_LOADED = 'ХУДАЛДААЧИН_ORDERS_LOADED';
export const VENDOR_ORDERS_LOADING = 'ХУДАЛДААЧИН_ORDERS_LOADING';

export const SET_FLOW = 'SET_FLOW';
export const SET_NEXT_STEP = 'SET_NEXT_STEP';

export const RESET_PASSWORD_REQUEST = 'ШИНЭЧЛЭХ_PASSWORD_ХҮСЭЛТ';
export const RESET_PASSWORD_SUCCESS = 'ШИНЭЧЛЭХ_PASSWORD_АМЖИЛТТАЙ';
export const RESET_PASSWORD_FAILED = 'ШИНЭЧЛЭХ_PASSWORD_FAILED';

export const DELETE_ACCOUNT_REQUEST = 'УСТГАХ_ACCOUNT_ХҮСЭЛТ';
export const DELETE_ACCOUNT_SUCCESS = 'УСТГАХ_ACCOUNT_АМЖИЛТТАЙ';
export const DELETE_ACCOUNT_FAILED = 'УСТГАХ_ACCOUNT_FAILED';

export const SET_ADDONS_SETTINGS = 'SET_ADDONS_SETTINGS';

export const LIKE_DISLIKE_REVIEW_REQUEST = 'LIKE_DISLIKE_REVIEW_ХҮСЭЛТ';
export const LIKE_DISLIKE_REVIEW_SUCCESS = 'LIKE_DISLIKE_REVIEW_АМЖИЛТТАЙ';
export const LIKE_DISLIKE_REVIEW_FAIL = 'LIKE_DISLIKE_REVIEW_АМЖИЛТГҮЙ';

export const SET_PRODUCT_REVIEWS = 'SET_PRODUCT_REVIEWS';

export const SET_DATE_FORMAT = 'SET_DATE_FORMAT';

export const GET_SOCIAL_LOGIN_LINKS = 'GET_SOCIAL_LOGIN_LINKS';
export const UPDATE_LOCAL_PRODUCT_FEATURES = 'ШИНЭЧЛЭХ_LOCAL_PRODUCT_FEATURES';

export const PRODUCT_STATUS_ACTIVE = 'A';
export const PRODUCT_STATUS_HIDDEN = 'H';
export const PRODUCT_STATUS_DISABLED = 'D';
export const PRODUCT_STATUS_REQUIRES_APPROVAL = 'R';
export const PRODUCT_STATUS_DISAPPROVED = 'X';

export const SET_LAYOUT_ID = 'SET_LAYOUT_ID';
export const SET_UUID = 'SET_UUID';

export const FETCH_HOMEPAGE_LAYOUTS_REQUEST = 'HOMEPAGE_БЛОКУУД_ХҮСЭЛТ';
export const FETCH_HOMEPAGE_LAYOUTS_SUCCESS = 'HOMEPAGE_БЛОКУУД_АМЖИЛТТАЙ';
export const FETCH_HOMEPAGE_LAYOUTS_FAIL = 'HOMEPAGE_БЛОКУУД_АМЖИЛТГҮЙ';

export const FETCH_MAIN_LAYOUTS_REQUEST = 'FETCH_MAIN_LAYOUTS_REQUEST';
export const FETCH_MAIN_LAYOUTS_SUCCESS = 'FETCH_MAIN_LAYOUTS_SUCCESS';
export const FETCH_MAIN_LAYOUTS_FAIL = 'FETCH_MAIN_LAYOUTS_FAIL';

export const TOP_PANEL = 'TOP_PANEL';
export const CONTENT = 'CONTENT';
export const HEADER = 'HEADER';
export const FOOTER = 'FOOTER';

export const TEMPLATE_SEARCH_BLOCK = 'blocks/static_templates/search.tpl';
export const TEMPLATE_QUICK_LINKS_BLOCK = 'blocks/menu/text_links.tpl';
export const TEMPLATE_LOGO_BLOCK = 'blocks/static_templates/logo.tpl';
export const TEMPLATE_CART_CONTENT_BLOCK = 'blocks/cart_content.tpl';
export const DEVICE_MOBILE_WIDTH = 800;

export const STORE_KEY = '@choihainzan_luxury_store_g';
export const STORE_KEY_AUTH = '@choihainzan_user_ccanonymous';
export const STORE_USER_MAIL = '@chihnzn_anonymous_mid';
export const STORE_LANGUAGE_CODE = '@chihnzn_lng_c';
export const STORE_CHOIHAI = 'choihainzan_token';

export const FETCH_LOCALCART_REQUEST = 'CART_LOCAL_LISTPRODUCTS_REQUEST';
export const FETCH_LOCALCART_SUCCESS = 'САГСАНД ДАХЬ БАРААНЫ INDEXEDDB ЖАГСААЛТ';
export const FETCH_LOCALCART_FAIL = 'САГСАНД ДАХЬ БАРААНЫ INDEXEDDB ЖАГСААЛТ';

export const CH_CATEGORIES_REQUEST = 'CH_CATEGORIES_REQUEST';
export const CH_CATEGORIES_SUCCESS = 'CH_CATEGORIES_SUCCESS';
export const CH_CATEGORIES_FAILED = 'CH_CATEGORIES_FAILED';

export const FETCH_CART_PRODUCTS_REQUEST = 'FETCH_CART_PRODUCTS_REQUEST';
export const FETCH_CART_PRODUCTS_SUCCESS = 'FETCH_CART_PRODUCTS_SUCCESS';
export const FETCH_CART_PRODUCTS_FAIL = 'FETCH_CART_PRODUCTS_FAIL';

export const MINI_CART_SHOW_REQUEST = 'MINI_CART_SHOW_REQUEST';
export const MINI_CART_SHOW_SUCCESS = 'MINI_CART_SHOW_SUCCESS';
export const MINI_CART_SHOW_FAILED = 'MINI_CART_SHOW_FAILED';


export const BLOGS_REQUEST = 'BLOGS_REQUEST';
export const BLOGS_SUCCESS = 'BLOGS_SUCCESS';
export const BLOGS_FAILED =  'BLOGS_FAILED';
export const BLOG_DETAIL_REQUEST = 'BLOG_DETAIL_REQUEST';
export const BLOG_DETAIL_SUCCESS = 'BLOG_DETAIL_SUCCESS';
export const BLOG_DETAIL_FAILED =  'BLOG_DETAIL_FAILED';

export const NEWPRESS_DETAIL_REQUEST = 'NEWPRESS_DETAIL_REQUEST';
export const NEWPRESS_DETAIL_SUCCESS = 'NEWPRESS_DETAIL_SUCCESS';
export const NEWPRESS_DETAIL_FAILED =  'NEWPRESS_DETAIL_FAILED';


export const CART_FETCH_SUCCESS = 'CART_FETCH_SUCCESS';



export const SHIPMENT_LOCATIONS_REQUEST = 'SHIPMENT_LOCATIONS_REQUEST';
export const SHIPMENT_LOCATIONS_SUCCESS = 'SHIPMENT_LOCATIONS_SUCCESS';
export const SHIPMENT_LOCATIONS_FAILED =  'SHIPMENT_LOCATIONS_FAILED';


export const FETCH_CUSTOM_LAYOUTS_REQUEST = 'FETCH_CUSTOM_LAYOUTS_REQUEST';
export const FETCH_CUSTOM_LAYOUTS_SUCCESS = 'FETCH_CUSTOM_LAYOUTS_SUCCESS';
export const FETCH_CUSTOM_LAYOUTS_FAIL = 'FETCH_CUSTOM_LAYOUTS_FAIL';

export const CATEGORIES_WITH_SUB_REQUEST = 'CATEGORIES_WITH_SUB_REQUEST';
export const CATEGORIES_WITH_SUB_SUCCESS = 'CATEGORIES_WITH_SUB_SUCCESS';
export const CATEGORIES_WITH_SUB_FAILED =  'CATEGORIES_WITH_SUB_FAILED';


export const BOUTIQUES_REQUEST = 'BOUTIQUES_REQUEST';
export const BOUTIQUES_SUCCESS = 'BOUTIQUES_SUCCESS';
export const BOUTIQUES_FAILED =  'BOUTIQUES_FAILED';

export const MAINNAV_SHOW = 'MAINNAV_SHOW';
export const MAINNAV_HIDE = 'MAINNAV_HIDE';


export const TOPHEADER_SHOW = 'TOPHEADER_SHOW';
export const TOPHEADER_HIDE = 'TOPHEADER_HIDE';
export const FOOTER_SHOW = 'FOOTER_SHOW';
export const FOOTER_HIDE = 'FOOTER_HIDE';

export const RELOAD_DATA_REQUEST = 'RELOAD_DATA_REQUEST';
export const RELOAD_DATA_DONE = 'RELOAD_DATA_DONE';


export const MAINMENU_REQUEST = 'MAINMENU_REQUEST';
export const MAINMENU_SUCCESS = 'MAINMENU_SUCCESS';
export const MAINMENU_FAILED =  'MAINMENU_FAILED';


export const FETCH_ORDERSWITH_DETAILED_REQUEST = 'FETCH_ORDERSWITH_DETAILED_REQUEST';
export const FETCH_ORDERSWITH_DETAILED_SUCCESS = 'FETCH_ORDERSWITH_DETAILED_SUCCESS';
export const FETCH_ORDERSWITH_DETAILED_FAIL = 'FETCH_ORDERSWITH_DETAILED_FAIL';


export const POINTS_REQUEST = 'POINTS_REQUEST';
export const POINTS_SUCCESS = 'POINTS_SUCCESS';
export const POINTS_FAILED = 'POINTS_FAILED';

export const CATEGORIES_TREE_REQUEST = 'CATEGORIES_TREE_REQUEST';
export const CATEGORIES_TREE_SUCCESS = 'CATEGORIES_TREE_SUCCESS';
export const CATEGORIES_TREE_FAILED =  'CATEGORIES_TREE_FAILED';

export const CATEGORY_DETAIL_REQUEST = 'CATEGORY_DETAIL_REQUEST';
export const CATEGORY_DETAIL_SUCCESS = 'CATEGORY_DETAIL_SUCCESS';
export const CATEGORY_DETAIL_FAILED =  'CATEGORY_DETAIL_FAILED';


export const FETCH_PAGE_REQUEST = 'FETCH_PAGE_REQUEST';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_FAIL = 'FETCH_PAGE_FAIL';


export const FETCH_TRANSLATIONS_REQUEST = 'FETCH_TRANSLATIONS_REQUEST';
export const FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_FAIL = 'FETCH_TRANSLATIONS_FAIL';




export const GET_VERIFY_CODE_REQUEST = 'GET_VERIFY_CODE_REQUEST';
export const GET_VERIFY_CODE_SUCCESS = 'GET_VERIFY_CODE_SUCCESS';
export const GET_VERIFY_CODE_FAIL = 'GET_VERIFY_CODE_FAIL';

export const CH_CAMPAIGN_REQUEST = 'CH_CAMPAIGN_REQUEST';
export const CH_CAMPAIGN_SUCCESS = 'CH_CAMPAIGN_SUCCESS';
export const CH_CAMPAIGN_FAILED = 'CH_CAMPAIGN_FAILED';