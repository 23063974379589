/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */

import cn from "clsx";
import s from "./Input.module.css";
import type { InsHTMLAttributes } from "react";
import React, { useEffect, useState } from "react";
import { Input } from "antd";

export type InputProps = {
  className?: string;
  onChange?: (...args: any[]) => any;
  isError?: boolean;
  isSuccess?: boolean;
  label: string;
  error?: string;
  labelTRight?: string;
  isRequired?: boolean;
  placeholder?: string;
  type: string;
  name: string;
  value: any;
  size?: "small" | "large" | "middle";
  disabled?: boolean
} & InsHTMLAttributes<HTMLInputElement>;

const InputComponent: React.FC<InputProps> = (props) => {
  const {
    className,
    children,
    onChange,
    isError,
    isSuccess,
    error,
    label,
    labelTRight,
    isRequired,
    type,
    name,
    value,
    size,
    disabled,
    ...rest
  } = props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }

    return null;
  };

  return (
    <>
      <div className="form-control w-full">
        <label className="label">
          {label && (
            <span className="label-text-alt font-bold float-left text-black mt-0">
              {label} {isRequired && <>*</>}
            </span>
          )}

          {labelTRight && <span className="label-text-alt">{labelTRight}</span>}
        </label>

        <Input
          onChange={handleOnChange}
          status={error ? "error" : ""}
          placeholder=""
          type={type}
          name={name}
          value={value}
          {...rest}
          bordered          
          size={size ? size : "middle"}
          disabled={disabled ? true : false}
          className={className}
        />

        <label className="label text-deep-orange-700 ">
          {error && (
            <span className="label-text-alt text-rose-600 -mt-1 text-deep-orange-700 ">
              {error}
            </span>
          )}
        </label>
      </div>
    </>
  );
};

export default InputComponent;
