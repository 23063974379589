/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/default-param-last */

import {
  BOUTIQUES_REQUEST,
  BOUTIQUES_SUCCESS,
  BOUTIQUES_FAILED,
} from "./constants";
type Initialstate = {
  fetching: boolean;
};
const initialState: Initialstate = {
  fetching: false,
};

const boutiques = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BOUTIQUES_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case BOUTIQUES_SUCCESS:
      return {
        ...state,
        fetching: false,
        ...action.payload,
      };

    case BOUTIQUES_FAILED:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};

export default boutiques;
