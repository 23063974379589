import React from "react";

type Props = {
  src?: string;
  width?: number;
  height?: number;
  alt?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
};
const ImageComponent = ({
  width,
  height,
  alt,
  onClick,
  src,
  style,
  className,
}: Props) => {
  return (
    <img
      className={className ? className : ""}
      src={src || "/assets/no-image.jpg"}
      alt={alt ? alt : "choihainzan.com"}
      style={{
        ...style,
      }}
    />
  );
};

export default ImageComponent;
