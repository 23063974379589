/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import type { LoginOneTimePassword } from "../../types/authTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as authActions from "../../reducers/actions/authActions";
import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { TypeOfStates } from "../../types/stateTypes";
import Input from "../ui/Input";
import { Button } from "antd";
import { drawerContextHide } from "../../lib/Functions";
import i18n from "../../utils/i18n";
import Loader from "../../layouts/Loader";
import { STORE_USER_MAIL } from "../../reducers/constants";
import base64 from "base-64";

type Props = {
  navigateUrl?: string;
};

const messageRequired = " !Required information";

const SignupSchema = yup.object().shape({
  email: yup.string().notRequired(),
  one_time_password: yup.string().required(messageRequired),
});

const LoginForm: FC<Props> = ({ navigateUrl }) => {
  const dispatch = useAppDispatch();
  let location = useLocation();
  const authState = useAppSelector((state: TypeOfStates) => state.auth);
  const navigate = useNavigate();
  const [awaitLogin, setAwaitLogin] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [getstMail, setstMail] = useState<string>();

  const defaultValues = {
    email: getstMail || "",
    one_time_password: "",
  };

  useEffect(() => {
    if (authState.logged) {
      setAwaitLogin(false);
      drawerContextHide();
    }
  }, [authState]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(SignupSchema),
  });

  const onSubmit = async (data: LoginOneTimePassword) => {
    setAwaitLogin(true);

    if (data) {
      if (data?.one_time_password && getstMail) {
        const res: any = await dispatch(
          authActions.loginWithOneTimePassword({
            email: getstMail,
            one_time_password: data?.one_time_password,
          })
        );

        // {"message":"Not Found","status":404}
        if (res.data?.status === 404) {
          setError("Нэг удаагын нууц үг буруу байна . ");
          setAwaitLogin(false);
        }

        if (res.data?.token && res.data?.ttl) {
          setError("");
          setAwaitLogin(false);
          await dispatch(
            authActions.getUserData({
              token: res.data?.token,
              ttl: res.data?.ttl,
            })
          );
          dispatch(authActions.authLoaded());
          drawerContextHide();
          navigate("/account/profile");
        }
      }

      if (authState.logged) {
        setAwaitLogin(false);
        drawerContextHide();
        navigateUrl ? navigate(navigateUrl) : navigate(location.pathname);
      }
    }
  };

  const onError = (errors: any) => {
    console.log(errors);
  };

  const getLocalItem = async () => {
    const m = localStorage.getItem(STORE_USER_MAIL);
    if (m) {
      return base64.decode(m);
    }
  };

  useEffect(() => {
    getLocalItem().then((stMail) => {
      if (stMail && stMail.length > 0) {
        setLoading(true);
        setstMail(stMail);
        setLoading(false);
        console.log(getstMail, getstMail);
      }
    });
  }, [getstMail]);

  return (
    <div className="login-form">
      {loading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="inputs">
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <Input
                type="email"
                name="email"
                value={getstMail}
                defaultValue={getstMail}
                onChange={onChange}
                onBlur={onBlur}
                isError={Boolean(errors?.email)}
                isSuccess={false}
                error={errors.email?.message}
                label={i18n.t("Email")}
                isRequired
                size={"large"}
                disabled={true}
                style={{
                  color: "black",
                  fontSize: 13,
                }}
              />
            )}
            name="email"
            control={control}
            rules={{ required: true }}
          />
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <Input
                type="password"
                name="password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label={i18n.t("One time password")}
                isError={Boolean(errors?.one_time_password)}
                isRequired
                error={error?.message}
                size={"large"}
              />
            )}
            name="one_time_password"
            control={control}
            rules={{ required: true }}
          />
          {error && <div className="error">{error}</div>}
        </div>

        <p
          style={{
            fontSize: 12,
          }}
        >
          Таны И-Мэйл хаягруу нэг удаагийн нууц үгийг илгээгдсэн та нэг удаагийн
          нууц үгээр нэвтрэн нууц үгээ шинэчилнэ үү !
        </p>

        <Button
          type={"primary"}
          loading={awaitLogin}
          size={"large"}
          onClick={handleSubmit(onSubmit, onError)}
        >
          {i18n.t("Sign in")}
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
