/// https://beta.choihainzan.com/api/4.0/Press/
import {
  BLOGS_REQUEST,
  BLOGS_SUCCESS,
  BLOGS_FAILED,
  BLOG_DETAIL_REQUEST,
  BLOG_DETAIL_SUCCESS,
  BLOG_DETAIL_FAILED,
} from "../constants";
import Api from "../../services/configApi";

export function fetch(parent_id = 0) {
  return async (dispatch: any) => {
    dispatch({
      type: BLOGS_REQUEST,
    });

    try {
      const response = await Api.get(
        `/Press&&page_type=B${parent_id ? "&parent_id=" + parent_id : ""}`
      );
      dispatch({
        type: BLOGS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: BLOGS_FAILED,
        error,
      });
    }
  };
}

export function fetchPageDetail(pageId = "", press_type: string) {
  return async (dispatch: any) => {
    dispatch({
      type: BLOG_DETAIL_REQUEST,
    });

    try {
      const response = await Api.get(`/Press/${pageId}?press_type=${press_type}`);
      dispatch({
        type: BLOG_DETAIL_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: BLOG_DETAIL_FAILED,
        error,
      });
    }
  };
}
