import _ from "lodash";
import {
  NewPressColumn,
  NewPressItem,
  NewPressList,
  NewPressListRow,
} from "../../types/blogs";
import { Row, Col, Image } from "antd";
import { createMarkup } from "../../lib/Functions";
import NewsSlider from "./CampaignSlider";
import { useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";
import { Fragment, useEffect, useRef, useState } from "react";
import { Youtube, ImageItem } from "./Items";

const RenderNewPress: React.FC<NewPressList> = ({ items }) => {
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const isMobile = useAppSelector(
    (state: TypeOfStates) => state.isMobile.status
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [marginTop, setMarginTop] = useState<Record<string, number>>({});
  const parentRef: any = useRef<Record<string, HTMLDivElement | null>>({});
  const childRef: any = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    Object.values(items).forEach((item: NewPressListRow) => {
      if (item?.columns) {
        setLoading(true);
        Object.values(item?.columns).forEach((column: NewPressColumn) => {
          if (column.items && Object.keys(column.items).length > 0) {
            Object.values(column.items).forEach((item: NewPressItem) => {
              if (
                item.item_type === "text" &&
                parentRef?.current &&
                parentRef?.current[column?.column_id]
              ) {
                if (
                  parentRef?.current[column?.column_id]?.firstElementChild &&
                  item?.item_position === "center"
                ) {
                  const d =
                    (Number(
                      parentRef?.current[column.column_id]?.clientHeight
                    ) -
                      Number(
                        parentRef?.current[column?.column_id].firstElementChild
                          ?.clientHeight
                      )) /
                    2.1;

                  setMarginTop((prevState) => ({
                    ...prevState,
                    [column?.column_id]: d,
                  }));
                } else {
                  setMarginTop((prevState) => ({
                    ...prevState,
                    [column?.column_id]: 5,
                  }));
                }
              }
            });
          }
        });
      }
    });
    setLoading(false);
  }, [items, parentRef, childRef]);

  const getColNum = (col = 24) => col * 2;

  if (!items || loading) {
    return <>loading ...</>;
  }

  return (
    <div>
      {_.orderBy(items, ["sort_order"], ["asc"]).map(
        (item: NewPressListRow) => (
          <Row key={item.row_id} gutter={[0, 0]}>
            {item?.columns ? (
              Object.values(item?.columns).map((column: NewPressColumn) => (
                <Col
                  xl={getColNum(Number(column.col_width_number))}
                  md={getColNum(Number(column.col_width_number))}
                  xs={24}
                  sm={getColNum(Number(column.col_width_number))}
                  xxl={getColNum(Number(column.col_width_number))}
                  key={column.column_id}
                  ref={(el) => (parentRef.current[column.column_id] = el)}
                >
                  {column.items ? (
                    Object.keys(column.items).length > 0 &&
                    Object.values(column.items).map((item: NewPressItem) => {
                      switch (item.item_type) {
                        case "slider":
                          return (
                            <div
                              key={item.id}
                              className={`item_maxDS_width_${item?.text_max_width}`}
                              style={
                                item.slider_style_type === "product_back_black"
                                  ? {
                                      background: "black",
                                      paddingLeft: isMobile ? 0 : 80,
                                      paddingRight: isMobile ? 0 : 80,
                                      paddingBottom: 80,
                                      paddingTop: 20,
                                    }
                                  : {}
                              }
                            >
                              {item.slider_style_type ===
                                "product_back_black" && (
                                <h2
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                    lineHeight: "44px",
                                  }}
                                >
                                  THE COLLECTION
                                </h2>
                              )}
                              <NewsSlider
                                slideNo={Number(item.slider_view_no)}
                                slides={item?.slider_items}
                                isMobile={getStates.isMobile.status}
                              />
                            </div>
                          );
                        case "text":
                          return (
                            <div
                              key={item.id}
                              ref={(el) =>
                                (childRef.current[column.column_id] = el)
                              }
                              className={`text_element text_align_${item?.item_position} column_id_${column?.column_id}`}
                              style={
                                isMobile
                                  ? {
                                      padding: 20,
                                      textAlign: "center",
                                      height: "auto",
                                      width: "auto",
                                    }
                                  : {
                                      marginTop:
                                        marginTop[column?.column_id] || 5,
                                    }
                              }
                              dangerouslySetInnerHTML={createMarkup(item.value)}
                            />
                          );
                        case "image":
                          return <ImageItem key={item.id} item={item} />;
                        case "youtube":
                          return <Youtube key={item.id} item={item} />;
                        default:
                          return <Fragment key={item.id}></Fragment>;
                      }
                    })
                  ) : (
                    <Fragment key={column.column_id}> </Fragment>
                  )}
                </Col>
              ))
            ) : (
              <></>
            )}
          </Row>
        )
      )}
    </div>
  );
};

export default RenderNewPress;
