import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { i18nt } from "../../utils/i18n";
import { RenderPicker } from "./AdvancedFilter/Items";

type Props = {
  params: any;
  filters: any;
  onChangeFilter: (arg: any) => void;
  setShowFilterView: (arg: any) => void;
  setActiveFiltersCount: (arg: any) => void;
  isMobile: boolean;
};

const AdvancedModalFilter: React.FC<Props> = ({
  params,
  filters,
  onChangeFilter,
  setShowFilterView,
  setActiveFiltersCount,
  isMobile,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<any>([]);
  const [openIDs, setOpenIDs] = useState<any>([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);

  const handleCalculateActiveFilters = useCallback(() => {
    const selected = filters.filter(
      (item: any) => item.selected_variants !== undefined || item.selected_range
    );

    const selectedFiltersOnload = selected
      .flatMap((filter: any) => {
        if (
          filter.selected_variants &&
          (filter.filter_style === "checkbox" ||
            filter.filter_style === "color")
        ) {
          return _.values(filter.selected_variants).map((variantItem) => ({
            ...filter,
            ...variantItem,
          }));
        } else if (filter.filter_style === "slider") {
          return {
            ...filter,
            min: Math.ceil(filter.left),
            max: Math.ceil(filter.right),
          };
        }
        return null;
      })
      .filter(Boolean);

    setSelectedFilters(selectedFiltersOnload);
  }, [filters]);

  useEffect(() => {
    handleCalculateActiveFilters();
  }, [handleCalculateActiveFilters]);

  useEffect(() => {
    setActiveFiltersCount(selectedFilters.length);
  }, [selectedFilters.length, setActiveFiltersCount]);

  const handleChangeFilter = useCallback(() => {
    const getFilterIds = (filterItems: any) => {
      const { filter_id, filter_style, field_type } = filterItems[0];
      if (filter_style === "checkbox" || filter_style === "color") {
        return `${filter_id}-${filterItems
          .map((item: any) => item.variant_id)
          .join("-")}`;
      }
      if (filter_style === "slider") {
        const active = filterItems[0];
        const min = _.round(active.min, 2);
        const max = _.round(active.max, 2);
        const extra = active.extra ? `-${active.extra}` : "";
        return `${filter_id}-${min}-${max}${extra}`;
      }
      return "";
    };

    const groupedFilters = _.groupBy(selectedFilters, "filter_id");
    const filtersIds = Object.keys(groupedFilters)
      .map((key) => getFilterIds(groupedFilters[key]))
      .filter((filterId) => filterId !== "");

    onChangeFilter(filtersIds.join("_"));
    setShowFilterView(false);
  }, [onChangeFilter, selectedFilters, setShowFilterView]);

  const setSelectedFiltersFunction = async (e: any) => {
    setSelectedFilters(e);
  };

  const filterReset = async () => {
    setSelectedFilters([]);
    onChangeFilter([]);
    setShowFilterView(false);
  };

  return (
    <div className="category-sort-section">
      <div
        className="filter-section filter-dropdown"
        onMouseLeave={() => {
          handleChangeFilter();
          setFilterOpen(false);
          setSelectedFilterIndex(99999);
        }}
      >
        <div className="filter-titles">
          {Object.values(filters).map((filter: any, index: number) => {
            if (filter?.filter_style === "checkbox") {
              return (
                <React.Fragment key={+filter.filter_id + 1}>
                  <Space
                    onClick={() => {
                      setSelectedFilterIndex(index);
                      setFilterOpen(true);
                    }}
                    onMouseOver={() => {
                      setSelectedFilterIndex(index);
                      setFilterOpen(true);
                    }}
                    onMouseUpCapture={() => {
                      setSelectedFilterIndex(index);
                      setFilterOpen(true);
                    }}
                  >
                    {filter?.filter}
                    {selectedFilterIndex === index ? (
                      <UpOutlined />
                    ) : (
                      <DownOutlined />
                    )}
                  </Space>
                </React.Fragment>
              );
            } else {
              return <></>;
            }
          })}
          <Space>
            {selectedFilters && selectedFilters.length > 0 && (
              <Button
                type="text"
                size={"small"}
                onClick={() => {
                  filterReset();
                }}
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                {i18nt("Reset all filters")}
              </Button>
            )}
          </Space>
        </div>
        {filterOpen && filters ? (
          <div className="variants">
            {Object.values(
              filters[selectedFilterIndex].filter_style === "checkbox" ||
                filters[selectedFilterIndex].filter_style === "color"
            ) ? (
              <RenderPicker
                item={filters[selectedFilterIndex]}
                setSelectedFilters={setSelectedFiltersFunction}
                selectedFilters={selectedFilters}
                openIDs={openIDs}
                setOpenIDs={setOpenIDs}
                isMobile={isMobile}
              />
            ) : (
              <></>
            )}
            {isMobile && (
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  handleChangeFilter();
                  setFilterOpen(false);
                  setSelectedFilterIndex(99999);
                }}
                icon={<SearchOutlined />}
              >
                Ангилах
              </Button>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AdvancedModalFilter;
