import { MenuOutlined } from "@ant-design/icons";
import { drawerContext } from "../../hooks/DrawerModal";
import MobileNavigation from "./MobileNavigation";

const BurgerMenu = () => {
  return (
    <button
      type="button"
      className="mobile-bar-menu"
      onClick={() => {
        document.body.style.overflow = "hidden";
        drawerContext.show(<MobileNavigation />, "", "left");
      }}
    >
      <span className="sr-only">Open menu</span>
      <MenuOutlined
        style={{
          color: "#000",
          fontSize: 22,
        }}
      />
    </button>
  );
};

export default BurgerMenu;
