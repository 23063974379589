/* eslint-disable @typescript-eslint/indent */
import React from "react";
import { useAppSelector } from "../reducers/cart/hooks";
import { TypeOfStates } from "../types/stateTypes";
import { Button, Image } from "antd";
import { GIFT_BOX_PRODUCT_ID } from "../reducers/constants/settings";
import { createMarkup } from "../lib/Functions";

interface Props {
  isShowBtn?: boolean;
  loader?: boolean;
  addToCartGift?: () => void;
}

const TheGiftBox: React.FC<Props> = ({
  isShowBtn = false,
  addToCartGift,
  loader = false,
}) => {
  const { front_settings: frontSettings } = useAppSelector(
    (state: TypeOfStates) => state.mainMenus
  );

  if (!frontSettings?.[GIFT_BOX_PRODUCT_ID]) {
    return <></>;
  }

  const { title, description, image } = frontSettings[GIFT_BOX_PRODUCT_ID];

  return (
    <div className="gift-box">
      <h2 className="title">{title}</h2>
      <div
        className="description"
        dangerouslySetInnerHTML={createMarkup(description)}
      />
      {image && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Image
            src={image}
            style={{
              objectFit: "cover",
              objectPosition: "center center",
              marginTop: 20,
              width: 266,
              height: 307,
            }}
            alt="choihainzan"
            preview={false}
          />
        </div>
      )}
      {isShowBtn && (
        <Button
          onClick={addToCartGift}
          style={{ margin: "0 auto", marginTop: 10 }}
          loading={loader}
        >
          Бэлгийн хайрцаг нэмэх
        </Button>
      )}
    </div>
  );
};

export default TheGiftBox;
