/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { FC, useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import type { LoginResetData } from "../../types/authTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as authActions from "../../reducers/actions/authActions";
import { useAppSelector } from "../../reducers/cart/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { TypeOfStates } from "../../types/stateTypes";
import Input from "../ui/Input";
import { Button } from "antd";
import { drawerContextHide } from "../../lib/Functions";
import i18n from "../../utils/i18n";
import { STORE_USER_MAIL } from "../../reducers/constants";
import { FormType } from "./LoginLayout";
import base64 from "base-64";

type Props = {
  navigateUrl?: string;
  setFormType: (arg: FormType) => void;
};
// resetPassword
const emailRequired = " !Required information";

const SignupSchema = yup.object().shape({
  email: yup.string().email().required(emailRequired),
});

const ForgotPasswordForm: FC<Props> = ({ navigateUrl, setFormType }) => {
  let location = useLocation();
  const authState = useAppSelector((state: TypeOfStates) => state.auth);
  const navigate = useNavigate();
  const [awaitLogin, setAwaitLogin] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>("");
  const [userMail, setUserMail] = useState<string>();
  const [msgType, setMsgType] = useState<string>("S");
  const defaultValues = {
    email: "",
  };

  useEffect(() => {
    if (authState.logged) {
      setAwaitLogin(false);
      drawerContextHide();
    }
  }, [authState]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(SignupSchema),
  });

  const onSubmit = async (data: LoginResetData) => {
    setAwaitLogin(true);

    if (data) {
      authActions
        .resetPasswordNew(data)
        .then((res: any) => {
          if (res?.data?.message === "Not Found") {
            setMsg("Хаяг бүртгэлгүй байна ! ");
            setMsgType("E");
            setAwaitLogin(false);
          } else {
            setMsgType("S");
            setAwaitLogin(false);
            setMsg(
              "Таны И-Мэйл хаягруу нэг удаагийн нууц үгийг илгээлээ та нэг удаагийн нууц үгээр нэвтрэн нууц үгээ шинэчилнэ үү  ! "
            );

            localStorage.setItem(STORE_USER_MAIL, base64.encode(data.email));           
            
            setFormType({
              type: "ONETIMEPASSWORD",
            });
          }
        })
        .catch((err) => {
          setMsgType("E");
          setAwaitLogin(false);
          setMsg(err);
        });

      if (authState.logged) {
        setAwaitLogin(false);
        drawerContextHide();
        navigateUrl ? navigate(navigateUrl) : navigate(location.pathname);
      }
    }
  };

  const onError = (errors: any) => {
    console.log(errors);
  };

  const stFormType = useCallback(() => {
    setFormType({
      type: "LOGIN",
    });
  }, [setFormType]);

  return (
    <div className="reset-section">
      <Button
        type="text"
        size="small"
        className="back-button"
        style={{
          float: "right",
          position: "absolute",
          right: 0,
          fontSize: 12,
        }}
        onClick={stFormType}
      >
        {i18n.t("Back")}
      </Button>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="reset-form">
        <Controller
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { isTouched, isDirty, error },
          }): any => (
            <Input
              type="email"
              name="email"
              value={value}
              onChange={(value) => {
                onChange(value);
                setUserMail(value);
              }}
              onBlur={onBlur}
              isError={Boolean(errors?.email)}
              isSuccess={false}
              error={errors.email?.message}
              label={i18n.t("Email")}
              isRequired
              size={"large"}
            />
          )}
          name="email"
          control={control}
          rules={{ required: true }}
        />
        <p
          style={{
            fontSize: 12,
            marginBottom: 8,
            color: msgType === "S" ? "#464646" : "red",
            fontWeight: 600,
          }}
        >
          {i18n.t(msg)}
        </p>
        <p
          style={{
            fontSize: 12,
            marginBottom: 40,
          }}
        >
          {i18n.t("Please enter the email address you used to register")}
        </p>

        <Button
          type={"primary"}
          loading={awaitLogin}
          size={"large"}
          onClick={handleSubmit(onSubmit, onError)}
        >
          {i18n.t("Send")}
        </Button>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
