import { get } from "lodash";
import {
  SET_CURRENCY,
  SET_LANGUAGE,
  GET_CURRENCIES,
  GET_LANGUAGES,
  RESTORE_STATE,
  LANGUAGE_CURRENCY_FEATURE_FLAG_OFF,
  SHOP_CLOSED,
  GET_SOCIAL_LOGIN_LINKS,
  SET_ADDONS_SETTINGS,
  SET_LAYOUT_ID,
  SET_LANGUAGE_REQUEST,
  SET_LANGUAGE_SUCCESS,
} from "./constants";

const initialState = {
  selectedCurrency: {
    symbol: "",
    currencyCode: "",
  },
  selectedLanguage: {
    langCode: "mn",
    name: "Mongolia",
    selected: true,
  },
  wishlistAddon: {
    isEnabled: true,
  },
  productReviewsAddon: {
    isEnabled: false,
    isCommentOnly: false,
  },
  dateFormat: "",
  languageCurrencyFeatureFlag: true,
  languages: null,
  currencies: null,
  isShopClosed: false,
  socialLoginLinks: {},
  layoutId: null,
  set_language_request: false,
};

function SettingsFunction(state = initialState, action: any) {
  switch (action.type) {
    case SET_LAYOUT_ID:
      return {
        ...state,
        layoutId: action.payload,
      };
    case SET_CURRENCY:
      const newSelectedCurrency = {
        currencyCode: action.payload.currencyCode,
        symbol: action.payload.symbol,
      };
      return {
        ...state,
        selectedCurrency: newSelectedCurrency,
      };

    case GET_CURRENCIES:
      return {
        ...state,
        languageCurrencyFeatureFlag: true,
        currencies: action.payload.map((el: any) => {
          return {
            selected: el.currency_code === state.selectedCurrency.currencyCode,
            currencyCode: el.currency_code,
            symbol: el.symbol,
          };
        }),
      };

    case SET_LANGUAGE_REQUEST:
      return {
        ...state,
        set_language_request: true,
      };
    case SET_LANGUAGE_SUCCESS:
      return {
        ...state,
        set_language_request: false,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        selectedLanguage: {
          langCode: action.payload.langCode,
          name: action.payload.name,
          selected: action.payload.selected,
        },
        set_language_request: false,
      };

    case GET_LANGUAGES:
      return {
        ...state,
        languageCurrencyFeatureFlag: true,
        languages: action.payload.map((el: any) => {
          return {
            selected: el.lang_code === state.selectedLanguage.langCode,
            langCode: el.lang_code,
            name: el.name,
          };
        }),
      };

    case LANGUAGE_CURRENCY_FEATURE_FLAG_OFF:
      return {
        ...state,
        languageCurrencyFeatureFlag: false,
      };

    case GET_SOCIAL_LOGIN_LINKS:
      let allowedSocialLinks = ["facebook", "google"];

      const filteredSocialLoginLinks: any = {};

      Object.keys(action.payload).forEach((provider) => {
        if (allowedSocialLinks.includes(provider)) {
          filteredSocialLoginLinks[provider] = action.payload[provider];
        }
      });
      return {
        ...state,
        socialLoginLinks: filteredSocialLoginLinks,
      };

    case SHOP_CLOSED:
      return {
        ...state,
        isShopClosed: true,
      };

    case SET_ADDONS_SETTINGS:
      const dateFormat = get(
        action.payload,
        "settings.appearance.calendar_date_format"
      );

      const productReviewsAddon = get(action.payload, "addons.product_reviews");
      const wishlistAddon = get(action.payload, "addons.wishlist");
      const isCommentOnly =
        productReviewsAddon?.review_fields.length === 1 ? true : false;

      return {
        ...state,
        productReviewsAddon: {
          isEnabled: productReviewsAddon?.is_enabled,
          isCommentOnly,
        },
        wishlistAddon: {
          isEnabled: wishlistAddon?.is_enabled,
        },
        dateFormat: dateFormat === "day_first" ? "dd/MM/yyyy" : "MM/dd/yyyy",
      };

    case RESTORE_STATE:
      return {
        ...state,
        ...action.payload.settings,
      };

    default:
      return state;
  }
}

export default SettingsFunction;
