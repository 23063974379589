import backCirclebtnImg from "../assets/backCircleBtn.svg";
import { Button } from "antd";

type Typeb = {
  onClick?: () => void;
  style?: React.CSSProperties;
};
const BackCircleBtn = ({ onClick, style }: Typeb) => {
  const BackCirclebtnImg = () => {
    return (
      <img
        style={{
          height: 33,
          objectFit: "cover",
          objectPosition: "center center",
        }}
        src={backCirclebtnImg}
        alt={""}
        onClick={onClick && onClick}
      />
    );
  };
  return (
    <div
      style={{
        ...style,
      }}
    >
      <Button
        onClick={onClick && onClick}
        type="primary"
        shape="circle"
        icon={<BackCirclebtnImg />}
        style={{
          backgroundColor: "#ffffff",
          border: "none",
          padding: 0,
          boxShadow: "none",
        }}
      />
    </div>
  );
};

export default BackCircleBtn;
