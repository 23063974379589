/* eslint-disable @typescript-eslint/no-unsafe-return */

import { drawerContext } from "../hooks/DrawerModal";

export function getOSType() {
  const ua = navigator.userAgent;

  if (/iPhone|iPad|iPod|Opera Mini/i.test(ua)) {
    return "ios";
  }
  if (/Android/i.test(ua)) {
    return "android";
  }
  return "computer";
}

export function productImagePairsSlider(product: any, pairId = 0) {
  const image_pairs = {
    ...Object.values(product.image_pairs),
    [Object.values(product.image_pairs).length]: product.main_pair,
  };
  const result = Object.values(image_pairs);

  if (pairId > 0) {
    const selectedPair = result.find(({ pair_id }) => +pair_id === pairId);
    return selectedPair
      ? [selectedPair, ...result.filter(({ pair_id }) => +pair_id !== pairId)]
      : result;
  }

  return result.length > 0 ? result : [];
}

export function createMarkup(text: any) {
  return { __html: text };
}

export const limitStrLength = (text: string, maxLength: number) => {
  if (text.length > maxLength - 3) {
    return text.substring(0, maxLength).trimEnd() + "...";
  } else {
    return text;
  }
};

export function isObject(value: any) {
  return value && typeof value === "object" && Object.keys(value).length > 0;
}

export function findSubGrid(
  object: any = {},
  keyToMatch: any = "",
  valueToMatch: any = ""
): any {
  if (isObject(object)) {
    const entries = Object.entries(object);

    for (const element of entries) {
      const [objectKey, objectValue] = element;

      if (objectKey === keyToMatch && objectValue === valueToMatch) {
        return object;
      }

      if (isObject(objectValue)) {
        const child = findSubGrid(objectValue, keyToMatch, valueToMatch);

        if (child !== null) {
          return child;
        }
      }
    }
  }

  return null;
}

export function numberFormat(number: any) {
  return (
    Number(number)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "₮"
  );
}

export function numberFormatNoSymbol(number: any) {
  return Number(number)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function isObjectLength(object: any) {
  return (
    object &&
    typeof object === "object" &&
    object !== null &&
    Object.keys(object).length > 0
  );
}

export const toBase64 = (str: string) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

export function drawerContextHide() {
  document.body.style.overflow = " show";
  drawerContext.hide();
}
