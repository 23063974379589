/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../reducers/cart/hooks";
import {
  ImageItem,
  LeftRightItem,
  LinkItem,
  MainMenu,
} from "../../types/mainMenu";
import { TypeOfStates } from "../../types/stateTypes";

export default function MainNavigation() {
  const [activeIndex, setActiveIndex] = useState(99999999);
  const [openMenu, setOpenMenu] = useState(9999);

  const mainMenuState = useAppSelector(
    (state: TypeOfStates) => state.mainMenus
  );

  const navigate = useNavigate();
  const closeMenu = () => {
    setOpenMenu(9999);
  };

  const mainMenuMemo = useMemo(() => {
    const tempObject: any = [];
    mainMenuState &&
      mainMenuState?.main_menu &&
      mainMenuState?.main_menu.forEach((item: MainMenu, index) => {
        tempObject.push({
          isVisible: openMenu === index ? true : false,
          item,
        });
      });
    return tempObject;
  }, [mainMenuState, openMenu]);

  const openNavigation = useCallback((indx = 9999) => {
    setOpenMenu(indx);
  }, []);

  return (
    <div className="main-navigation">
      <ul>
        {mainMenuMemo?.map(
          (
            visibleItem: {
              isVisible: boolean;
              item: MainMenu;
            },
            index: number
          ) => (
            <React.Fragment key={index}>
              <li
                key={index}
                onClick={() => {
                  navigate(visibleItem?.item?.url);
                  setActiveIndex(index);
                }}
                onMouseOver={() => {
                  if (visibleItem?.item.is_sub_item) {
                    openNavigation(index);
                  } else {
                    closeMenu();
                  }
                }}
                onMouseLeave={() => {
                  if (!visibleItem?.item.is_sub_item) {
                    openNavigation(index);
                  }
                }}
                className={activeIndex === index ? "active-nav" : ""}
              >
                <span className="">{visibleItem?.item.frontmenu}</span>
              </li>
              {visibleItem.isVisible &&
                visibleItem?.item?.sub_item &&
                visibleItem?.item?.url !== "/press" && (
                  <SubElements
                    leftRightItem={visibleItem?.item?.sub_item}
                    openNavigation={() => {
                      openNavigation();
                    }}
                  />
                )}
            </React.Fragment>
          )
        )}
      </ul>
    </div>
  );
}

type Sprops = {
  leftRightItem?: LeftRightItem;
  openNavigation: () => void;
};
function SubElements({ leftRightItem, openNavigation }: Sprops) {
  return (
    <div className="fly-menu-container">
      <div
        className="fly-menu"
        onMouseLeave={() => {
          openNavigation();
        }}
      >
        <div className="items-left">
          <div className="items">
            {leftRightItem &&
              Object.keys(leftRightItem?.left?.link_items).length > 0 &&
              Object.values(leftRightItem?.left?.link_items)?.map(
                (item: LinkItem, index: number) => (
                  <div
                    key={item.mainmenu_subitem_id}
                    className={`item ${item?.sort_order}`}
                  >
                    <Link
                      to={`/category/${item?.cid}`}
                      className="link"
                      onClick={() => {
                        openNavigation();
                      }}
                    >
                      {item?.mainmenu_subitem}
                    </Link>
                  </div>
                )
              )}
          </div>

          {typeof leftRightItem?.left?.image_items === "object" &&
            Object.values(leftRightItem?.left.image_items)?.map(
              (item: ImageItem) => (
                <div
                  key={item?.mainmenu_subitem_id}
                  className={`item ${item?.sort_order}`}
                >
                  <Link
                    to={`/category/${item?.cid}`}
                    className="link"
                    onClick={() => {
                      openNavigation();
                    }}
                  >
                    {item?.main_pair?.icon?.image_path && (
                      <img
                        src={item?.main_pair?.icon?.image_path}
                        alt={"choihainzan.com "}
                        style={{
                          height: 234,
                          width: 520,
                        }}
                      />
                    )}
                  </Link>
                </div>
              )
            )}
        </div>

        <div className="items-right">
          {leftRightItem?.right &&
            typeof leftRightItem?.right?.image_items === "object" &&
            Object.values(leftRightItem?.right?.image_items)?.map(
              (item: ImageItem) => (
                <div
                  key={item.mainmenu_subitem_id}
                  className={`item ${item?.sort_order}`}
                >
                  <Link
                    to={`/category/${item?.cid}`}
                    className="link"
                    onClick={() => {
                      openNavigation();
                    }}
                  >
                    {item?.mainmenu_subitem}
                  </Link>
                  {item?.main_pair?.icon?.image_path && (
                    <img
                      src={item?.main_pair?.icon?.image_path}
                      alt={"choihainzan.com "}
                      style={{
                        height: 290,
                        width: 250,
                        objectFit: "cover",
                        objectPosition: "top left"
                      }}
                    />
                  )}
                </div>
              )
            )}
        </div>
      </div>
    </div>
  );
}
