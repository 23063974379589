import { Link } from "react-router-dom";
import { Image } from "antd";
import { TypeOfStates } from "../types/stateTypes";
import { useAppSelector } from "../reducers/cart/hooks";
import logo from "../assets/logo.svg";
import logoMobile from "../assets/logoMobile.svg";
import { getOSType } from "../lib/Functions";

const MainLogo = () => {
  const getStates = useAppSelector((state: TypeOfStates) => state);
  const isMobile = getStates.isMobile.status;

  const heightLogo = () => {
    if (getOSType() === "android") {
      return 54;
    } else if (getOSType() === "ios") {
      return 36;
    } else {
      return 46;
    }
  };
  return (
    <div className="logo">
      <Link to={"/"}>
        <span className="sr-only">Choihainzan.com</span>
        {isMobile ? (
          <Image
            src={logoMobile}
            alt=""
            preview={false}
            style={{
              height: heightLogo(),
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        ) : (
          <Image
            src={
              getOSType() === "android" || getOSType() === "ios"
                ? logoMobile
                : logo
            }
            alt=""
            preview={false}
            style={{
              height: heightLogo(),
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        )}
      </Link>
    </div>
  );
};

export default MainLogo;
