/* eslint-disable @typescript-eslint/indent */

import {MINI_CART_SHOW_REQUEST} from '../../reducers/constants';

export function showOrHide(isShow = false) {
  return (
    dispatch: (arg0: {type: string; payload: {show: boolean}}) => void,
  ) => {
    dispatch({
      type: MINI_CART_SHOW_REQUEST,
      payload: {
        show: isShow,
      },
    });
  };
}

export function showWithTimer(second = 6000) {
  return (
    dispatch: (arg0: {type: string; payload: {show: boolean}}) => void,
  ) => {
    dispatch({
      type: MINI_CART_SHOW_REQUEST,
      payload: {
        show: true,
      },
    });
    setTimeout(() => {
      dispatch({
        type: MINI_CART_SHOW_REQUEST,
        payload: {
          show: false,
        },
      });
    }, second);
  };
}
