import i18n from "../../utils/i18n";
import { FaOpencart } from 'react-icons/fa';

const EmptyCart = () => {
  return (
    <div className="empty-cart"
    >
      <FaOpencart />
      {i18n.t("empty_cart")}
    </div>
  );
};

export default EmptyCart;
