/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-case-declarations */
import type { GridType } from "../types/grids";
import {
  FETCH_HOMEPAGE_LAYOUTS_REQUEST,
  FETCH_HOMEPAGE_LAYOUTS_SUCCESS,
  FETCH_HOMEPAGE_LAYOUTS_FAIL,
  FETCH_CUSTOM_LAYOUTS_REQUEST,
  FETCH_CUSTOM_LAYOUTS_SUCCESS,
  FETCH_CUSTOM_LAYOUTS_FAIL,
} from "./constants";

type InitProps = {
  fetching: boolean;
  grids: GridType[];
  custom_grids: GridType[];
};
const initialState: InitProps = {
  fetching: false,
  grids: [],
  custom_grids: [],
};

function getpageblocks(state: any = initialState, action: any) {
  switch (action.type) {
    case FETCH_HOMEPAGE_LAYOUTS_REQUEST:
    case FETCH_CUSTOM_LAYOUTS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_HOMEPAGE_LAYOUTS_SUCCESS:
      return {
        ...state,
        grids: action.payload,
        fetching: false,
      };
      
    case FETCH_CUSTOM_LAYOUTS_SUCCESS:
      return {
        ...state,
        custom_grids: action.payload,
        fetching: false,
      };

    case FETCH_HOMEPAGE_LAYOUTS_FAIL:
    case FETCH_CUSTOM_LAYOUTS_FAIL:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}

export default getpageblocks;
