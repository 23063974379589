import {
  CH_CATEGORIES_REQUEST,
  CH_CATEGORIES_SUCCESS,
  CH_CATEGORIES_FAILED,
  CATEGORIES_WITH_SUB_REQUEST,
  CATEGORIES_WITH_SUB_SUCCESS,
  CATEGORIES_WITH_SUB_FAILED,
  CATEGORIES_TREE_REQUEST,
  CATEGORIES_TREE_SUCCESS,
  CATEGORIES_TREE_FAILED,
  CATEGORY_DETAIL_REQUEST,
  CATEGORY_DETAIL_SUCCESS,
  CATEGORY_DETAIL_FAILED,
} from "../../reducers/constants";
import Api from "../../services/configApi";

export function fetchCategoryWithSub(
  subcategoryId: unknown,
  categoryId = 0,
  page = 1,
  items_per_page = 20,
  advParams = {}
) {
  return async (dispatch: any) => {
    dispatch({ type: CATEGORIES_WITH_SUB_REQUEST });

    try {
      const params = {
        sub_category_id: subcategoryId,
        page,
        subcats: "Y",
        items_per_page,
        get_filters: true,
        cid: categoryId,
        ...advParams,
      };
      const categoryData = await Api.get(`/CategoryList/${categoryId}`, {
        params,
      });
      dispatch({
        type: CATEGORIES_WITH_SUB_SUCCESS,
        payload: categoryData.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: CATEGORIES_WITH_SUB_FAILED,
        error,
      });
    }
  };
}

export function fetchCategoryTree(
  categoryId: number | 0,
  page?: number | 1,
  itemsPerPage?: number | 50,
  sortBy?: string | "timestamp",
  sortOrder?: string | "asc"
) {
  const params: any = {
    page,
    items_per_page: itemsPerPage,
    sort_by: sortBy,
    sort_order: sortOrder,
    subcats: "Y",
  };

  return async (dispatch: any) => {
    dispatch({ type: CATEGORIES_TREE_REQUEST });
    return await Api.get(`CategoriesTree/${categoryId}`, { params })
      .then((response: any) => {
        dispatch({
          type: CATEGORIES_TREE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: CATEGORIES_TREE_FAILED,
          error,
        });
      });
  };
}

export function fetchCategoryDetail(
  categoryId: number | 0,
  page?: number | 1,
  itemsPerPage?: number | 50,
  sortBy?: string | "timestamp",
  sortOrder?: string | "asc"
) {
  const params: any = {
    page,
    items_per_page: itemsPerPage,
    sort_by: sortBy,
    sort_order: sortOrder,
    subcats: "Y",
  };
  // 'sort_by' : 'timestamp'; // position // product
  return async (dispatch: any) => {
    dispatch({ type: CATEGORY_DETAIL_REQUEST });
    return await Api.get(`categories/${categoryId}`, { params })
      .then((response: any) => {
        dispatch({
          type: CATEGORY_DETAIL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: CATEGORY_DETAIL_FAILED,
          error,
        });
      });
  };
}

export function fetch(categoryId = 0, page = 1, advParams = {}) {
  return async (dispatch: any) => {
    dispatch({ type: CH_CATEGORIES_REQUEST });

    try {
      const params: any = {
        page,
        subcats: "Y",
        items_per_page: 12,
        get_filters: true,
        cid: categoryId,
        ...advParams,
      };
      const catInfo = await Api.get(`/categories/${categoryId}`, {
        params,
      });
      const categoryProducts = await Api.get(
        `/categories/${categoryId}/sra_products`,
        {
          params,
        }
      );
      dispatch({
        type: CH_CATEGORIES_SUCCESS,
        payload: {
          catInfo,
          categoryProducts,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: CH_CATEGORIES_FAILED,
        error,
      });
    }
  };
}
