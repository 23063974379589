import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal, Image, Carousel, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const ModalSlider: React.FC<{
  isMobile: boolean;
  currentSlider: number;
  isShow: boolean;
  setIsModalOpen: (arg: boolean) => void;
  slidersReOrder: any;
}> = ({ slidersReOrder, isMobile, isShow, setIsModalOpen, currentSlider }) => {
  const [zoom, setZoom] = useState(1);
  const [getInitialSlide, setInitialSlide] = useState(0);
  let sliderRef: any = useRef();

  const [newSlidersReOrder, setslidersReOrder] = useState<any[]>([]);
  const handleImageWheel = (event: React.WheelEvent) => {
    if (event.deltaY > 0) {
      setZoom(zoom - 0.1);
    } else {
      setZoom(zoom + 0.1);
    }
  };

  useEffect(() => {
    const nslides = Object.values(slidersReOrder);
    const currentSliderSlides = nslides.filter(
      (values: any) => Number(values.slider_id) === +currentSlider
    );
    const otherSlides = nslides.filter(
      (values: any) => Number(values.slider_id) !== +currentSlider
    );
    setslidersReOrder([...currentSliderSlides, ...otherSlides]);
  }, [currentSlider, slidersReOrder]);

  const settings: any = {
    initialSlide: getInitialSlide,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    lazyLoad: true,
    swipeToSlide: true,
    afterChange: function (index: number) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },

    dotsClass: "slick-dots slick-thumb",
  };
  return (
    <Modal
      title=""
      open={isShow}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => {
        setIsModalOpen(false);
        document.body.style.overflow = "auto";
      }}
      className="image-Modal-Slider"
      width={"auto"}
      style={{ top: -1 }}
      footer={[]}
    >
      <Carousel effect="fade" {...settings} ref={sliderRef}>
        {newSlidersReOrder &&
          newSlidersReOrder.map((slide: any, index: number) => {
            if (slide && slide?.value && (slide?.value).length > 0) {
              return (
                <Fragment key={index}>
                  <div
                    style={{
                      position: "absolute",
                      top: 1,
                      zIndex: 99999,
                      color: "#E2E2E2D2",
                      margin: "0 auto",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {index + 1}/{newSlidersReOrder.length}
                  </div>
                  <Button
                    className="prev-button"
                    onClick={() => sliderRef?.current.prev()}
                    type={"text"}
                  >
      
                    <LeftOutlined />
                  </Button>
                  <Image
                    src={slide.value}
                    alt={"Choihainzan.com"}
                    className={
                      isMobile ? "slider-image-mobile" : "slider-image-web"
                    }
                    key={index}
                    draggable={true}
                    onWheel={handleImageWheel}
                    style={{
                      transform: `scale(${zoom > 1 ? zoom : 1})`,
                    }}
                    preview={{
                      visible: false,
                    }}
                  />
                  <Button
                    className="next-button"
                    onClick={() => sliderRef?.current.next()}
                    type={"text"}
                  >
                   <RightOutlined />
                  </Button>
                </Fragment>
              );
            } else {
              return <Fragment key={index}></Fragment>;
            }
          })}
      </Carousel>
    </Modal>
  );
};

export default ModalSlider;
