import { POINTS_REQUEST, POINTS_SUCCESS, POINTS_FAILED } from "./constants";

const initialState = {
  fetching: false,
};

function points(state: any = initialState, action: any) {
  switch (action.type) {
    case POINTS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case POINTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
      };
    case POINTS_FAILED:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}

export default points;
