import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  lng: 'mn',
  debug: true,
  keySeparator: false,
  resources: {
    mn: { translation: require('../config/locales/mn.json') },
    en: { translation: require('../config/locales/en.json') },
  },
  fallbackLng: 'mn',
});

export const i18nt = (input: string) => {
  const dictionary = require('../config/locales/mn.json');
  if (Object.keys(dictionary).includes(input)) return dictionary[input];
  return input;
};



export default i18n;

