/* eslint-disable @typescript-eslint/indent */

import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button } from "antd";
import getWindowDimensions from "../hooks/useWindowDimension";
import i18n from "../utils/i18n";
type Props = {
  item: any;
  isMobile: boolean;
  grid_width: number;
};
const SingleProductBlocks = ({ item, isMobile, grid_width }: Props) => {
  const navigate = useNavigate();

  if (item && typeof item !== "object") {
    return null;
  }

  const { width } = getWindowDimensions();

  return (
    <>
      {item && (
        <div
          style={{ overflow: "hidden" }}
          className={`relative  flex ${"items-end justify-center"}`}
        >
          <div
            className={`z-10  text-white px-6 absolute
        ${" mb-8 center-align-items "} 
      `}
          >
            <h1
              className={`                        
              ${
                isMobile === true
                  ? " homecategory-btn-figma-mobile "
                  : " homecategory-btn-figma "
              }         
              ${" txt-2 "} 
            `}
              style={{
                color: "black",
                textShadow: "2px 0px 16px #c6c6c6ba"
              }}
            >
              {item?.product}
            </h1>

            {item?.seo_name && (
              <Button
                className={`${
                  isMobile === true
                    ? " home-category-link-btn-mobile "
                    : " home-category-link-btn "
                }  `}
                onClick={() => navigate(`/product/${item?.seo_name}`)}
                type="primary"
              >
                {i18n.t("Shop the Collection")}
              </Button>
            )}
          </div>
          {item?.main_image && width && (
            <LazyLoadImage
              src={item?.main_image}
              style={{
                width: isMobile || grid_width === 12 ? width : width / 1.1,
                height: "100%",
                background: "#efefef",
                objectFit: "cover",
                objectPosition: "center center",
                marginBottom: 0,
                maxHeight: 590,
              }}
              alt={item?.product}
              className="inset-0 object-cover"
            />
          )}
        </div>
      )}
    </>
  );
};

export default SingleProductBlocks;
