/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import * as React from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import { FC, useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import type { LoginData } from "../../types/authTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as authActions from "../../reducers/actions/authActions";
import * as cartActions from "../../reducers/actions/cartActions";

import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { TypeOfStates } from "../../types/stateTypes";
import Input from "../ui/Input";
import { Button, Divider } from "antd";
import { drawerContextHide } from "../../lib/Functions";
import i18n from "../../utils/i18n";
import { FormType } from "./LoginLayout";
import { STORE_USER_MAIL } from "../../reducers/constants";
import base64 from "base-64";

type Props = {
  navigateUrl?: string;
  setFormType: (arg: FormType) => void;
};

const messageRequired = "!" + i18n.t("Required information");
const emailRequired = "!" + i18n.t("Required information");
const passwordWrong = " " + i18n.t("password_wrong") + ""; // password_wrong

const LoginForm: FC<Props> = ({ navigateUrl, setFormType }) => {
  const dispatch = useAppDispatch();
  let location = useLocation();
  const allstate = useAppSelector((state: TypeOfStates) => state);
  const navigate = useNavigate();
  const [awaitLogin, setAwaitLogin] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [errorMsjType, setErrorMsjType] = useState<string>();

  const authState = allstate.auth;

  const ldp = localStorage.getItem(STORE_USER_MAIL);

  const defaultValues = {
    email: ldp && ldp?.length > 0 ? base64.decode(ldp) : "",
    password: "",
  };

  const SignupSchema = yup.object().shape({
    email: yup.string().email().required(emailRequired),
    password: yup.string().required(messageRequired),
  });

  const setFormTypeReset = useCallback(() => {
    setFormType({
      type: "FORGOT",
    });
  }, [setFormType]);

  useEffect(() => {
    if (authState.logged) {
      setAwaitLogin(false);
      drawerContextHide();
      void dispatch(cartActions.fetchCart());
    }
  }, [authState.logged, dispatch]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(SignupSchema),
  });

  const onSubmit = async (data: LoginData) => {
    setAwaitLogin(true);
    if (data) {
      let res: any;

      const lp = localStorage.getItem(STORE_USER_MAIL);

      if (lp && lp?.length > 0) {
        const ldata = {
          email: base64.decode(lp),
          one_time_password: data.password,
        };
        res = await authActions
          .loginWithOneTimePassword1(ldata)
          .then((s: any) => {
            if (s.data?.token && s.data?.ttl) {
              setError("");
              setAwaitLogin(false);

              dispatch(
                authActions.getUserData({
                  token: res.data?.token,
                  ttl: res.data?.ttl,
                })
              );

              dispatch(authActions.authLoaded());
              drawerContextHide();
              localStorage.removeItem(STORE_USER_MAIL);
              navigate("/account/profile");
            }

            if (s?.data?.error || s?.data?.status === 404) {
              setError("passwordWrong");
              setAwaitLogin(false);
            }
            console.log("ssss", s);
          })
          .catch((err) => {
            setError("passwordWrong");
            setAwaitLogin(false);
          });
      } else {
        res = await dispatch(authActions.loginFunction(data));
        if (res?.error || res?.status === 404) {
          setError("passwordWrong");
          setAwaitLogin(false);
        } else {
          setError("");
          setAwaitLogin(false);
          await dispatch(authActions.getUserData(res));
          drawerContextHide();
          navigateUrl ? navigate(navigateUrl) : navigate(location.pathname);
        }
      }

      if (authState.logged) {
        setAwaitLogin(false);
        drawerContextHide();
        navigateUrl ? navigate(navigateUrl) : navigate(location.pathname);
      }
    }
  };

  const onError = (errors: any) => {
    console.log(errors);
  };

  const routeToSignUp = () => {
    navigate("/account/signup");
    drawerContextHide();
  };

  return (
    <div className="login-form">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="inputs">
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <Input
                type="email"
                name="email"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isError={Boolean(errors?.email)}
                isSuccess={false}
                error={errors.email?.message}
                label={i18n.t("login_email")}
                isRequired
                size={"large"}
              />
            )}
            name="email"
            control={control}
            rules={{ required: true }}
          />
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <Input
                type="password"
                name={"password"}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label={i18n.t("login_password")}
                isError={Boolean(errors?.password)}
                isRequired
                error={error?.message}
                size={"large"}
              />
            )}
            name="password"
            control={control}
            rules={{ required: true }}
          />
          {error && (
            <div className="error">
              {error === "passwordWrong"
                ? i18n.t("password_wrong")
                : "Нууц үг буруу "}
            </div>
          )}
        </div>

        <Button
          className="forgot-password"
          type={"text"}
          onClick={setFormTypeReset}
          style={{
            width: "200px",
            float: "right",
          }}
        >
          {i18n.t("Forgot your password")}?
        </Button>
        <Button
          type={"primary"}
          loading={awaitLogin}
          size={"large"}
          onClick={handleSubmit(onSubmit, onError)}
        >
          {i18n.t("sign_in")}
        </Button>
        <Divider plain>{i18n.t("or")}</Divider>

        <Button type={"primary"} size={"large"} onClick={routeToSignUp}>
          {i18n.t("Create an account")}
        </Button>
        <PrivacyPolicy />
      </form>
    </div>
  );
};

export default LoginForm;
