/* eslint-disable @typescript-eslint/indent */
import { STORE_KEY, STORE_KEY_AUTH } from "./constants";
import type { ThunkAction, Action } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { combineReducers } from "redux";
import getpageblocks from "./getpageblocks";
import layouts from "./layouts";
import products from "./products";
import mainlayouts from "./mainlayouts";
import cart from "./cart";
import localCart from "./local-cart";
import cartproducts from "./cart-products";
import miniCart from "./mini-cart";
import notifications from "./notifications";
import category from "./category";
import productDetail from "./product-detail";
import isMobile from "./is-mobile";
import auth from "./auth";
import profile from "./profile";
import settings from "./settings";
import wishList from "./wishList";
import blog from "./blogs";
import shipmentLocations from "./shipment-locations";
import boutiques from "./boutiques";
import common from "./common";
import mainMenus from "./main-menus";
import orders from "./orders";
import points from "./points";
import search from "./search";
import page from "./page";
import cartChangeStatus from "./cart-change-status";
import getTranslations from "./get-translations";
import newpress from "./newpress";
import campaign from "./campaign";

export const rootReducer = combineReducers({
  mainMenus,
  auth,
  profile,
  settings,
  wishList,
  layouts,
  getpageblocks,
  products,
  mainlayouts,
  cart,
  localCart,
  cartproducts,
  miniCart,
  notifications,
  category,
  productDetail,
  isMobile,
  blog,
  shipmentLocations,
  boutiques,
  common,
  orders,
  points,
  search,
  page,
  cartChangeStatus,
  getTranslations,
  newpress,
  campaign
});

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  });
}

export type RootState = ReturnType<typeof rootReducer>;
const store = makeStore();
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const storeSubscribe = () => {
  store.subscribe(() => {
    localStorage.setItem(
      STORE_KEY,

      JSON.stringify({
        auth: store.getState().auth,
        cart: store.getState().cart,
        profile: store.getState().profile,
        settings: {
          ...store.getState().settings,
          languageCurrencyFeatureFlag: true,
          isShopClosed: false,
          productReviewsAddon: {
            isEnabled: false,
            isCommentOnly: false,
          },
        },
      })
    );
  });
};

const storeSubscribeAuth = () => {
  store.subscribe(() => {
    localStorage.setItem(
      STORE_KEY_AUTH,

      JSON.stringify({
        auth: store.getState().auth,
        cart: store.getState().cart,
        profile: store.getState().profile,
        settings: {
          ...store.getState().settings,
          languageCurrencyFeatureFlag: true,
          isShopClosed: false,
          productReviewsAddon: {
            isEnabled: false,
            isCommentOnly: false,
          },
        },
      })
    );
  });
};

/// const localStorageData = localStorage.getItem(STORE_KEY)
const localStorageDataAuth = localStorage.getItem(STORE_KEY_AUTH);

if (
  localStorageDataAuth &&
  (JSON.parse(localStorageDataAuth)?.auth?.token).length === 0
) {
  storeSubscribeAuth();
}

if (localStorageDataAuth === null && typeof localStorageDataAuth === "object") {
  storeSubscribeAuth();
}

if (!localStorageDataAuth) {
  storeSubscribeAuth();
}

storeSubscribe();

export default store;
