import {
  CH_CAMPAIGN_REQUEST,
  CH_CAMPAIGN_SUCCESS,
  CH_CAMPAIGN_FAILED,
} from "./constants";

type Initialstate = {
  fetching: boolean;
  detail: any[]
};
const initialState: Initialstate = {
  fetching: false,
  detail: []
};

const campaigns = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CH_CAMPAIGN_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case CH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        fetching: false,
        detail: action.payload,
      };

    case CH_CAMPAIGN_FAILED:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};

export default campaigns;
