/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import {
  FETCH_DISCUSSION_REQUEST,
  FETCH_DISCUSSION_SUCCESS,
  FETCH_DISCUSSION_FAIL,
  NOTIFICATION_SHOW,
  CHANGE_PRODUCTS_SORT,
} from "../constants";
import Api from "../../services/configApi";

export function fetchDiscussion(
  authToken: string,
  id: any,
  params = { page: 1 },
  type = "P"
) {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_DISCUSSION_REQUEST,
    });

    try {
      const response = await Api.get(
        `/sra_discussion/?object_type=${type}&object_id=${id}&params[page]=${params.page}`,
      );
      dispatch({
        type: FETCH_DISCUSSION_SUCCESS,
        payload: {
          id: `${type.toLowerCase()}_${id}`,
          page: params.page,
          discussion: response.data,
        },
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: FETCH_DISCUSSION_FAIL,
        error,
      });
    }
  };
}

export function sendErrorNotification(title: any, message: any) {
  return async (dispatch: any) => {
    dispatch({
      type: NOTIFICATION_SHOW,
      payload: {
        show: true,
        type: "error",
        title,
        text: message,
      },
    });
  };
}

export function changeSort(params: any) {
  return (dispatch: any) => {
    dispatch({
      type: CHANGE_PRODUCTS_SORT,
      payload: params,
    });
  };
}
