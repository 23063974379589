import * as React from "react";
import type { FC } from "react";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import type { MainPair } from "../../types/ProductType";
import { Image } from "antd";

type Props = {
  product: any;
  screenWidth: number;
};

const ProductImageSlider: FC<Props> = ({ product, screenWidth }: Props) => {
  const sliderImagePairs = [];
  sliderImagePairs.push(product.main_pair);
  return (
    <div
      style={{
        width: screenWidth,
        paddingLeft: 2,
        paddingRight: 2,
        background: "#f1f1f1",
        textAlign: "center",
      }}
    >
     
      <Slider
        dots={true}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        centerMode={false}
        lazyLoad={"ondemand"}
        initialSlide={0}
      >
        {sliderImagePairs
          .concat(Object.values(product.image_pairs))
          .map((image: MainPair, index: number) => (
            <Image
              key={index}
              style={{
                objectFit: "cover",
                objectPosition: "center center",
              }}
              preview={false}
              src={image.detailed.image_path}
              loading="lazy"
            />
          ))}
      </Slider>
    </div>
  );
};

export default ProductImageSlider;
