import {
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_FAIL,
  FETCH_ORDERS_SUCCESS,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
} from "./constants";

const initialState = {
  params: {},
  items: [],
  fetching: false,
  fetchOrderCreate: false,
};

function order(state = initialState, action: any) {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        ...state,
        fetchOrderCreate: true,
      };
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case ORDER_CREATE_SUCCESS:
      return {
        ...state,
        fetchOrderCreate: false,
        fetching: false,
        ...action.payload
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        items: action.payload.orders,
        params: action.payload.params,
        fetching: false,
      };

    case FETCH_ORDERS_FAIL:
    case ORDER_CREATE_FAIL:
      return {
        ...state,
        fetching: false,
        fetchOrderCreate: false
      };

    default:
      return state;
  }
}

export default order;
