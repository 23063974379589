/* eslint-disable max-params */

import React from "react";
import { BaseProviderImpl } from "./BaseHooks";

type DrawerData = {
  visible: boolean;
  component: JSX.Element;
  styleName?: string;
  leftRight?: string;
  closable: boolean;
  backdropPress: boolean;
  spinning: boolean;
  callBack?: () => void;
  contextWidth: number;
};

export class DrawerDtEffectuate implements DrawerData {
  constructor(
    public visible: boolean,
    public component: JSX.Element,
    public styleName: string | undefined = undefined,
    public leftRight: string | undefined = undefined,
    public closable: boolean = true,
    public backdropPress: boolean = false,
    public spinning: boolean = false,
    public callBack?: () => void,
    public contextWidth: number = 300
  ) {}
}
export type ModalContext = {
  isVisible(): boolean;

  isBackPress(): boolean;

  isClosable(): boolean;

  isSpinning(): boolean;

  hasStyleName(): boolean;

  getStyleName(): string;

  getContent(): JSX.Element;

  setSpinning(value: boolean): void;

  show(
    component: JSX.Element,
    styleName?: string,
    leftRight?: string,
    backdropPress?: boolean,
    closable?: boolean,
    callBack?: () => void,
    contextWidth?: number
  ): void;

  hide(feedBack?: boolean): void;
  leftRight(): string;
  contextWidth(): number;
};

export class ModalContextEffect
  extends BaseProviderImpl<DrawerData, DrawerData>
  implements ModalContext
{
  reducer(prevState: DrawerData, state: DrawerData) {
    return {
      ...prevState,
      visible: state.visible,
      component: state.component,
      styleName: state.styleName,
      leftRight: state.leftRight,
      closable: state.closable,
      backdropPress: state.backdropPress,
      spinning: state.spinning,
      callBack: state.callBack,
      contextWidth: state.contextWidth,
    };
  }

  isVisible(): boolean {
    return this.state.visible;
  }

  isSpinning(): boolean {
    return this.state.spinning;
  }

  isBackPress(): boolean {
    return this.state.backdropPress;
  }

  hasStyleName(): boolean {
    const styleName: string = this.getStyleName();
    return Boolean(styleName && styleName !== "");
  }

  getStyleName(): string {
    const styleName: string = this.state.styleName ? this.state.styleName : "";
    return styleName;
  }
  leftRight(): string {
    const leftRight: string = this.state.leftRight ? this.state.leftRight : "";
    return leftRight;
  }
  contextWidth(): number {
    const leftRight: number = this.state.contextWidth
      ? this.state.contextWidth
      : 300;
    return leftRight;
  }

  getContent(): JSX.Element {
    return this.state.component;
  }

  setSpinning(value: boolean) {
    const {
      visible,
      component,
      closable,
      styleName,
      leftRight,
      backdropPress,
    } = this.getState();

    this.sendDispatch(
      new DrawerDtEffectuate(
        visible,
        component,
        styleName,
        leftRight,
        closable,
        backdropPress,
        value
      )
    );
  }

  show(
    component: JSX.Element,
    styleName?: string,
    leftRight?: string,
    backdropPress = true,
    closable = true,
    callBack?: () => void,
    contextWidth?: number
  ) {
    this.sendDispatch(
      new DrawerDtEffectuate(
        true,
        component,
        styleName,
        leftRight,
        closable,
        backdropPress,
        false,
        callBack,
        contextWidth
      )
    );
  }

  hide(feedBack = false) {
    const { callBack } = this.getState();

    if (callBack && feedBack) {
      callBack();
    }

    this.sendDispatch(
      new DrawerDtEffectuate(false, <div></div>, undefined, "right", true, true)
    );
    document.body.style.overflow = "auto";
  }

  isClosable() {
    return this.state.closable;
  }
}

export const drawerContext: ModalContext = new ModalContextEffect(
  new DrawerDtEffectuate(false, <div></div>, undefined)
);

export const AppModalContext: React.Context<ModalContext> =
  React.createContext(drawerContext);
