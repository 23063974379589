/* eslint-disable @typescript-eslint/default-param-last */

/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import {
  MAINNAV_SHOW,
  MAINNAV_HIDE,
  RELOAD_DATA_REQUEST,
  RELOAD_DATA_DONE,
  TOPHEADER_SHOW,
  TOPHEADER_HIDE,
  FOOTER_SHOW,
  FOOTER_HIDE,
} from "./constants";

type InitProps = {
  mainnavShow: boolean;
  topHeaderShow: boolean
  footerShow: boolean
};
const initialState: InitProps = {
  mainnavShow: true,
  topHeaderShow: true,
  footerShow: true
};

function mainNavShowHide(state: any = initialState, action: any) {
  switch (action.type) {
    case FOOTER_SHOW:
      return {
        footerShow: true,
        ...state,
        ...action.payload,
      };

    case FOOTER_HIDE:
      return {
        footerShow: false,
        ...state,
        ...action.payload,
      };
      
    case TOPHEADER_SHOW:
      return {
        topHeaderShow: true,
        ...state,
        ...action.payload,
      };

    case TOPHEADER_HIDE:
      return {
        topHeaderShow: false,
        ...state,
        ...action.payload,
      };

      
    case MAINNAV_SHOW:
      return {
        mainnavShow: true,
        ...state,
        ...action.payload,
      };

    case MAINNAV_HIDE:
      return {
        mainnavShow: false,
        ...state,
        ...action.payload,
      };

    case RELOAD_DATA_REQUEST:
      return {
        ...state,
        fetching: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default mainNavShowHide;
