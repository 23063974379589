import { useEffect, useState } from "react";
import i18n from "../../utils/i18n";

interface userProfileProps {
  profile: any;
  isMobile: boolean;
}

const PointsRedBox = ({ profile, isMobile }: userProfileProps) => {
  const [points, setpoints] = useState();

  useEffect(() => {
    if (profile?.points) {
      setpoints(
        profile?.points.replace(/i/g, "").replace(/:/g, "").replace(/;/g, "")
      );
    }
  }, [profile?.points]);

  return (
    <div className="red-box">
      <div className="items">
        <h1 className="pointnumber-pointtext">
          <span className="pointnumber">{points}</span>
          <span className="pointtext">{i18n.t("points")}</span>
        </h1>

        <img
          style={{
            height: 12,
            marginLeft: -8,
            objectFit: "cover",
          }}
          src="/linepoints.png"
          alt="linepoints.png"
        />

        {/* <div className="unlock-text"> Unlock Level 2 at 150,000 pts</div> */}
        <div className="member-id"> MEMBER ID {profile?.user_id}</div>
      </div>

      <img
        style={{
          height: 120,
          objectFit: "cover",
        }}
        src="/qrcode_choihainzan.com.png"
        alt="qrcode_choihainzan.com.png"
      />
    </div>
  );
};

export default PointsRedBox;
