/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/default-param-last */

import {
  FETCH_LOCALCART_REQUEST,
  FETCH_LOCALCART_SUCCESS,
  FETCH_LOCALCART_FAIL,
} from './constants';

type Initialstate = {
    fetching: boolean;
};
const initialState: Initialstate = {
  fetching: false,
};

const localCart = (
  state = initialState,
  action: {type: any; payload: any},
) => {
  switch (action.type) {
    case FETCH_LOCALCART_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_LOCALCART_SUCCESS:
      return {
        ...state,
        fetching: false,
        ...action.payload,
      };

    case FETCH_LOCALCART_FAIL:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};

export default localCart;
