import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Layout } from "../../layouts";
import { createMarkup } from "../../lib/Functions";
import * as commonActions from "../../reducers/actions/commonActions";
import * as pageActions from "../../reducers/actions/pageActions";
import { useAppDispatch, useAppSelector } from "../../reducers/cart/hooks";
import { TypeOfStates } from "../../types/stateTypes";
import i18n from "../../utils/i18n";
import Loader from "../Category/Loader";

function PageScreen() {
  const dispatch = useAppDispatch();

  const { pageSlug } = useParams<"pageSlug">();
  const allState = useAppSelector((state: TypeOfStates) => state);
  const navigate = useNavigate();
  const pageDetail = allState.page.pageDetail;

  // page_data/46

  useEffect(() => {
    dispatch(commonActions.mainNavShowHide(true));
    if (pageSlug && Number(pageSlug)) {
      dispatch(pageActions.fetch(Number(pageSlug)));
    }
  }, [dispatch, pageSlug]);

  useEffect(() => {
    if (pageDetail && pageDetail?.spec_url && pageDetail?.spec_url.length > 0) {
      navigate(pageDetail.spec_url);
    }
  }, [navigate, pageDetail]);

  if (!pageDetail.description) {
    return (
      <Layout>
        <div className="empty-category">
          <h2>{i18n.t("Coming soon")}</h2>
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      {allState.page.fetching && <Loader />}
      <div className="min-h-screen product-page-horizontal-layout">
        <h2
          style={{
            textAlign: "center",
            padding: 10,
          }}
        >
          {pageDetail.page}
        </h2>
        {pageDetail?.page_image_top?.icon?.image_path && (
          <img
            src={pageDetail?.page_image_top?.icon?.image_path}
            alt={""}
            style={{
              width: "100%",
              objectFit: "cover",
              objectPosition: "center top",
            }}
          />
        )}
        <div
          style={{
            padding: 10,
          }}
          dangerouslySetInnerHTML={createMarkup(pageDetail.description)}
        ></div>
        {pageDetail?.page_image_bottom?.icon?.image_path && (
          <img
            src={pageDetail?.page_image_bottom?.icon?.image_path}
            alt={""}
            style={{
              width: "100%",
              objectFit: "cover",
              objectPosition: "center top",
            }}
          />
        )}
      </div>
    </Layout>
  );
}

export default PageScreen;
