import { get } from "lodash";
import i18n from "../../utils/i18n";
import {
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_FAIL,
  FETCH_ORDERS_SUCCESS,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  NOTIFICATION_SHOW,
  FETCH_ORDERSWITH_DETAILED_REQUEST,
  FETCH_ORDERSWITH_DETAILED_SUCCESS,
  FETCH_ORDERSWITH_DETAILED_FAIL,
} from "../constants";
import Api from "../../services/configApi";
import * as notificationsActions from "../actions/notificationsActions";

export function create(data: any) {
  return async (dispatch: any) => {
    dispatch({
      type: ORDER_CREATE_REQUEST,
      payload: {
        fetchOrderCreate: true,
      },
    });

    try {
      const response = await Api.post("/ChoihainzanSraOrders/", data);
      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: {
          ...response.data,
          fetchOrderCreate: false,
        },
      });

      dispatch(
        notificationsActions.showWithTimer(
          "success",
          "Захиалга",
          "Таны захиалга амжилттай үүслээ  Захиалгын дугаар #" +
            response.data.order_id,
          7000
        )
      );

      /// indow.location.reload("/account/orders");

      return response;
    } catch (error) {
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: {
          fetchOrderCreate: false,
          type: "error",
          title: i18n.t("Error"),
          text: get(
            error,
            "response.data.message",
            i18n.t("Something went wrong. Please try again later.")
          ),
        },
      });
      dispatch({
        type: ORDER_CREATE_FAIL,
        error,
      });
    }
  };
}

export function fetch(page = 1) {
  return async (dispatch: any) => {
    dispatch({ type: FETCH_ORDERS_REQUEST });
    try {
      const params = {
        page,
        get_orders_data: true,
      };
      const response = await Api.get(`/ChoihainzanSraOrders`, {
        params,
      });
      dispatch({
        type: FETCH_ORDERS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ORDERS_FAIL,
        error,
      });
    }
  };
}
